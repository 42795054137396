<template>
  <div>
    <el-form
      class="scroll-view"
      label-position="top"
      :model="property.form"
      ref="propertyForm"
      :rules="property.formRules"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <!-- 属性名称 -->
          <el-form-item
            :label="$t('vlink.tsl.propertyName')"
            prop="propertyName"
          >
            <el-input
              v-model="property.form.propertyName"
              :readonly="currentProperty.isEdit"
              maxlength="32"
              :show-word-limit="true"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 数据类型 -->
          <el-form-item :label="$t('vlink.tsl.dataType')" prop="dataType">
            <el-select
              v-model="property.form.dataType"
              :style="{ width: '100%' }"
            >
              <el-option
                :label="$t('vlink.dataType.int')"
                value="int"
              ></el-option>
              <el-option
                :label="$t('vlink.dataType.decimal')"
                value="decimal"
              ></el-option>
              <el-option
                :label="$t('vlink.dataType.string')"
                value="string"
              ></el-option>
              <el-option
                :label="$t('vlink.dataType.dateTime')"
                value="dateTime"
              ></el-option>
              <el-option
                :label="$t('vlink.dataType.enum')"
                value="enum"
              ></el-option>
              <el-option
                :label="$t('vlink.dataType.jsonObject')"
                value="jsonObject"
              ></el-option>
              <el-option
                :label="$t('vlink.dataType.stringList')"
                value="stringList"
              ></el-option>
              <el-option
                :label="$t('vlink.dataType.img')"
                value="img"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <!-- 访问权限 -->
          <el-form-item :label="$t('vlink.tsl.accessMode')" prop="accessMode">
            <el-select
              v-model="property.form.accessMode"
              :style="{ width: '100%' }"
            >
              <el-option :label="$t('vlink.commons.r')" value="r"></el-option>
              <el-option :label="$t('vlink.commons.w')" value="w"></el-option>
              <el-option :label="$t('vlink.commons.rw')" value="rw"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :span="12"
          v-if="
            property.form.dataType == 'int' ||
            property.form.dataType == 'decimal'
          "
        >
          <!-- 取值范围 -->
          <el-form-item :label="$t('vlink.tsl.valueRange')" prop="min">
            <div style="display: flex">
              <el-form-item prop="min">
                <el-input-number
                  style="width: 100%"
                  v-model="property.form.min"
                  controls-position="right"
                  :min="-2147483647"
                  :max="property.form.max - 1"
                ></el-input-number>
              </el-form-item>
              <span class="split-span">-</span>
              <el-form-item prop="max">
                <el-input-number
                  style="width: 100%"
                  v-model="property.form.max"
                  controls-position="right"
                  :min="property.form.min + 1"
                  :max="2147483647"
                ></el-input-number>
              </el-form-item>
            </div>
          </el-form-item>
        </el-col>
        <template
          v-if="
            property.form.dataType == 'int' ||
            property.form.dataType == 'decimal'
          "
        >
          <!-- <el-row :gutter="48"> -->
          <el-col :span="12">
            <!-- 步长 -->
            <el-form-item :label="$t('vlink.tsl.step')" prop="step">
              <el-input-number
                class="tsl-input-number"
                v-model="property.form.step"
                controls-position="right"
                :min="1"
                :max="50"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="12">
          <!-- 单位 -->
          <el-form-item :label="$t('vlink.tsl.unit')" prop="unit">
            <el-input
              v-model="property.form.unit"
              maxlength="16"
              :show-word-limit="true"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- </el-row> -->
        <template
          v-if="
            property.form.dataType !== 'int' &&
            property.form.dataType !== 'decimal'
          "
        >
          <!-- <el-row :gutter="48"> -->
          <el-col :span="12">
            <!-- 最大长度 -->
            <el-form-item :label="$t('vlink.tsl.maxLength')" prop="maxLength">
              <el-input-number
                class="tsl-input-number"
                v-model="property.form.maxLength"
                controls-position="right"
                :min="1"
                :max="2147483647"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <!-- </el-row> -->
        </template>
        <!-- 上报方式 -->
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.trigger')" prop="trigger">
            <el-select
              v-model="property.form.trigger"
              :style="{ width: '100%' }"
            >
              <el-option
                :label="$t('vlink.tsl.trigger1')"
                value="1"
              ></el-option>
              <el-option
                :label="$t('vlink.tsl.trigger2')"
                value="2"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 上报周期 -->
        <el-col :span="12" v-if="property.form.trigger === '1'">
          <el-form-item :label="$t('vlink.tsl.reportCycle')" prop="reportCycle">
            <el-input-number
              style="width: 100%"
              v-model="property.form.reportCycle"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.scaling')" prop="scaling">
            <el-input
              v-model="property.form.scaling"
              maxlength="20"
              :show-word-limit="true"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.precision')" prop="precision">
            <el-input
              v-model="property.form.precision"
              maxlength="20"
              :show-word-limit="true"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 描述 -->
          <el-form-item :label="$t('vlink.tsl.description')" prop="description">
            <el-input
              class="descript-input"
              v-model="property.form.description"
              maxlength="100"
              :rows="1"
              :show-word-limit="true"
              type="textarea"
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 是否是虚拟点 -->
          <el-form-item :label="$t('vlink.tsl.virtual')" prop="description">
            <el-select
              v-model="property.form.virtual"
              :style="{ width: '100%' }"
            >
              <el-option
                :label="$t('vlink.commons.true')"
                :value="true"
              ></el-option>
              <el-option
                :label="$t('vlink.commons.false')"
                :value="false"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="property.form.virtual">
          <!-- 描述 -->
          <el-form-item
            :label="$t('vlink.tsl.virtualExpression')"
            prop="description"
          >
            <el-input
              class="descript-input"
              v-model="property.form.virtualExpression"
              maxlength="100"
              :rows="1"
              :show-word-limit="true"
              type="textarea"
              :placeholder="$t('commons.pleaseInputExpression')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <template v-if="property.form.dataType == 'enum'">
        <!-- 枚举列表 -->
        <el-form-item :label="$t('vlink.tsl.enumList')">
          <el-button
            type="success"
            icon="el-icon-plus"
            size="mini"
            @click="addEnumItem"
            >{{ $t("vlink.tsl.addEnumItem") }}</el-button
          >
          <el-form-item
            v-for="(enumItem, index) in property.form.enumList"
            :key="index"
            style="margin-top: 10px"
          >
            <el-col>
              {{ index }}<span style="margin-left: 10px">-</span>
              <el-input
                v-model="property.form.enumList[index]"
                style="margin-left: 10px; width: 80%"
                maxlength="16"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
              <el-button
                type="text"
                @click="removeEnumItem(index)"
                style="margin-left: 10px"
              >
                <i class="el-icon-delete delete-btn"></i>
              </el-button>
            </el-col>
          </el-form-item>
        </el-form-item>
      </template>
      <!-- 扩展描述 -->
      <el-form-item :label="$t('vlink.tsl.extendConfig')" prop="extendConfig">
        <div class="extend-config-line">
          <el-switch
            v-model="property.form.needConfig"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
          <span
            :class="
              property.form.needConfig
                ? 'config-title-active'
                : 'config-title-default'
            "
          >
            {{ property.form.needConfig ? "已开启" : "未开启" }}
          </span>
          <span class="config-tips">
            <i class="el-icon-info"></i>
            关闭时，将不保存扩展配置。
          </span>
        </div>

        <property-info
          v-show="property.form.needConfig"
          ref="propInfoRef"
          :editable="true"
          :property="property.form"
          @cancelEditProperty="closeExtendConfigDialog"
        ></property-info>
      </el-form-item>
    </el-form>
    <div slot="footer" align="right" style="margin-bottom: 10px">
      <el-button @click="closeSaveProperty(false)">{{
        $t("commons.cancel")
      }}</el-button>
      <el-button :loading="loading" type="primary" @click="saveHandler">{{
        currentProperty.isEdit ? $t("commons.save") : $t("commons.add")
      }}</el-button>
    </div>
  </div>
</template>

<script>
// import CustomExtendConfig from '@/views/ruge/vlink/product/tsl/customExtendConfig'

import PropertyInfo from "./propertyConfig/propertyInfo";

import { findProductDetail, updateTsl } from "@/api/ruge/vlink/product/product";

export default {
  components: {
    PropertyInfo,
    // CustomExtendConfig
  },
  data() {
    return {
      loading: false,
      extendConfigDialogVisible: false,
      tslObj: {},
      property: {
        form: {
          propertyName: null,
          needConfig: false,
          trigger: "2",
          reportCycle: null,
          scaling: null,
          precision: null,
          virtual: false,
          virtualExpression: null,
        },
        formRules: {
          propertyName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              pattern: /^[A-Za-z0-9-]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          description: [
            {
              // pattern: /^[A-Za-z0-9-_@#()\u4e00-\u9fa5]+$/,
              message: this.$t("vlink.product.productNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          min: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              validator: (rule, value, callback) => {
                if (this.property.form.dataType == "int") {
                  //判断是否为整数
                  if (parseInt(value) == parseFloat(value)) {
                    callback();
                  }
                  callback(new Error(this.$t("vlink.tsl.tips8")));
                } else {
                  //判断是否为小数
                  if (
                    parseInt(value) < parseFloat(value) ||
                    parseInt(value) == parseFloat(value)
                  ) {
                    callback();
                  }
                  callback(new Error(this.$t("vlink.tsl.tips9")));
                }
              },
              trigger: ["blur", "change"],
            },
          ],
          max: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              validator: (rule, value, callback) => {
                if (this.property.form.dataType == "int") {
                  //判断是否为整数
                  if (parseInt(value) == parseFloat(value)) {
                    callback();
                  }
                  callback(new Error(this.$t("vlink.tsl.tips8")));
                } else {
                  //判断是否为小数
                  if (
                    parseInt(value) < parseFloat(value) ||
                    parseInt(value) == parseFloat(value)
                  ) {
                    callback();
                  }
                  callback(new Error(this.$t("vlink.tsl.tips9")));
                }
              },
              trigger: ["blur", "change"],
            },
          ],
          step: [
            {
              required: false,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              validator: (rule, value, callback) => {
                if (this.property.form.dataType == "int") {
                  //判断是否为整数
                  if (parseInt(value) == parseFloat(value)) {
                    callback();
                  }
                  callback(new Error(this.$t("vlink.tsl.tips8")));
                } else {
                  //判断是否为小数
                  if (
                    parseInt(value) < parseFloat(value) ||
                    parseInt(value) == parseFloat(value)
                  ) {
                    callback();
                  }
                  callback(new Error(this.$t("vlink.tsl.tips9")));
                }
              },
              trigger: ["blur", "change"],
            },
          ],
          maxLength: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              validator: (rule, value, callback) => {
                //判断是否为整数
                if (
                  parseInt(value) == parseFloat(value) &&
                  parseInt(value) > 0
                ) {
                  callback();
                }
                callback(new Error(this.$t("vlink.tsl.tips10")));
              },
              trigger: ["blur", "change"],
            },
          ],
          trigger: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          reportCycle: [
            {
              required: false,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              validator: (rule, value, callback) => {
                if (value < 1000) {
                  callback(new Error("上报周期最小1000毫秒"));
                  return;
                }
                callback();
              },
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  props: {
    tsl: {
      type: Object,
      required: true,
    },
    currentProperty: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentProperty: {
      immediate: true,
      handler: function () {
        // JSON.parse(JSON.stringify(obj)) 深拷贝，防止提交失败也会修改父组件数据现象
        this.tslObj = JSON.parse(
          JSON.stringify(
            Object.assign(
              { productKey: this.currentProperty.productKey },
              this.tsl
            )
          )
        );
        this.initProerptyForm();
      },
    },
  },
  methods: {
    initProerptyForm() {
      // 查询产品信息 （属性协议）
      findProductDetail({ productKey: this.tsl.productKey })
        .then((response) => {
          if (this.currentProperty.isEdit || this.currentProperty.isCopy) {
            let tempObj = JSON.parse(JSON.stringify(this.currentProperty.form));
            tempObj.min = Number(tempObj.min);
            if (tempObj.extendConfig) {
              // 如果有extendConfig，switch 打开
              const { needConfig, trigger, reportCycle } =
                JSON.parse(tempObj.extendConfig) || {};
              tempObj.needConfig = needConfig;
              tempObj.trigger = trigger;
              tempObj.reportCycle = reportCycle;
              tempObj.extendConfig = JSON.stringify(
                JSON.parse(tempObj.extendConfig),
                null,
                "\t"
              );
            }
            this.property.form = {
              ...tempObj,
            };
            console.log("xxx1", this.property.form);
          } else {
            this.property.form = {
              propertyName: null,
              description: null,
              required: false,
              dataType: "int",
              accessMode: "r",
              min: 1,
              max: 65535,
              step: 1,
              maxLength: 1,
              enumList: null,
              extendConfig: null,
              needConfig: false,
              trigger: "2",
              virtual: false,
              virtualExpression: null,
            };
          }
          this.property.form.protocolName = response.protocol;
        })
        .catch((error) => {
          console.log(`服务请求失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeSaveProperty(moduleName) {
      this.$emit("closeSaveProperty", moduleName);
    },
    saveHandler() {
      if (this.property.form.needConfig) {
        this.$refs["propInfoRef"].saveEmit(this.property.form.protocolName);
      } else {
        this.closeExtendConfigDialog();
      }
    },
    closeExtendConfigDialog(extendConfig) {
      if (extendConfig) {
        let temp = JSON.parse(extendConfig);
        let tempObj = {
          trigger: this.property.form.trigger,
          reportCycle: this.property.form.reportCycle,
          needConfig: this.property.form.needConfig,
        };
        extendConfig = JSON.stringify({ ...temp, ...tempObj });
      } else {
        extendConfig = JSON.stringify({
          needConfig: this.property.form.needConfig,
          trigger: this.property.form.trigger,
          reportCycle: this.property.form.reportCycle,
        });
      }
      this.property.form.extendConfig = extendConfig || null;
      this.handleSaveProperty();
    },
    handleSaveProperty() {
      this.$refs.propertyForm.validate((v) => {
        if (v) {
          if (
            this.tslObj &&
            this.tslObj.modules &&
            this.tslObj.modules.length > 0
          ) {
            // 获取当前模块
            let currModule = this.tslObj.modules.find((item) => {
              return item.moduleName == this.currentProperty.moduleName;
            });
            // 找不到对应的模块
            if (!currModule) {
              this.$message({
                message: this.$t("vlink.tsl.tips3"),
                type: "warning",
              });
              return;
            }
            // 编辑
            if (this.currentProperty.isEdit) {
              currModule.properties = currModule.properties.map((item) => {
                return item.propertyName == this.property.form.propertyName
                  ? Object.assign({}, this.property.form)
                  : item;
              });
            } else {
              // 添加时，propertyName唯一性校验
              if (currModule.properties && currModule.properties.length > 0) {
                let obj = currModule.properties.find((item) => {
                  return item.propertyName == this.property.form.propertyName;
                });
                if (obj) {
                  this.$message({
                    message: this.$t("vlink.tsl.tips4"),
                    type: "warning",
                  });
                  return;
                }
              }
              if (!currModule.properties) {
                currModule.properties = [];
              }
              // 将属性添加到模块中
              currModule.properties.push(Object.assign({}, this.property.form));
            }

            this.loading = true;
            this.tslObj.modules = this.tslObj.modules.map((item) => {
              return item.moduleName == currModule.moduleName
                ? currModule
                : item;
            });
            // 保存tsl
            return updateTsl(this.tslObj)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.closeSaveProperty(currModule.moduleName);
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    addEnumItem() {
      if (!this.property.form.enumList) {
        this.property.form.enumList = [];
      }
      this.property.form.enumList.push("");
    },
    removeEnumItem(index) {
      if (
        this.property.form.enumList &&
        this.property.form.enumList.length > 0
      ) {
        this.property.form.enumList.splice(index, 1);
      }
    },
    // closeExtendConfigDialog(v) {
    //   if (v && typeof v != "function") {
    //     this.property.form.extendConfig = v;
    //   }
    //   this.extendConfigDialogVisible = false;
    // },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tsl-input-number {
  width: 100%;

  .el-input {
    input {
      text-align: left !important;
    }
  }
}
.split-span {
  padding: 0 5px;
}
// /deep/ .descript-input {
.descript-input .el-input__count {
  top: 1px;
  height: 30px;
}
.extend-config-line {
  display: flex;
  align-items: center;
  .config-title-active {
    color: #13ce66;
    margin-left: 10px;
  }
  .config-title-default {
    color: #ccc;
    margin-left: 10px;
  }
  .config-tips {
    margin-left: 15px;
    font-size: 12px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ccc;
  }
}
// }
</style>
