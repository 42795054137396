var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "opcuaForm",
          attrs: {
            "label-position": "top",
            rules: _vm.opcuaRule,
            model: _vm.opcuaForm,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.originalDataType"),
                        prop: "originalDataType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.opcuaForm.originalDataType,
                            callback: function ($$v) {
                              _vm.$set(_vm.opcuaForm, "originalDataType", $$v)
                            },
                            expression: "opcuaForm.originalDataType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.int16"),
                              value: "int16",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.uint16"),
                              value: "uint16",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.int32"),
                              value: "int32",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.uint32"),
                              value: "uint32",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.int64"),
                              value: "int64",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.uint64"),
                              value: "uint64",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.float"),
                              value: "float",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.double"),
                              value: "double",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.enum"),
                              value: "enum",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.bool"),
                              value: "bool",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.date"),
                              value: "date",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.text"),
                              value: "text",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.displayName"),
                        prop: "displayName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "50",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.opcuaForm.displayName,
                          callback: function ($$v) {
                            _vm.$set(_vm.opcuaForm, "displayName", $$v)
                          },
                          expression: "opcuaForm.displayName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }