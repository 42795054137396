<template>
  <div>
    <ace-editor
      key="customizeJson"
      v-model="customizeForm.customizeJson"
      @init="editorInit"
      lang="json"
      theme="chrome"
      style="border: 1px solid #ebecec"
      height="320"
    ></ace-editor>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";

export default {
  components: { AceEditor },
  props: {
    property: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    property: {
      immediate: true,
      handler: function () {
        this.initProperty();
      },
    },
  },
  data() {
    return {
      customizeForm: {
        customizeJson: null,
      },
    };
  },
  methods: {
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(!this.editable);
    },
    initProperty() {
      if (this.property) {
        this.customizeForm.customizeJson = this.property.extendConfig
          ? this.property.extendConfig
          : "";
      }
    },
    cancelEditProperty(v) {
      let param = this.buildParam();
      this.$emit("cancelEditProperty", param);
    },
    buildParam() {
      // let param = {
      //   extendConfig: ,
      // };
      return this.customizeForm.customizeJson;
    },
  },
};
</script>
