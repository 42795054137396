var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.property.protocolName == "modbus"
        ? [
            _c("property-modbus", {
              ref: "modbus",
              attrs: { editable: _vm.editable, property: _vm.property },
              on: { cancelEditProperty: _vm.cancelEditProperty },
            }),
          ]
        : _vm.property.protocolName == "opcua"
        ? [
            _c("property-opcua", {
              ref: "opcua",
              attrs: { editable: _vm.editable, property: _vm.property },
              on: { cancelEditProperty: _vm.cancelEditProperty },
            }),
          ]
        : _vm.property.protocolName == "bacnet"
        ? [
            _c("property-bacnet", {
              ref: "bacnet",
              attrs: { editable: _vm.editable, property: _vm.property },
              on: { cancelEditProperty: _vm.cancelEditProperty },
            }),
          ]
        : [
            _c("property-customize", {
              ref: "custom",
              attrs: { editable: _vm.editable, property: _vm.property },
              on: { cancelEditProperty: _vm.cancelEditProperty },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }