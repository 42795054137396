<template>
  <div class="single-tsl-component">
    <div class="desc-container">
      <span v-if="descriptionEdit">
        <el-input
          ref="descInput"
          v-model="dataset.description"
          @keyup.enter.native="updateDesc"
          @blur="updateDesc"
          size="mini"
        ></el-input>
      </span>
      <span v-else class="show-input">
        {{ dataset.description || "请填写描述" }}
      </span>
      <i class="el-icon-edit" @click="showDescriptionEdit"></i>
    </div>
    <!-- 属性卡片 -->
    <div class="card-container">
      <div
        class="title-line"
        @click="cardDetailShow.prop = !cardDetailShow.prop"
      >
        <span class="title-line-name">
          <span> 属性 </span>
          <span>
            <el-tag type="info" class="title-count-tag">{{
              propDataset.tableData.length
            }}</el-tag>
          </span>
        </span>
        <span class="arrow-cont">
          <i v-if="cardDetailShow.prop" class="el-icon-arrow-down"></i>
          <i v-else class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div
        :style="{
          height: cardDetailShow.prop ? 'auto' : '0px',
        }"
        class="card-toggle-container"
      >
        <div class="button-line">
          <el-button type="primary" plain @click="addProperty"
            >添加属性</el-button
          >
          <el-button
            @click="propDataset.filterLine.show = !propDataset.filterLine.show"
            class="header-filter-button"
          >
            筛选属性
            <i
              class="el-icon-arrow-down"
              v-if="propDataset.filterLine.show"
            ></i>
            <i class="el-icon-arrow-right" v-else></i>
          </el-button>
        </div>
        <div class="table-container">
          <finalTable
            :datas="propDataset"
            @tableEventHandler="(datas) => tableEventHandler(datas, 'prop')"
          />
        </div>
      </div>
    </div>
    <!-- 事件卡片 -->
    <div class="card-container">
      <div
        class="title-line"
        @click="cardDetailShow.event = !cardDetailShow.event"
      >
        <span class="title-line-name">
          <span> 事件 </span>
          <span>
            <el-tag type="info" class="title-count-tag">{{
              eventDataset.tableData.length
            }}</el-tag>
          </span>
        </span>
        <span class="arrow-cont">
          <i v-if="cardDetailShow.event" class="el-icon-arrow-down"></i>
          <i v-else class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div v-show="cardDetailShow.event">
        <div class="button-line">
          <el-button type="primary" plain @click="addEvent">添加事件</el-button>
          <el-button
            @click="
              eventDataset.filterLine.show = !eventDataset.filterLine.show
            "
            class="header-filter-button"
          >
            筛选事件
            <i
              class="el-icon-arrow-down"
              v-if="eventDataset.filterLine.show"
            ></i>
            <i class="el-icon-arrow-right" v-else></i>
          </el-button>
        </div>
        <div class="table-container">
          <finalTable
            :datas="eventDataset"
            @tableEventHandler="(datas) => tableEventHandler(datas, 'event')"
          />
        </div>
      </div>
    </div>
    <!-- 服务卡片 -->
    <div class="card-container">
      <div
        class="title-line"
        @click="cardDetailShow.service = !cardDetailShow.service"
      >
        <span class="title-line-name">
          <span> 服务 </span>
          <span>
            <el-tag type="info" class="title-count-tag">{{
              serviceDataset.tableData.length
            }}</el-tag>
          </span>
        </span>
        <span class="arrow-cont">
          <i v-if="cardDetailShow.service" class="el-icon-arrow-down"></i>
          <i v-else class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div v-show="cardDetailShow.service">
        <div class="button-line">
          <el-button type="primary" plain @click="addService"
            >添加服务</el-button
          >
          <el-button
            @click="
              serviceDataset.filterLine.show = !serviceDataset.filterLine.show
            "
            class="header-filter-button"
          >
            筛选服务
            <i
              class="el-icon-arrow-down"
              v-if="serviceDataset.filterLine.show"
            ></i>
            <i class="el-icon-arrow-right" v-else></i>
          </el-button>
        </div>
        <div class="table-container">
          <finalTable
            :datas="serviceDataset"
            @tableEventHandler="(datas) => tableEventHandler(datas, 'service')"
          />
        </div>
      </div>
    </div>

    <!-- 属性 - 添加/复制/编辑弹框 -->
    <el-dialog
      width="728px"
      :wrapperClosable="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="
        () => {
          property.addDialogVisible = false;
        }
      "
      :visible.sync="property.addDialogVisible"
    >
      <template slot="title">
        <span>{{
          property.isEdit
            ? $t("vlink.tsl.editProperty")
            : property.isCopy
            ? "复制属性"
            : $t("vlink.tsl.addProperty")
        }}</span>
      </template>
      <edit-property
        v-if="property.addDialogVisible"
        :tsl="tsl"
        :currentProperty="property"
        @closeSaveProperty="closeSaveProperty"
      ></edit-property>
    </el-dialog>

    <!-- 事件 - 添加/复制/编辑弹框 -->
    <el-dialog
      width="728px"
      :wrapperClosable="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="
        () => {
          event.addDialogVisible = false;
        }
      "
      :visible.sync="event.addDialogVisible"
    >
      <template slot="title">
        <span>{{
          event.isEdit ? $t("vlink.tsl.editEvent") : $t("vlink.tsl.addEvent")
        }}</span>
      </template>
      <EditEvent
        v-if="event.addDialogVisible"
        :tsl="tsl"
        :currentEvent="event"
        @closeSaveEvent="closeSaveEvent"
      />
    </el-dialog>

    <!-- 服务 - 添加/复制/编辑弹框 -->
    <el-dialog
      width="728px"
      :wrapperClosable="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="
        () => {
          service.addDialogVisible = false;
        }
      "
      :visible.sync="service.addDialogVisible"
    >
      <template slot="title">
        <span>{{
          service.isEdit
            ? $t("vlink.tsl.editService")
            : $t("vlink.tsl.addService")
        }}</span>
      </template>
      <EditService
        v-if="service.addDialogVisible"
        :tsl="tsl"
        :currentService="service"
        @closeSaveService="closeSaveService"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { deepClone } from "@/utils/utils.js";
import EditProperty from "./editProperty";
import EditEvent from "./editEvent";
import EditService from "./editService";
import { updateTsl } from "@/api/ruge/vlink/product/product";
export default {
  name: "singleTslComponent",
  components: {
    EditProperty,
    EditEvent,
    EditService,
    finalTable,
  },
  data() {
    return {
      // 备份过滤数据
      propDatas: [],
      eventDatas: [],
      serviceDatas: [],
      // 卡片显示/隐藏
      cardDetailShow: {
        prop: true,
        event: true,
        service: true,
      },
      // table配置
      propDataset: {
        paginationConfig: {
          need: false,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "propertyName", label: "属性名称", width: "" },
          { prop: "description", label: "描述", width: "" },
          { prop: "dataType", label: "数据类型", width: "" },
          { prop: "accessMode", label: "访问权限", width: "" },
          { prop: "valueRangeSpecial", label: "取值范围", width: "" },
          { prop: "step", label: "步长", width: "" },
          { prop: "operation", label: "操作", width: "135" },
        ],
        tableData: [],
        searchLineConfig: {
          propertyName: {
            type: "input",
            label: "属性名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入属性名称",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          dataType: {
            type: "selector",
            label: "数据类型",
            value: "",
            placeholder: "请选择数据类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "int(整数)",
                value: "int",
              },
              {
                label: "decimal(小数)",
                value: "decimal ",
              },
              {
                label: "string(字符串)",
                value: "string",
              },
              {
                label: "dateTime(日期时间)",
                value: "dateTime",
              },
              {
                label: "enum(枚举)",
                value: "enum",
              },
              {
                label: "jsonObject(JSON结构体)",
                value: "jsonObject",
              },
              {
                label: "stringList(字符串数组)",
                value: "stringList",
              },
              {
                label: "img(图片)",
                value: "img",
              },
            ],
          },
          accessMode: {
            type: "selector",
            label: "访问权限",
            value: "",
            placeholder: "请选择访问权限",
            actionType: "goSearch",
            optionList: [
              {
                label: "只读",
                value: "r",
              },
              {
                label: "只写",
                value: "w",
              },
              {
                label: "读写",
                value: "rw",
              },
            ],
          },
        },
        detailConfig: {
          // valueRange: {
          //   type: "joinList",
          //   joinSymbol: "-",
          //   joinList: ["min", "max"],
          // },
          dataType: {
            type: "enumerationColumn",
            emuList: {
              int: "int(整数)",
              decimal: "decimal(小数)",
              string: "string(字符串)",
              dateTime: "dateTime(日期时间)",
              enum: "enum(枚举)",
              jsonObject: "jsonObject(JSON结构体)",
              stringList: "stringList(字符串数组)",
              img: "img(图片)",
            },
          },
          accessMode: {
            type: "enumerationColumn",
            emuList: {
              r: "只读",
              w: "只写",
              rw: "读写",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "copy",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "复制",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {
          propertyName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          dataType: {
            inline: true,
            value: "",
          },
          accessMode: {
            inline: true,
            value: "",
          },
          step: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      eventDataset: {
        paginationConfig: {
          need: false,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "eventName", label: "事件名称", width: "" },
          { prop: "description", label: "描述", width: "" },
          { prop: "eventType", label: "事件类型", width: "" },
          { prop: "outputData", label: "上报参数", width: "" },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        searchLineConfig: {
          eventName: {
            type: "input",
            label: "属性名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入属性名称",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          eventType: {
            type: "selector",
            label: "事件类型",
            value: "",
            placeholder: "请选择事件类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "信息",
                value: "info",
              },
              {
                label: "告警",
                value: "alarm ",
              },
              {
                label: "故障",
                value: "fault",
              },
            ],
          },
        },
        detailConfig: {
          outputData: {
            type: "deepListJoin",
            first: "outputData",
            second: "paramName",
            joinSymbol: ",",
          },
          eventType: {
            type: "enumerationColumn",
            emuList: {
              info: "信息",
              alarm: "告警",
              fault: "故障",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {
          eventName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          eventType: {
            inline: true,
            value: "",
          },
          outputData: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      serviceDataset: {
        paginationConfig: {
          need: false,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "serviceName", label: "服务名称", width: "" },
          { prop: "description", label: "描述", width: "" },
          { prop: "inputData", label: "下发参数", width: "" },
          { prop: "outputData", label: "响应参数", width: "" },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        searchLineConfig: {
          serviceName: {
            type: "input",
            label: "属性名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入服务名称",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
        },
        detailConfig: {
          inputData: {
            type: "deepListJoin",
            first: "inputData",
            second: "paramName",
            joinSymbol: ",",
          },
          outputData: {
            type: "deepListJoin",
            first: "outputData",
            second: "paramName",
            joinSymbol: ",",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {
          propertyName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          dataType: {
            inline: true,
            value: "",
          },
          accessMode: {
            inline: true,
            value: "",
          },
          step: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      descriptionEdit: false,
      property: {
        addDialogVisible: false,
        productKey: null,
        moduleName: null,
        isEdit: false,
        form: {},
      },
      event: {
        addDialogVisible: false,
        productKey: null,
        moduleName: null,
        isEdit: false,
        form: {},
      },
      service: {
        addDialogVisible: false,
        productKey: null,
        moduleName: null,
        isEdit: false,
        form: {},
      },
    };
  },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
    currentRow: {
      type: Object,
      required: true,
    },
    tsl: {
      type: Object,
      required: true,
    },
  },
  watch: {
    dataset: {
      handler(val) {
        this.loadDatas(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    loadDatas(datas) {
      const propDatas =
        datas.properties && datas.properties.length > 0 ? datas.properties : [];
      const eventDatas =
        datas.events && datas.events.length > 0 ? datas.events : [];
      const serviceDatas =
        datas.services && datas.services.length > 0 ? datas.services : [];
      propDatas.forEach((item) => {
        if (
          ["string", "dateTime", "enum", "jsonObject", "stringList"].includes(
            item.dataType
          )
        ) {
          item.valueRangeSpecial = item.maxLength;
        } else {
          item.valueRangeSpecial = `${item.min}-${item.max}`;
        }
      });
      this.propDataset.tableData = propDatas;
      this.propDatas = propDatas;
      this.eventDataset.tableData = eventDatas;
      this.eventDatas = eventDatas;
      this.serviceDataset.tableData = serviceDatas;
      this.serviceDatas = serviceDatas;
    },
    tableEventHandler(datas, tslType) {
      switch (tslType) {
        case "prop":
          this.propDatasFilter(datas);
          break;
        case "event":
          this.eventDatasFilter(datas);
          break;
        case "service":
          this.serviceDatasFilter(datas);
          break;
      }
    },
    // 属性列表事件处理
    propDatasFilter(datas) {
      if (datas.type === "goSearch") {
        let filterLine = this.propDatas;
        Object.keys(datas.params).forEach((val) => {
          // 如果查询项的值为空则不过滤
          if (!datas.params[val]) return;
          filterLine = filterLine.filter((item) => {
            // 如果是取值范围，需要特殊处理，取 min ~ max
            if (val === "valueRange") {
              return (
                datas.params[val] >= item["min"] &&
                datas.params[val] <= item["max"]
              );
            }
            // 其他模糊匹配
            return item[val] && item[val].includes(datas.params[val]);
          });
        });
        this.propDataset.tableData = deepClone(filterLine);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "copy":
            this.copyProperty(datas.row);
            break;
          case "edit":
            this.editProperty(datas.row);
            break;
          case "delete":
            this.deleteProperty(datas.row.propertyName);
            break;
        }
      }
    },
    // 事件列表事件处理
    eventDatasFilter(datas) {
      if (datas.type === "goSearch") {
        let filterLine = this.eventDatas;
        Object.keys(datas.params).forEach((val) => {
          if (!datas.params[val]) return;
          filterLine = filterLine.filter((item) => {
            return item[val] && item[val].includes(datas.params[val]);
          });
        });
        this.eventDataset.tableData = deepClone(filterLine);
        // this.filterObj = { ...this.filterObj, ...datas.params };
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editEvent(datas.row);
            break;
          case "delete":
            this.deleteEvent(datas.row.eventName);
            break;
        }
      }
    },
    serviceDatasFilter(datas) {
      if (datas.type === "goSearch") {
        let filterLine = this.serviceDatas;
        Object.keys(datas.params).forEach((val) => {
          if (!datas.params[val]) return;
          filterLine = filterLine.filter((item) => {
            return item[val] && item[val].includes(datas.params[val]);
          });
        });
        this.serviceDataset.tableData = deepClone(filterLine);
        // this.filterObj = { ...this.filterObj, ...datas.params };
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editService(datas.row);
            break;
          case "delete":
            this.deleteService(datas.row.serviceName);
            break;
        }
      }
    },
    updateDesc() {
      this.$emit("updateDesc", this.dataset);
      this.descriptionEdit = false;
    },
    showDescriptionEdit() {
      this.descriptionEdit = true;
      this.$nextTick(() => {
        this.$refs.descInput.focus();
        this.$refs.descInput.select();
      });
    },
    // 添加属性
    addProperty() {
      this.property.productKey = this.currentRow.productKey;
      this.property.moduleName = this.dataset.moduleName;
      this.property.isEdit = false;
      this.property.isCopy = false;
      this.property.addDialogVisible = true;
    },
    // 复制属性
    copyProperty(propertyObj) {
      this.property.productKey = this.currentRow.productKey;
      this.property.moduleName = this.dataset.moduleName;
      this.property.isEdit = false;
      this.property.isCopy = true;
      this.property.form = Object.assign({}, propertyObj);
      this.property.addDialogVisible = true;
    },
    // 编辑属性
    editProperty(propertyObj) {
      this.property.productKey = this.currentRow.productKey;
      this.property.moduleName = this.dataset.moduleName;
      this.property.isEdit = true;
      this.property.form = Object.assign({}, propertyObj);
      this.property.addDialogVisible = true;
    },
    // 删除属性
    deleteProperty(propertyName) {
      this.$confirm(
        "删除可能导致此属性的规则失效，确定要删除吗？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
            // 获取当前模块
            let currModule = this.tsl.modules.find((item) => {
              return item.moduleName == this.dataset.moduleName;
            });
            // 找不到对应的模块
            if (!currModule) {
              this.$message({
                message: this.$t("vlink.tsl.tips3"),
                type: "warning",
              });
              return;
            }
            currModule.properties = currModule.properties.filter(
              (item) => item.propertyName !== propertyName
            );

            this.loading = true;
            let tslObj = Object.assign(
              { productKey: this.currentRow.productKey },
              this.tsl
            );
            tslObj.modules = tslObj.modules.map((item) => {
              return item.moduleName == currModule.moduleName
                ? currModule
                : item;
            });
            // 保存tsl
            updateTsl(tslObj)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.deleteSuccess"),
                  type: "success",
                });
                // this.activeTabName = currModule.moduleName;
                this.$emit("updateObject", this.dataset.moduleName);
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 关闭属性弹框
    closeSaveProperty(moduleName) {
      this.property.addDialogVisible = false;
      moduleName && this.$emit("updateObject", moduleName);
    },
    // 新增事件
    addEvent() {
      this.event.productKey = this.currentRow.productKey;
      this.event.moduleName = this.dataset.moduleName;
      this.event.isEdit = false;
      this.event.addDialogVisible = true;
    },
    // 编辑事件
    editEvent(eventObj) {
      this.event.productKey = this.currentRow.productKey;
      this.event.moduleName = this.dataset.moduleName;
      this.event.isEdit = true;
      this.event.form = Object.assign({}, eventObj);
      this.event.addDialogVisible = true;
    },
    // 更新事件
    closeSaveEvent(moduleName) {
      console.log("moduleName", moduleName);
      this.event.addDialogVisible = false;
      // this.$emit("updateObject", this.tsl);
      if (moduleName) this.$emit("updateObject", moduleName);
    },
    // 删除事件
    deleteEvent(eventName) {
      this.$confirm(
        "删除可能导致此事件的规则失效，确定要删除吗？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
            // 获取当前模块
            let currModule = this.tsl.modules.find((item) => {
              return item.moduleName == this.dataset.moduleName;
            });
            // 找不到对应的模块
            if (!currModule) {
              this.$message({
                message: this.$t("vlink.tsl.tips3"),
                type: "warning",
              });
              return;
            }
            currModule.events = currModule.events.filter(
              (item) => item.eventName !== eventName
            );

            this.loading = true;
            let tslObj = Object.assign(
              { productKey: this.currentRow.productKey },
              this.tsl
            );
            tslObj.modules = tslObj.modules.map((item) => {
              return item.moduleName == currModule.moduleName
                ? currModule
                : item;
            });
            // 保存tsl
            return updateTsl(tslObj)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.deleteSuccess"),
                  type: "success",
                });
                // this.tsl = tslObj;
                // this.activeTabName = currModule.moduleName;
                this.$emit("updateObject", this.dataset.moduleName);
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 添加服务
    addService() {
      this.service.productKey = this.currentRow.productKey;
      this.service.moduleName = this.dataset.moduleName;
      this.service.isEdit = false;
      this.service.addDialogVisible = true;
    },
    // 关闭属性弹框
    closeSaveService(moduleName) {
      this.service.addDialogVisible = false;
      moduleName && this.$emit("updateObject", moduleName);
    },
    // 编辑服务
    editService(serviceObj) {
      this.service.productKey = this.currentRow.productKey;
      this.service.moduleName = this.dataset.moduleName;
      this.service.isEdit = true;
      this.service.form = Object.assign({}, serviceObj);
      this.service.addDialogVisible = true;
    },
    deleteService(serviceName) {
      this.$confirm(
        "删除可能导致此服务的规则失效，确定要删除吗？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
            // 获取当前模块
            let currModule = this.tsl.modules.find((item) => {
              return item.moduleName == this.dataset.moduleName;
            });
            // 找不到对应的模块
            if (!currModule) {
              this.$message({
                message: this.$t("vlink.tsl.tips3"),
                type: "warning",
              });
              return;
            }
            currModule.services = currModule.services.filter(
              (item) => item.serviceName !== serviceName
            );

            this.loading = true;
            let tslObj = Object.assign(
              { productKey: this.currentRow.productKey },
              this.tsl
            );
            tslObj.modules = tslObj.modules.map((item) => {
              return item.moduleName == currModule.moduleName
                ? currModule
                : item;
            });
            // 保存tsl
            return updateTsl(tslObj)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.deleteSuccess"),
                  type: "success",
                });
                this.$emit("updateObject", this.dataset.moduleName);
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
  },
};
</script>

<style scoped lang="less">
.single-tsl-component {
  .desc-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    .show-input {
      opacity: 0.5;
    }
    .el-icon-edit {
      margin-left: 10px;
      height: 18;
      width: 18px;
      cursor: pointer;
      &:hover {
        color: #1a4cec;
      }
    }
  }
  .card-container {
    margin-top: 32px;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px #00000016;
    border-radius: 4px;
    .title-line {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .title-count-tag {
        margin-left: 5px;
        border: none;
        height: 21px;
        line-height: 21px;
        font-size: 16px;
      }
      .title-line-name {
        display: inline-block;
        height: 21px;
        line-height: 21px;
      }
      .arrow-cont {
        margin-left: 10px;
        font-size: 16px;
        color: #ccc;
      }
      &:hover {
        .arrow-cont {
          color: #1a4cec;
        }
      }
    }
    .button-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
      .filter-button-open {
        color: #409eff;
        background: #ecf5ff;
        border: 1px solid #c6e2ff;
      }
      .filter-button-close {
        color: #2a4158;
        background: #ffffff;
        border: 1px solid #e7e8eb;
      }
    }
    .table-container {
      margin-top: 10px;
    }
    .card-toggle-container {
      overflow: hidden;
      transition: max-height 0.5s;
    }
  }
}
</style>