<template>
  <div v-loading="loadingFlag">
    <el-tabs v-model="activeName">
      <el-tab-pane
        :label="$t('vlink.topic.basicTopic')"
        name="basic"
        v-if="
          this.currentRow.nodeType == 'GATEWAY' ||
          this.currentRow.nodeType == 'DIRECTDEVICE'
        "
      >
        <el-table
          :span-method="basicSpanMethod"
          :data="basicTableView"
          style="width: 100%"
        >
          <el-table-column
            width="150"
            prop="feature"
            :label="$t('vlink.topic.feature')"
          >
          </el-table-column>
          <el-table-column
            width="500"
            prop="topicClass"
            :label="$t('vlink.topic.topicClass')"
          >
          </el-table-column>
          <el-table-column
            width="150"
            prop="security"
            :label="$t('vlink.topic.security')"
          >
          </el-table-column>
          <el-table-column prop="desc" :label="$t('vlink.topic.desc')">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('vlink.topic.modelTopic')"
        name="model"
        v-if="
          this.currentRow.nodeType == 'GATEWAY' ||
          this.currentRow.nodeType == 'DIRECTDEVICE'
        "
      >
        <el-table
          :span-method="modelSpanMethod"
          :data="modelTableView"
          style="width: 100%"
        >
          <el-table-column
            width="150"
            prop="feature"
            :label="$t('vlink.topic.feature')"
          >
          </el-table-column>
          <el-table-column
            width="500"
            prop="topicClass"
            :label="$t('vlink.topic.topicClass')"
          >
          </el-table-column>
          <el-table-column
            width="150"
            prop="security"
            :label="$t('vlink.topic.security')"
          >
          </el-table-column>
          <el-table-column prop="desc" :label="$t('vlink.topic.desc')">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.topic.customTopic')" name="customTopic">
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="margin: 10px 0 20px"
          @click="createCustomTopic"
          plain
        >
          {{ $t("commons.add") }}
        </el-button>
        <el-table :data="customTopic.list" style="width: 100%">
          <el-table-column
            prop="topicClassName"
            :label="$t('vlink.topic.customTopic')"
          >
          </el-table-column>
          <el-table-column
            prop="operationAuthority"
            :label="$t('vlink.topic.security')"
          >
          </el-table-column>
          <el-table-column prop="description" :label="$t('vlink.topic.desc')">
          </el-table-column>
          <el-table-column
            :label="$t('commons.actions')"
            align="left"
            width="100"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="$t('commons.edit')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <i
                  class="el-icon-edit operation-icon"
                  @click="editCustomTopic(scope.row)"
                >
                </i>
                <!-- <el-button
                  size="mini"
                  icon="el-icon-edit"
                  type="primary"
                  circle
                  @click="editCustomTopic(scope.row)"
                >
                </el-button> -->
              </el-tooltip>
              <el-tooltip
                :content="$t('commons.delete')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <i
                  class="el-icon-delete operation-icon"
                  @click="deleteCustomTopic(scope.row.topicId)"
                >
                </i>
                <!-- <el-button
                  size="mini"
                  icon="el-icon-delete"
                  type="danger"
                  circle
                  @click="deleteCustomTopic(scope.row.topicId)"
                >
                </el-button> -->
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 添加 自定义Topic 对话框 -->
        <el-dialog
          :visible.sync="customTopic.createCustomTopicDialogVisible"
          :before-close="cancelCustomTopicSave"
          width="45%"
          :destroy-on-close="true"
          :close-on-click-modal="false"
        >
          <template slot="title">
            {{ customTopic.title }}
          </template>
          <el-form
            label-position="top"
            :model="customTopic.form"
            ref="customTopicForm"
            :rules="customTopic.formRules"
          >
            <el-form-item
              :label="$t('vlink.product.deviceOperationAuthority')"
              prop="operationAuthority"
            >
              <el-select
                v-model="customTopic.form.operationAuthority"
                :disabled="editFlag === 'edit'"
                style="width: 50%"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in customTopic.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('vlink.product.topicClass')"
              prop="topicShortName"
            >
              <el-input
                v-model="customTopic.form.topicShortName"
                :disabled="editFlag === 'edit'"
                maxlength="30"
                :show-word-limit="true"
                class="prepend-input"
                ref="prependInputRef"
                @focus="prependInputStateToggle(true)"
                @blur="prependInputStateToggle(false)"
                clearable
              >
                <template slot="prepend">
                  <span @click="activeInput">
                    {{ prependRender() }}
                  </span></template
                >
              </el-input>
            </el-form-item>
            <el-form-item
              :label="$t('vlink.product.customTopicDescription')"
              prop="description"
            >
              <el-input
                v-model="customTopic.form.description"
                maxlength="100"
                :show-word-limit="true"
                clearable
                style="width: 50%"
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="cancelCustomTopicSave">{{
              $t("commons.cancel")
            }}</el-button>
            <el-button type="primary" @click="handleCustomTopicSave">{{
              $t("commons.add")
            }}</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  createCustomTopic,
  findCustomTopicList,
  updateCustomTopic,
  deleteCustomTopic,
} from "../../../../api/ruge/vlink/product/product";

export default {
  data() {
    return {
      editFlag: "add",
      loadingFlag: false,
      activeName: "basic",
      basicTableView: [],
      modelTableView: [],
      customTableView: [],
      basicTable: [
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/register",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkRegister"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/register/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkRegisterResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/register",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDeviceRegister"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/register/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDeviceRegisterResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/login",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkLogin"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/login/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkLoginResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/logout",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkLogout"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/logout/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkLogoutResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/login",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDeviceLogin"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/login/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDeviceLoginResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/logout",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDeviceLogout"),
        },
        {
          feature: this.$t("vlink.topic.deviceJoin"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/logout/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDeviceLogoutResponse"),
        },

        {
          feature: this.$t("vlink.topic.deviceConfig"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/config/deploy",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.cloudDeployDeviceConfig"),
        },
        {
          feature: this.$t("vlink.topic.deviceConfig"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/config/deploy/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.cloudDeployDeviceConfigResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceConfig"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/config/get",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.deviceObtainConfig"),
        },
        {
          feature: this.$t("vlink.topic.deviceConfig"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/config/get/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.deviceObtainConfigResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceConfig"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/config/get",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.getawayObtainSubDeviceConfig"),
        },
        {
          feature: this.$t("vlink.topic.deviceConfig"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/config/get/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.getawayObtainSubDeviceConfigResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass: "sys/{productKey}/{deviceName}/ota/device/config/get",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OtaDeviceConfigGet"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass:
            "sys/{productKey}/{deviceName}/ota/device/config/get/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.OtaDeviceConfigGetResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass: "sys/{productKey}/{deviceName}/ota/device/config/deploy",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OtaDeviceConfigDeploy"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass:
            "sys/{productKey}/{deviceName}/ota/device/config/deploy/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.OtaDeviceConfigDeployResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass: "sys/{productKey}/{deviceName}/ota/device/log/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OtaDeviceLogReport"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass: "sys/{productKey}/{deviceName}/ota/sub/config/get",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OtaSubConfigGet"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass:
            "sys/{productKey}/{deviceName}/ota/sub/config/get/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.OtaSubConfigGetResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass: "sys/{productKey}/{deviceName}/ota/sub/config/deploy",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OtaSubConfigDeploy"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass:
            "sys/{productKey}/{deviceName}/ota/sub/config/deploy/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.OtaSubConfigDeployResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceOTA"),
          topicClass: "sys/{productKey}/{deviceName}/ota/sub/log/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OtaSubLogReport"),
        },
        {
          feature: this.$t("vlink.topic.deviceLog"),
          topicClass: "sys/{productKey}/{deviceName}/log/device/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.logDeviceReport"),
        },
        {
          feature: this.$t("vlink.topic.deviceLog"),
          topicClass:
            "sys/{productKey}/{deviceName}/log/device/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.logDeviceReportResponse"),
        },
        {
          feature: this.$t("vlink.topic.deviceLog"),
          topicClass: "sys/{productKey}/{deviceName}/log/sub/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.logSubReport"),
        },
        {
          feature: this.$t("vlink.topic.deviceLog"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/property/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.logSubReportResponse"),
        },
      ],
      modelTable: [
        {
          feature: this.$t("vlink.topic.propertyAppear"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/property/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkPropertyAppear"),
        },
        {
          feature: this.$t("vlink.topic.propertyAppear"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/property/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkPropertyAppearResponse"),
        },
        {
          feature: this.$t("vlink.topic.propertyAppear"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/property/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDevicePropertyAppear"),
        },
        {
          feature: this.$t("vlink.topic.propertyAppear"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/property/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDevicePropertyAppearResponse"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/property/set",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkPropertySet"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/property/set/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkPropertySetResponse"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/property/set",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDevicePropertySet"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/property/set/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDevicePropertySetResponse"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass:
              "sys/{productKey}/{deviceName}/thing/device/expected/get",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.deviceExpectedGet"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass:
              "sys/{productKey}/{deviceName}/thing/device/expected/get/response",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.deviceExpectedGetResponse"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass:
              "sys/{productKey}/{deviceName}/thing/device/expected/delete",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.deviceExpectedDelete"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/event/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkEventAppear"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/event/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkEventAppearResponse"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/event/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDeviceEventAppear"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/event/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDeviceEventAppearResponse"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/service/call",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkServiceCall"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/service/call/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkServiceCallResponse"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/service/call",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDeviceServiceCall"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/service/call/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDeviceServiceCallResponse"),
        },
        {
          feature: this.$t("vlink.topic.gatewayRule"),
          topicClass:
              "sys/{productKey}/{deviceName}/rule/alarm/report",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.ruleAlarmReport"),
        },
        {
          feature: this.$t("vlink.topic.gatewayRule"),
          topicClass:
              "sys/{productKey}/{deviceName}/rule/alarm/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.ruleAlarmReportResponse"),
        },
        {
          feature: this.$t("vlink.topic.offline"),
          topicClass:
              "sys/{productKey}/{deviceName}/thing/sub/offline/property/report",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.offlinePropertyReport"),
        },
        {
          feature: this.$t("vlink.topic.offline"),
          topicClass:
              "sys/{productKey}/{deviceName}/thing/sub/offline/event/report",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.offlineEventReport"),
        },
        {
          feature: this.$t("vlink.topic.offline"),
          topicClass:
              "sys/{productKey}/{deviceName}/thing/sub/offline/service/report",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.offlineServiceReport"),
        },
        {
          feature: this.$t("vlink.topic.offline"),
          topicClass:
              "sys/{productKey}/{deviceName}/thing/sub/offline/status/report",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.offlineStatusReport"),
        },
      ],
      modelTableOrig: [
        {
          feature: this.$t("vlink.topic.propertyAppear"),
          topicClass:
            "sysorig/{productKey}/{deviceName}/thing/device/property/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OriginDirectLinkPropertyAppear"),
        },
        {
          feature: this.$t("vlink.topic.propertyAppear"),
          topicClass:
            "sysorig/{productKey}/{deviceName}/{subProductKey}/{subDeviceName}/thing/sub/property/report",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.OriginSubDevicePropertyAppear"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass:
            "sysorig/{productKey}/{deviceName}/thing/device/property/set",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.OriginDirectLinkPropertySet"),
        },
        {
          feature: this.$t("vlink.topic.propertySet"),
          topicClass:
            "sysorig/{productKey}/{deviceName}/{subProductKey}/{subDeviceName}/thing/sub/property/set",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.OriginSubDevicePropertySet"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/event/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkEventAppear"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/event/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkEventAppearResponse"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/event/report",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDeviceEventAppear"),
        },
        {
          feature: this.$t("vlink.topic.eventAppear"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/event/report/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDeviceEventAppearResponse"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass: "sys/{productKey}/{deviceName}/thing/device/service/call",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.directLinkServiceCall"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/device/service/call/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.directLinkServiceCallResponse"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass: "sys/{productKey}/{deviceName}/thing/sub/service/call",
          security: this.$t("vlink.topic.publish"),
          desc: this.$t("vlink.topic.subDeviceServiceCall"),
        },
        {
          feature: this.$t("vlink.topic.serviceCall"),
          topicClass:
            "sys/{productKey}/{deviceName}/thing/sub/service/call/response",
          security: this.$t("vlink.topic.subscribe"),
          desc: this.$t("vlink.topic.subDeviceServiceCallResponse"),
        },
      ],
      customTable: [],
      customTopic: {
        title: this.$t("vlink.product.createCustomTopic"),
        list: null,
        form: {
          topicId: null,
          topicShortName: null,
          topicClassName: null,
          productKey: this.currentRow.productKey,
          deviceName: null,
          operationAuthority: null,
          description: null,
        },
        options: [
          {
            value: "1",
            label: "发布",
          },
          {
            value: "2",
            label: "订阅",
          },
          {
            value: "3",
            label: "发布和订阅",
          },
        ],
        createCustomTopicDialogVisible: false,
        formRules: {
          operationAuthority: [
            {
              required: true,
              message: this.$t("validate.required"),
            },
          ],
        },
      },
      openDelay: 200,
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.findCustomTopicList();
  },
  mounted() {
    if (this.currentRow.nodeType == "SUBDEVICE") {
      // 如果是子设备,隐藏基础通信Topic和物模型通信Topic
      this.activeName = "customTopic";
    } /*else if (this.currentRow.nodeType == 'GATEWAY' || this.currentRow.nodeType == 'DIRECTDEVICE'){
    //     this.activeName = 'basic';
    // }*/
  },
  computed: {
    productId() {
      return this.currentRow.productId;
    },
  },
  watch: {
    productId: {
      immediate: true,
      handler: function () {
        this.changeTopicClass();
      },
    },
  },
  methods: {
    prependRender() {
      console.log(
        "this.customTopic.form.operationAuthority",
        this.customTopic.form.operationAuthority
      );
      switch (this.customTopic.form.operationAuthority) {
        case "1":
          return this.currentRow.nodeType !== "SUBDEVICE"
            ? "user/{productKey}/{deviceName}/publish/device/"
            : "user/{parentProductKey}/{parentDeviceName}/{productKey}/{deviceName}/publish/sub/";
        case "2":
          return this.currentRow.nodeType !== "SUBDEVICE"
            ? "user/{productKey}/{deviceName}/subscribe/device/"
            : "user/{parentProductKey}/{parentDeviceName}/{productKey}/{deviceName}/subscribe/sub/";
        case "3":
          return this.currentRow.nodeType !== "SUBDEVICE"
            ? "user/{productKey}/{deviceName}/all/device/"
            : "user/{parentProductKey}/{parentDeviceName}/{productKey}/{deviceName}/all/sub/";
        default:
          return "user/{productKey}/{deviceName}/publish/device/";
      }
    },
    activeInput() {
      this.$nextTick(() => {
        console.log("this.$refs.prependInputRef", this.$refs.prependInputRef);
        this.$refs.prependInputRef.focus();
      });
    },
    prependInputStateToggle(type) {
      const targetDom = document.querySelector(
        ".prepend-input .el-input-group__prepend"
      );
      targetDom.style.borderColor = type ? "#409EFF" : "#DCDFE6";
    },
    changeTopicClass() {
      this.basicTableView = JSON.parse(JSON.stringify(this.basicTable));
      this.basicTableView.map((row) => {
        row.topicClass = row.topicClass.replaceAll(
          "{productKey}",
          this.currentRow.productKey
        );
      });
      console.log("this.currentRow.dataType", this.currentRow);
      if (this.currentRow.dataType == "JSON") {
        //标准数据
        this.modelTableView = JSON.parse(JSON.stringify(this.modelTable));
      } else if (this.currentRow.dataType == "BINARY") {
        //透传
        this.modelTableView = JSON.parse(JSON.stringify(this.modelTableOrig));
      }

      this.modelTableView.map((row) => {
        row.topicClass = row.topicClass.replaceAll(
          "{productKey}",
          this.currentRow.productKey
        );
      });
    },
    basicSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          // 设备接入
          return {
            rowspan: 12,
            colspan: 1,
          };
        } else if (rowIndex === 12) {
          // 设备接入
          return {
            rowspan: 6,
            colspan: 1,
          };
        } else if (rowIndex === 18) {
          // 设备OTA
          return {
            rowspan: 10,
            colspan: 1,
          };
        } else if (rowIndex === 28) {
          // 设备日志
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    modelSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (this.currentRow.dataType == "JSON") {
          // 非透传展示非透传的相关Topic(标准格式)
          if (rowIndex === 0) {
            // 属性上报
            return {
              rowspan: 4,
              colspan: 1,
            };
          } else if (rowIndex === 4) {
            // 属性设置
            return {
              rowspan: 7,
              colspan: 1,
            };
          } else if (rowIndex === 11) {
            // 事件上报
            return {
              rowspan: 4,
              colspan: 1,
            };
          } else if (rowIndex === 15) {
            // 服务调用
            return {
              rowspan: 4,
              colspan: 1,
            };
          } else if (rowIndex === 19) {
            // 告警
            return {
              rowspan: 2,
              colspan: 1,
            };
          } else if (rowIndex === 21) {
            // 离线
            return {
              rowspan: 4,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        } else if (this.currentRow.dataType == "BINARY") {
          // 透传展示透传的相关Topic
          if (rowIndex === 0) {
            // 属性上报
            return {
              rowspan: 2,
              colspan: 1,
            };
          } else if (rowIndex === 2) {
            // 属性设置
            return {
              rowspan: 2,
              colspan: 1,
            };
          } else if (rowIndex === 4) {
            // 事件上报
            return {
              rowspan: 4,
              colspan: 1,
            };
          } else if (rowIndex === 8) {
            // 服务调用
            return {
              rowspan: 4,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        }
      }
    },
    findCustomTopicList() {
      this.loadingFlag = true;
      findCustomTopicList(this.customTopic.form)
        .then((response) => {
          this.customTopic.list = response;
          this.loadingFlag = false;
        })
        .catch(() => {
          this.loadingFlag = false;
        });
    },
    createCustomTopic() {
      this.customTopic.title = this.$t("vlink.product.createCustomTopic");
      this.editFlag = "add";
      this.customTopic.form.topicId = null;
      this.customTopic.form.productKey = null;
      this.customTopic.form.version = null;
      this.customTopic.form.topicShortName = null;
      this.customTopic.form.topicClassName = null;
      this.customTopic.form.description = null;
      this.customTopic.createCustomTopicDialogVisible = true;
    },
    cancelCustomTopicSave() {
      this.$refs.customTopicForm.resetFields();
      this.customTopic.createCustomTopicDialogVisible = false;
    },
    handleCustomTopicSave() {
      // pk
      this.customTopic.form.productKey = this.currentRow.productKey;
      var topicShortName = this.customTopic.form.topicShortName;
      // 操作权限
      var operationAuthority = this.customTopic.form.operationAuthority;

      // 子设备
      if (this.currentRow.nodeType == "SUBDEVICE") {
        if (operationAuthority == "1") {
          // 发布
          this.customTopic.form.topicClassName =
            "user/{parentProductKey}/{parentDeviceName}/{productKey}/{deviceName}/publish/sub/" +
            topicShortName;
        }
        if (operationAuthority == "2") {
          // 订阅
          this.customTopic.form.topicClassName =
            "user/{parentProductKey}/{parentDeviceName}/{productKey}/{deviceName}/subscribe/sub/" +
            topicShortName;
        }
        if (operationAuthority == "3") {
          // 发布和订阅
          this.customTopic.form.topicClassName =
            "user/{parentProductKey}/{parentDeviceName}/{productKey}/{deviceName}/all/sub/" +
            topicShortName;
        }
      } else {
        // 直连设备或网关
        if (operationAuthority == "1") {
          // 发布
          this.customTopic.form.topicClassName =
            "user/{productKey}/{deviceName}/publish/device/" + topicShortName;
        }
        if (operationAuthority == "2") {
          // 订阅
          this.customTopic.form.topicClassName =
            "user/{productKey}/{deviceName}/subscribe/device/" + topicShortName;
        }
        if (operationAuthority == "3") {
          // 发布和订阅
          this.customTopic.form.topicClassName =
            "user/{productKey}/{deviceName}/all/device/" + topicShortName;
        }
      }

      this.$refs.customTopicForm.validate((v) => {
        if (v) {
          // 编辑
          if (this.customTopic.form.topicId) {
            updateCustomTopic(this.customTopic.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                // 清空表单
                this.$refs.customTopicForm.resetFields();

                this.cancelCustomTopicSave();
                this.findCustomTopicList();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            // 新增
            createCustomTopic(this.customTopic.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                // 清空表单
                this.$refs.customTopicForm.resetFields();

                this.cancelCustomTopicSave();
                this.findCustomTopicList();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    deleteCustomTopic(topicId) {
      this.$confirm(
        "删除topic可能造成已绑定的规则失效，确定删除吗？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteCustomTopic({ topicId: topicId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findCustomTopicList();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    operationAuthorityTrans(operationAuthority) {
      const emuObject = {
        发布: "1",
        订阅: "2",
        发布和订阅: "3",
      };
      return emuObject[operationAuthority] || "1";
    },
    editCustomTopic(row) {
      this.customTopic.title = "编辑Topic类";
      this.editFlag = "edit";
      this.customTopic.form.topicId = row.topicId;
      this.customTopic.form.topicShortName = row.topicShortName;
      this.customTopic.form.topicClassName = row.topicClassName;
      this.customTopic.form.operationAuthority = this.operationAuthorityTrans(
        row.operationAuthority
      );
      this.customTopic.form.description = row.description;
      // this.customTopic.form.creationDate = row.creationDate;
      this.customTopic.form.createdBy = row.createdBy;
      this.customTopic.createCustomTopicDialogVisible = true;
    },
  },
};
</script>
<style scoped lang="less">
.operation-icon {
  cursor: pointer;
  font-size: 20px;
}
.el-icon-edit {
  color: #5a9cf8;
}
.el-icon-delete {
  margin-left: 20px;
  color: #e47470;
}
.prepend-input {
  /deep/ .el-input__inner {
    border-left: none;
    transition: none;
    font-size: 14px;
    color: #333;
  }
  /deep/ .el-input-group__prepend {
    background: #fff;
    border-right: none;
    padding-right: 0;
    font-size: 14px;
    color: #333;
  }
}
</style>