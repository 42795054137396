<template>
  <div>
    <el-form
      label-position="top"
      ref="modbusForm"
      :rules="modbusRule"
      :model="modbusForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.tsl.originalDataType')"
            prop="originalDataType"
          >
            <el-select
              v-model="modbusForm.originalDataType"
              clearable
              :style="{ width: '100%' }"
            >
              <el-option
                :label="$t('vlink.originalDataType.int8')"
                value="int8"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.uint8')"
                value="uint8"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.int16')"
                value="int16"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.uint16')"
                value="uint16"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.int32')"
                value="int32"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.uint32')"
                value="uint32"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.int64')"
                value="int64"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.uint64')"
                value="uint64"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.float')"
                value="float"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.double')"
                value="double"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.string')"
                value="string"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.bool')"
                value="bool"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.customized')"
                value="customized"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <template
          v-if="
            modbusForm.originalDataType == 'string' ||
            modbusForm.originalDataType == 'customized'
          "
        >
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.tsl.registerCount')"
              prop="registerCount"
            >
              <el-input
                :readonly="!editable"
                v-model="modbusForm.registerCount"
                maxlength="20"
                :show-word-limit="true"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template
          v-if="
            modbusForm.originalDataType == 'int32' ||
            modbusForm.originalDataType == 'uint32' ||
            modbusForm.originalDataType == 'int64' ||
            modbusForm.originalDataType == 'uint64' ||
            modbusForm.originalDataType == 'float' ||
            modbusForm.originalDataType == 'double'
          "
        >
          <el-col :span="12">
            <el-form-item :label="$t('vlink.tsl.swap')" prop="swap">
              <el-select
                v-model="modbusForm.swap"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  :label="$t('vlink.tsl.bigEndian')"
                  value="BIG_ENDIAN"
                ></el-option>
                <el-option
                  :label="$t('vlink.tsl.littleEndian')"
                  value="LITTLE_ENDIAN"
                ></el-option>
                <el-option
                  :label="$t('vlink.tsl.bigEndianSwap')"
                  value="BIG_ENDIAN_BYTE_SWAP"
                ></el-option>
                <el-option
                  :label="$t('vlink.tsl.littleEndianSwap')"
                  value="LITTLE_ENDIAN_BYTE_SWAP"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.operateType')" prop="operateType">
            <el-select
              v-model="modbusForm.operateType"
              :style="{ width: '100%' }"
            >
              <el-option
                :label="$t('vlink.tsl.coilStatus')"
                value="coilStatus"
              ></el-option>
              <el-option
                :label="$t('vlink.tsl.inputStatus')"
                value="inputStatus"
              ></el-option>
              <el-option
                :label="$t('vlink.tsl.holdingRegister')"
                value="holdingRegister"
              ></el-option>
              <el-option
                :label="$t('vlink.tsl.inputRegister')"
                value="inputRegister"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.tsl.registerAddress')"
            prop="registerAddress"
          >
            <el-input
              :readonly="!editable"
              v-model="modbusForm.registerAddress"
              maxlength="20"
              :show-word-limit="true"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <template v-if="modbusForm.originalDataType == 'bool'">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.tsl.bitMask')" prop="bitMask">
              <el-select
                v-model="modbusForm.bitMask"
                :style="{ width: '100%' }"
              >
                <el-option label="1(1<<0)" :value="1"></el-option>
                <el-option label="2(1<<1)" :value="2"></el-option>
                <el-option label="4(1<<2)" :value="4"></el-option>
                <el-option label="8(1<<3)" :value="8"></el-option>
                <el-option label="16(1<<4)" :value="16"></el-option>
                <el-option label="32(1<<5)" :value="32"></el-option>
                <el-option label="64(1<<6)" :value="64"></el-option>
                <el-option label="128(1<<7)" :value="128"></el-option>
                <el-option label="256(1<<8)" :value="256"></el-option>
                <el-option label="512(1<<9)" :value="512"></el-option>
                <el-option label="1024(1<<10)" :value="1024"></el-option>
                <el-option label="2048(1<<11)" :value="2048"></el-option>
                <el-option label="4096(1<<12)" :value="4096"></el-option>
                <el-option label="8192(1<<13)" :value="8192"></el-option>
                <el-option label="16384(1<<14)" :value="16384"></el-option>
                <el-option label="32768(1<<15)" :value="32768"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
        <!-- <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.scaling')" prop="scaling">
            <el-input
              :readonly="!editable"
              v-model="modbusForm.scaling"
              maxlength="20"
              :show-word-limit="true"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.precision')" prop="precision">
            <el-input
              :readonly="!editable"
              v-model="modbusForm.precision"
              maxlength="20"
              :show-word-limit="true"
              clearable
            ></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>

      <!-- 将上报方式移到子设备配置中 -->
      <!-- <el-row>
                <el-col>
                    <el-form-item :label="$t('vlink.tsl.trigger')" prop="trigger">
                        <el-radio-group :disabled="!editable" v-model="modbusForm.trigger">
                            <el-radio :label="1">{{$t("vlink.tsl.timingAppear")}}</el-radio>
                            <el-radio :label="2">{{$t("vlink.tsl.changeAppear")}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row> -->

      <!-- <el-row v-if="editable">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveProperty">{{
            $t("commons.save")
          }}</el-button>
          <el-button @click="cancelEditProperty(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    property: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    property: {
      immediate: true,
      handler: function () {
        this.initProperty();
      },
    },
  },
  data() {
    return {
      modbusForm: {
        originalDataType: null,
        registerCount: null,
        swap: null,
        operateType: null,
        registerAddress: null,
        bitMask: null,
        scaling: null,
        precision: null,
        trigger: "2",
        reportCycle: 5000,
      },
      modbusRule: {
        originalDataType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        operateType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        registerAddress: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        trigger: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        reportCycle: [
          {
            required: false,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (value < 1000) {
                callback(new Error("上报周期最小1000毫秒"));
                return;
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    initProperty() {
      if (this.property) {
        if (this.property.extendConfig) {
          let customize = JSON.parse(this.property.extendConfig);
          this.modbusForm.originalDataType = customize.originalDataType;
          this.modbusForm.registerCount = customize.registerCount;
          this.modbusForm.swap = customize.swap;
          this.modbusForm.operateType = customize.operateType;
          this.modbusForm.registerAddress = customize.registerAddress;
          this.modbusForm.bitMask = customize.bitMask;
          this.modbusForm.scaling = customize.scaling;
          this.modbusForm.precision = customize.precision;
          this.modbusForm.trigger = customize.trigger ? customize.trigger : "2";
          this.modbusForm.reportCycle = customize.reportCycle;
          // if(customize.trigger){
          //     this.modbusForm.trigger = customize.trigger;
          // }else{
          //     this.modbusForm.trigger = 1;
          // }
        }
      }
    },
    saveProperty() {
      this.$refs.modbusForm.validate((v) => {
        if (v) {
          let param = this.buildParam();
          // 回写数据
          this.$emit("cancelEditProperty", param);
        }
      });
    },
    buildParam() {
      let extendConfig = JSON.stringify({
        originalDataType: this.modbusForm.originalDataType,
        registerCount: this.modbusForm.registerCount,
        swap: this.modbusForm.swap,
        operateType: this.modbusForm.operateType,
        registerAddress: this.modbusForm.registerAddress,
        bitMask: this.modbusForm.bitMask,
        scaling: this.modbusForm.scaling,
        precision: this.modbusForm.precision,
        trigger: this.modbusForm.trigger,
        reportCycle: this.modbusForm.reportCycle,
      });
      return extendConfig;
    },
    cancelEditProperty() {
      this.saveProperty();
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 18px;
}
</style>