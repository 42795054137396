<template>
  <div class="ruge-drawer-container">
    <el-form label-position="top" label-width="80px">
      <!-- <el-row>
        <el-col :span="6">
          <el-button type="primary" @click="saveTsl">{{
            $t("commons.save")
          }}</el-button>
          <el-button type="success" @click="formatJson">{{
            $t("vlink.commons.formatJson")
          }}</el-button>
        </el-col>
      </el-row> -->
      <el-form-item :label="$t('vlink.tsl.tslJson')">
        <ace-editor
          :key="tsl.productKey"
          v-model="tslJson"
          @init="editorInit"
          lang="json"
          theme="chrome"
          style="border: 1px solid #ebecec"
          height="562px"
        ></ace-editor>
      </el-form-item>
      <el-row style="text-align: right">
        <el-button type="primary" @click="closeInfoDialog">{{
          $t("vlink.commons.close")
        }}</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";
import { updateTsl } from "@/api/ruge/vlink/product/product";

export default {
  components: { AceEditor },
  name: "TslInfo",
  props: {
    tsl: {
      required: true,
      type: Object,
    },
  },
  watch: {
    tsl: {
      immediate: true,
      handler: function () {
        if (this.tsl) {
          this.initThing();
        }
      },
    },
  },
  data() {
    return {
      tslJson: null,
      startValue: null,
      moduleName: "base",
    };
  },
  methods: {
    closeInfoDialog() {
      this.$emit("close");
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      // thingEditor.setReadOnly(true);
    },
    initThing() {
      // 格式化json
      this.tslJson = JSON.stringify(this.tsl, null, "\t");
    },
    formatJson() {
      if (
        this.tslJson &&
        this.tslJson.trim() !== "" &&
        this.checkJsonFormat()
      ) {
        this.tslJson = JSON.stringify(
          JSON.parse(this.tslJson.trim()),
          null,
          "\t"
        );
      }
    },
    checkJsonFormat() {
      try {
        if (typeof JSON.parse(this.tslJson) == "object") {
          return true;
        }
      } catch (e) {
        console.log(`tsl格式异常，原因 => ${e}`);
      }
      this.$message({
        message: this.$t("vlink.tsl.tips7"),
        type: "warning",
      });
      return false;
    },
    saveTsl() {
      let tslObj = JSON.parse(this.tslJson);
      updateTsl(tslObj).then(() => {
        // 成功提示
        this.$message({
          message: this.$t("message.saveSuccess"),
          type: "success",
        });
      });
    },
    //生成脚本
    generateScript() {
      if (!this.startValue) {
        return;
      }
      let tslObj = JSON.parse(this.tslJson);
      // 获取当前模块
      let currModule = tslObj.modules.find((item) => {
        return item.moduleName == this.moduleName;
      });

      let array = [];
      currModule.properties.forEach((obj) => {
        let extendConfig = JSON.parse(obj.extendConfig);
        if (extendConfig.registerAddress) {
          array.push(extendConfig.registerAddress);
        }
        if (extendConfig.objectId) {
          array.push(extendConfig.objectId);
        }
      });

      let growthFactor = this.calcGrowthFactor(array);

      for (let j = 0; j < currModule.properties.length; j++) {
        let extendConfig = JSON.parse(currModule.properties[j].extendConfig);
        if (j == 0) {
          if (extendConfig.registerAddress) {
            extendConfig.registerAddress = this.startValue;
          }
          if (extendConfig.objectId) {
            extendConfig.objectId = this.startValue;
          }
        } else {
          let configObj = JSON.parse(currModule.properties[j - 1].extendConfig);
          if (configObj.registerAddress) {
            let lastValue = configObj.registerAddress;
            extendConfig.registerAddress = lastValue - growthFactor[j - 1];
          }
          if (configObj.objectId) {
            let lastValue = configObj.objectId;
            extendConfig.objectId = lastValue - growthFactor[j - 1];
          }
        }
        currModule.properties[j].extendConfig = JSON.stringify(extendConfig);
      }

      tslObj.modules.find((item) => {
        if (item.moduleName == this.moduleName) {
          item = currModule;
          return;
        }
      });
      this.tslJson = JSON.stringify(tslObj);
      this.formatJson();
    },
    // 计算增长因子
    calcGrowthFactor(array) {
      let growthFactor = [];
      for (let i = 0; i < array.length; i++) {
        if (i != array.length - 1) {
          growthFactor.push(array[i] - array[i + 1]);
        }
      }
      return growthFactor;
    },
  },
};
</script>

<style scoped>
</style>
