var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ota-component" },
    [
      _c("div", { staticClass: "buttons-container" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-plus", type: "primary" },
                on: { click: _vm.createOta },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("commons.add")) + "\n      "
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  disabled: _vm.ota.multipleSelection.length > 0,
                  content: _vm.$t("commons.multipleSelectionOne"),
                  placement: "right",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    class:
                      _vm.ota.multipleSelection.length === 0 &&
                      "not-allowed-button",
                    attrs: {
                      type: "danger",
                      icon: "el-icon-delete",
                      plain: "",
                    },
                    on: { click: _vm.batchDelete },
                  },
                  [_vm._v(_vm._s(_vm.$t("commons.delete")) + "\n        ")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.freshHandler },
              },
              [_vm._v("刷新")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            class: _vm.filterLineShow
              ? "filter-button-open"
              : "filter-button-close",
            on: {
              click: function ($event) {
                _vm.filterLineShow = !_vm.filterLineShow
              },
            },
          },
          [
            _vm._v("\n      筛选\n      "),
            _vm.filterLineShow
              ? _c("i", { staticClass: "el-icon-arrow-down" })
              : _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.ota.addDialogVisible,
            "before-close": _vm.cancelOtaSave,
            width: "35%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.ota, "addDialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.ota.title) + "\n    "),
          ]),
          _c(
            "el-form",
            {
              ref: "otaForm",
              attrs: { "label-position": "top", model: _vm.ota.form },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("vlink.product.firmwareVersion"),
                    prop: "firmwareVersion",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "32",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.ota.form.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.ota.form, "version", $$v)
                      },
                      expression: "ota.form.version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("vlink.product.programmingLanguage"),
                    prop: "programmingLanguage",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "32",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.ota.form.programmingLanguage,
                      callback: function ($$v) {
                        _vm.$set(_vm.ota.form, "programmingLanguage", $$v)
                      },
                      expression: "ota.form.programmingLanguage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("vlink.product.OTADescription"),
                    prop: "description",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "32",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.ota.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.ota.form, "description", $$v)
                      },
                      expression: "ota.form.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    drag: "",
                    "with-credentials": true,
                    action: _vm.uploadUrl,
                    "on-success": _vm.uploadSuccess,
                    "on-exceed": _vm.onExceed,
                    limit: 1,
                    multiple: "",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")]),
                  ]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.download },
                },
                [
                  _vm._v(_vm._s(_vm.ota.form.fileName)),
                  _c("i", { staticClass: "el-icon-download el-icon--right" }),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelOtaSave } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleOtaSave },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }