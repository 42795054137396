<template>
  <div>
    <el-form
      class="scroll-view"
      v-loading="loading"
      label-position="top"
      :model="event.form"
      ref="eventForm"
      :rules="event.formRules"
    >
      <el-row :gutter="48">
        <!-- 事件名称 -->
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.eventName')" prop="eventName">
            <el-input
              v-model="event.form.eventName"
              :readonly="currentEvent.isEdit"
              maxlength="32"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 事件类型 -->
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.eventType')" prop="eventType">
            <el-select
              v-model="event.form.eventType"
              :style="{ width: '100%' }"
            >
              <el-option
                :label="$t('vlink.commons.info')"
                value="info"
              ></el-option>
              <el-option
                :label="$t('vlink.commons.alarm')"
                value="alarm"
              ></el-option>
              <el-option
                :label="$t('vlink.commons.fault')"
                value="fault"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 描述 -->
      <el-row :gutter="48">
        <el-col>
          <el-form-item :label="$t('vlink.tsl.description')" prop="description">
            <el-input
              v-model="event.form.description"
              maxlength="100"
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 上报参数  -->
      <el-form-item :label="$t('vlink.tsl.outputParam')" prop="inputData">
        <EditParams
          :paramType="'inputData'"
          ref="editParamsRef"
          :dataset="event.form.outputData"
        />
      </el-form-item>

      <!-- 扩展描述 -->
      <el-form-item :label="$t('vlink.tsl.extendConfig')" prop="extendConfig">
        <div class="extend-config-line">
          <el-switch
            v-model="event.form.needConfig"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
          <span
            :class="
              event.form.needConfig
                ? 'config-title-active'
                : 'config-title-default'
            "
          >
            {{ event.form.needConfig ? "已开启" : "未开启" }}
          </span>
          <span class="config-tips">
            <i class="el-icon-info"></i>
            关闭时，将不保存扩展配置。
          </span>
        </div>
        <ace-editor
          v-if="event.form.needConfig"
          v-model="event.form.extendConfig"
          @init="editorInit"
          lang="json"
          theme="chrome"
          style="border: 1px solid #ebecec"
          height="300px"
        ></ace-editor>
      </el-form-item>
    </el-form>
    <div slot="footer" align="right" style="margin-bottom: 10px">
      <el-button @click="closeSaveEvent(false)">{{
        $t("commons.cancel")
      }}</el-button>
      <el-button :loading="loading" type="primary" @click="handleSaveEvent">{{
        currentEvent.isEdit ? $t("commons.save") : $t("commons.add")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import EditParams from "./editParams";

import { updateTsl } from "@/api/ruge/vlink/product/product";
import AceEditor from "vue2-ace-editor";

export default {
  components: {
    EditParams,
    AceEditor,
  },
  data() {
    const inputDataValid = (rule, value, callback) => {
      const currentOutputList = this.$refs.editParamsRef.getParamList();
      if (currentOutputList.length) {
        callback();
      } else {
        callback(new Error("请添加上报参数"));
      }
      // if (!value) callback();
      // const Reg = /^[0-9\-]+$/;
      // if (!Reg.test(value) || value.length > 20) {
      //   callback(new Error("联系电话仅支持20位以内的阿拉伯数字，横杠"));
      // } else {
      //   callback();
      // }
    };
    return {
      loading: false,
      tslObj: {},
      event: {
        form: {},
        formRules: {
          eventName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              pattern: /^[A-Za-z0-9-]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          description: [
            {
              // pattern: /^[A-Za-z0-9-_@()\u4e00-\u9fa5]+$/,
              message: this.$t("vlink.product.productNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          eventType: [
            {
              required: true,
              message: this.$t("validate.required"),
              trigger: ["blur", "change"],
            },
          ],
          inputData: [
            {
              required: true,
              validator: inputDataValid,
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  props: {
    tsl: {
      type: Object,
      required: true,
    },
    currentEvent: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentEvent: {
      immediate: true,
      handler: function () {
        // JSON.parse(JSON.stringify(obj)) 深拷贝，防止提交失败也会修改父组件数据现象
        this.tslObj = JSON.parse(
          JSON.stringify(
            Object.assign(
              { productKey: this.currentEvent.productKey },
              this.tsl
            )
          )
        );
        this.initEventForm();
      },
    },
  },
  methods: {
    editorInit(thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(false);
    },
    initEventForm() {
      if (this.currentEvent.isEdit) {
        console.log("this.currentEvent.form", this.currentEvent.form);
        // 如果有extendConfig，switch打开
        this.currentEvent.form.needConfig = this.currentEvent.form.extendConfig
          ? true
          : false;
        this.currentEvent.form.extendConfig = this.currentEvent.form
          .extendConfig
          ? JSON.parse(this.currentEvent.form.extendConfig)
          : null;
        this.event.form = Object.assign({}, this.currentEvent.form);
      } else {
        this.event.form = {
          eventName: null,
          description: null,
          required: false,
          eventType: "info",
          outputData: [],
          extendConfig: null,
          needConfig: false,
        };
      }
    },
    closeSaveEvent(moduleName) {
      this.$emit("closeSaveEvent", moduleName);
    },
    handleSaveEvent() {
      this.$refs.eventForm.validate((v) => {
        if (v) {
          if (
            this.tslObj &&
            this.tslObj.modules &&
            this.tslObj.modules.length > 0
          ) {
            console.log("this.tslObj", this.tslObj);
            console.log("this.currentEvent", this.currentEvent);
            console.log("this.tsl", this.tsl);
            // 获取当前模块
            let currModule = this.tslObj.modules.find((item) => {
              return item.moduleName == this.currentEvent.moduleName;
            });
            // 找不到对应的模块
            if (!currModule) {
              this.$message({
                message: this.$t("vlink.tsl.tips3"),
                type: "warning",
              });
              return;
            }
            const currentOutputList = this.$refs.editParamsRef.getParamList();
            this.event.form.outputData = currentOutputList;
            this.event.form.extendConfig = this.event.form.needConfig
              ? JSON.stringify(this.event.form.extendConfig)
              : null;
            // 编辑
            if (this.currentEvent.isEdit) {
              currModule.events = currModule.events.map((item) => {
                return item.eventName == this.event.form.eventName
                  ? Object.assign({}, this.event.form)
                  : item;
              });
            } else {
              // 添加时，eventName唯一性校验
              if (currModule.events && currModule.events.length > 0) {
                let obj = currModule.events.find((item) => {
                  return item.eventName == this.event.form.eventName;
                });
                if (obj) {
                  this.$message({
                    message: this.$t("vlink.tsl.tips4"),
                    type: "warning",
                  });
                  return;
                }
              }
              if (!currModule.events) {
                currModule.events = [];
              }

              // 将事件添加到模块中
              currModule.events.push(Object.assign({}, this.event.form));
            }

            this.loading = true;
            this.tslObj.modules = this.tslObj.modules.map((item) => {
              return item.moduleName == currModule.moduleName
                ? currModule
                : item;
            });

            // 保存tsl
            updateTsl(this.tslObj)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.closeSaveEvent(currModule.moduleName);
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="less">
.descript-input .el-input__count {
  top: 1px;
  height: 30px;
}
.extend-config-line {
  display: flex;
  align-items: center;
  .config-title-active {
    color: #13ce66;
    margin-left: 10px;
  }
  .config-title-default {
    color: #ccc;
    margin-left: 10px;
  }
  .config-tips {
    margin-left: 15px;
    font-size: 12px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ccc;
  }
}
</style>