var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "modbusForm",
          attrs: {
            "label-position": "top",
            rules: _vm.modbusRule,
            model: _vm.modbusForm,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.originalDataType"),
                        prop: "originalDataType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.modbusForm.originalDataType,
                            callback: function ($$v) {
                              _vm.$set(_vm.modbusForm, "originalDataType", $$v)
                            },
                            expression: "modbusForm.originalDataType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.int8"),
                              value: "int8",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.uint8"),
                              value: "uint8",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.int16"),
                              value: "int16",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.uint16"),
                              value: "uint16",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.int32"),
                              value: "int32",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.uint32"),
                              value: "uint32",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.int64"),
                              value: "int64",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.uint64"),
                              value: "uint64",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.float"),
                              value: "float",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.double"),
                              value: "double",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.string"),
                              value: "string",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.originalDataType.bool"),
                              value: "bool",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t(
                                "vlink.originalDataType.customized"
                              ),
                              value: "customized",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.modbusForm.originalDataType == "string" ||
              _vm.modbusForm.originalDataType == "customized"
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.registerCount"),
                              prop: "registerCount",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                readonly: !_vm.editable,
                                maxlength: "20",
                                "show-word-limit": true,
                                clearable: "",
                              },
                              model: {
                                value: _vm.modbusForm.registerCount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modbusForm, "registerCount", $$v)
                                },
                                expression: "modbusForm.registerCount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.modbusForm.originalDataType == "int32" ||
              _vm.modbusForm.originalDataType == "uint32" ||
              _vm.modbusForm.originalDataType == "int64" ||
              _vm.modbusForm.originalDataType == "uint64" ||
              _vm.modbusForm.originalDataType == "float" ||
              _vm.modbusForm.originalDataType == "double"
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.swap"),
                              prop: "swap",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                style: { width: "100%" },
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.modbusForm.swap,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modbusForm, "swap", $$v)
                                  },
                                  expression: "modbusForm.swap",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("vlink.tsl.bigEndian"),
                                    value: "BIG_ENDIAN",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("vlink.tsl.littleEndian"),
                                    value: "LITTLE_ENDIAN",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("vlink.tsl.bigEndianSwap"),
                                    value: "BIG_ENDIAN_BYTE_SWAP",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("vlink.tsl.littleEndianSwap"),
                                    value: "LITTLE_ENDIAN_BYTE_SWAP",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.operateType"),
                        prop: "operateType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.modbusForm.operateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.modbusForm, "operateType", $$v)
                            },
                            expression: "modbusForm.operateType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.coilStatus"),
                              value: "coilStatus",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.inputStatus"),
                              value: "inputStatus",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.holdingRegister"),
                              value: "holdingRegister",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.inputRegister"),
                              value: "inputRegister",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.registerAddress"),
                        prop: "registerAddress",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "20",
                          "show-word-limit": true,
                          clearable: "",
                        },
                        model: {
                          value: _vm.modbusForm.registerAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.modbusForm, "registerAddress", $$v)
                          },
                          expression: "modbusForm.registerAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.modbusForm.originalDataType == "bool"
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.bitMask"),
                              prop: "bitMask",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                style: { width: "100%" },
                                model: {
                                  value: _vm.modbusForm.bitMask,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modbusForm, "bitMask", $$v)
                                  },
                                  expression: "modbusForm.bitMask",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "1(1<<0)", value: 1 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "2(1<<1)", value: 2 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "4(1<<2)", value: 4 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "8(1<<3)", value: 8 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "16(1<<4)", value: 16 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "32(1<<5)", value: 32 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "64(1<<6)", value: 64 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "128(1<<7)", value: 128 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "256(1<<8)", value: 256 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "512(1<<9)", value: 512 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "1024(1<<10)", value: 1024 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "2048(1<<11)", value: 2048 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "4096(1<<12)", value: 4096 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "8192(1<<13)", value: 8192 },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "16384(1<<14)",
                                    value: 16384,
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "32768(1<<15)",
                                    value: 32768,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }