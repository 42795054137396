<template>
  <div class="single-param-form">
    <!-- 新增 & 编辑 -->
    <div v-show="currentState !== 'view'">
      <el-form
        label-position="top"
        :model="param.form"
        ref="paramForm"
        :rules="param.formRules"
      >
        <el-row :gutter="48">
          <!-- 参数名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('vlink.tsl.paramName')" prop="paramName">
              <el-input
                :readonly="currentState === 'edit'"
                v-model="param.form.paramName"
                maxlength="32"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 数据类型 -->
          <el-col :span="12">
            <el-form-item :label="$t('vlink.tsl.dataType')" prop="dataType">
              <el-select
                v-model="param.form.dataType"
                :style="{ width: '100%' }"
              >
                <el-option
                  :label="$t('vlink.dataType.int')"
                  value="int"
                ></el-option>
                <el-option
                  :label="$t('vlink.dataType.decimal')"
                  value="decimal"
                ></el-option>
                <el-option
                  :label="$t('vlink.dataType.string')"
                  value="string"
                ></el-option>
                <el-option
                  :label="$t('vlink.dataType.dateTime')"
                  value="dateTime"
                ></el-option>
                <el-option
                  :label="$t('vlink.dataType.enum')"
                  value="enum"
                ></el-option>
                <el-option
                  :label="$t('vlink.dataType.jsonObject')"
                  value="jsonObject"
                ></el-option>
                <el-option
                  :label="$t('vlink.dataType.stringList')"
                  value="stringList"
                ></el-option>
                <el-option
                  :label="$t('vlink.dataType.img')"
                  value="img"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 取值范围 -->
          <el-col
            :span="12"
            v-if="
              param.form.dataType == 'int' || param.form.dataType == 'decimal'
            "
          >
            <el-form-item :label="$t('vlink.tsl.valueRange')" required>
              <div style="display: flex">
                <el-form-item prop="min">
                  <el-input-number
                    :style="{ width: '100%' }"
                    v-model="param.form.min"
                    controls-position="right"
                    :min="-2147483647"
                    :max="param.form.max - 1"
                  ></el-input-number>
                </el-form-item>
                <span class="split-span">-</span>
                <el-form-item prop="max">
                  <el-input-number
                    :style="{ width: '100%' }"
                    v-model="param.form.max"
                    controls-position="right"
                    :min="param.form.min + 1"
                    :max="2147483647"
                  ></el-input-number>
                </el-form-item>
              </div>
            </el-form-item>
          </el-col>
          <!-- 步长 -->
          <el-col
            :span="12"
            v-if="
              param.form.dataType == 'int' || param.form.dataType == 'decimal'
            "
          >
            <el-form-item :label="$t('vlink.tsl.step')" prop="step">
              <el-input-number
                class="tsl-input-number"
                v-model="param.form.step"
                controls-position="right"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <!-- 单位 -->
          <el-col
            :span="12"
            v-if="
              param.form.dataType == 'int' || param.form.dataType == 'decimal'
            "
          >
            <el-form-item :label="$t('vlink.tsl.unit')" prop="unit">
              <el-input
                v-model="param.form.unit"
                maxlength="16"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 最大长度 -->
          <el-col
            :span="12"
            v-if="
              param.form.dataType !== 'int' && param.form.dataType !== 'decimal'
            "
          >
            <el-form-item :label="$t('vlink.tsl.maxLength')" prop="maxLength">
              <el-input-number
                class="tsl-input-number"
                style="width: 100%"
                v-model="param.form.maxLength"
                controls-position="right"
                :min="0"
                :max="2147483647"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <!-- 参数描述 -->
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.tsl.paramDescription')"
              prop="description"
            >
              <el-input
                class="descript-input"
                v-model="param.form.description"
                maxlength="100"
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 枚举列表 -->
        <el-row v-if="param.form.dataType == 'enum'">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.tsl.enumList')">
              <el-form-item
                v-for="(enumItem, index) in param.form.enumList"
                :key="index"
                style="margin-top: 10px"
              >
                <div class="enum-list-line">
                  <span class="index-span">
                    {{ index + " - " }}
                  </span>
                  <el-input
                    v-model="param.form.enumList[index]"
                    maxlength="16"
                    clearable
                    :placeholder="$t('commons.pleaseInput')"
                  ></el-input>
                  <el-button
                    type="text"
                    @click="removeEnumItem(index)"
                    style="margin-left: 10px"
                  >
                    <i class="el-icon-delete delete-btn"></i>
                  </el-button>
                </div>
              </el-form-item>
              <div class="add-line">
                <span @click="addEnumItem">
                  <i class="el-icon-plus"></i>
                  {{ $t("vlink.tsl.addEnumItem") }}
                </span>
              </div>
              <!-- <el-button
                type="success"
                icon="el-icon-plus"
                size="mini"
                @click="addEnumItem"
                >{{ $t("vlink.tsl.addEnumItem") }}</el-button
              > -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="buttons-container">
        <el-button type="primary" plain @click="saveParamHandler"
          >保存参数</el-button
        >
        <el-button plain @click="cancelHandler">取消</el-button>
      </div>
    </div>

    <!-- 预览模式 -->
    <div v-show="currentState === 'view'">
      <el-row :gutter="48">
        <!-- 参数名称 -->
        <el-col :span="12">
          <div class="view-detail-container">
            <div class="view-detail-label">{{ $t("vlink.tsl.paramName") }}</div>
            <div class="view-detail-value">
              {{ param.form.paramName }}
            </div>
          </div>
        </el-col>
        <!-- 数据类型 -->
        <el-col :span="12">
          <div class="view-detail-container">
            <div class="view-detail-label">{{ $t("vlink.tsl.dataType") }}</div>
            <div class="view-detail-value">
              {{ renderDataType(param.form.dataType) }}
            </div>
          </div>
        </el-col>
        <!-- 取值范围 -->
        <el-col
          :span="12"
          v-if="
            param.form.dataType == 'int' || param.form.dataType == 'decimal'
          "
        >
          <div class="view-detail-container">
            <div class="view-detail-label">
              {{ $t("vlink.tsl.valueRange") }}
            </div>
            <div class="view-detail-value">
              {{ renderValueRange(param.form) }}
            </div>
          </div>
        </el-col>
        <!-- 步长 -->
        <el-col
          :span="12"
          v-if="
            param.form.dataType == 'int' || param.form.dataType == 'decimal'
          "
        >
          <div class="view-detail-container">
            <div class="view-detail-label">{{ $t("vlink.tsl.step") }}</div>
            <div class="view-detail-value">
              {{ param.form.step || "-" }}
            </div>
          </div>
        </el-col>
        <!-- 单位 -->
        <el-col
          :span="12"
          v-if="
            param.form.dataType == 'int' || param.form.dataType == 'decimal'
          "
        >
          <div class="view-detail-container">
            <div class="view-detail-label">{{ $t("vlink.tsl.unit") }}</div>
            <div class="view-detail-value">
              {{ param.form.unit || "-" }}
            </div>
          </div>
        </el-col>
        <!-- 最大长度 -->
        <el-col
          :span="12"
          v-if="
            param.form.dataType !== 'int' && param.form.dataType !== 'decimal'
          "
        >
          <div class="view-detail-container">
            <div class="view-detail-label">{{ $t("vlink.tsl.maxLength") }}</div>
            <div class="view-detail-value">
              {{ param.form.maxLength || "-" }}
            </div>
          </div>
        </el-col>
        <!-- 参数描述 -->
        <el-col :span="12">
          <div class="view-detail-container">
            <div class="view-detail-label">
              {{ $t("vlink.tsl.paramDescription") }}
            </div>
            <div class="view-detail-value">
              {{ param.form.description || "-" }}
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 枚举列表 -->
      <el-row v-if="param.form.dataType == 'enum'">
        <div class="view-detail-container">
          <div class="view-detail-label">{{ $t("vlink.tsl.enumList") }}</div>
          <div v-for="(item, index) in param.form.enumList" :key="index">
            {{ index + "-" + item }}
          </div>
        </div>
      </el-row>
      <div class="buttons-container">
        <el-button plain @click="editHandler">编辑参数</el-button>
        <el-button plain @click="deleteHandler">删除参数</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { deepClone } from "@/utils/utils.js";
export default {
  props: {
    /**
     * 新增参数：add
     * 编辑参数：edit
     * 预览参数：view
     */
    formState: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
    },
  },
  watch: {
    formState: {
      handler(val) {
        this.currentState = val;
      },
      immediate: true,
    },
    formData: {
      handler(val) {
        if (val) {
          this.param.form = deepClone(val);
        } else {
          this.initForm();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      currentState: "",
      EditFlag: false,
      param: {
        form: {},
        formRules: {
          paramName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              pattern: /^[A-Za-z0-9-#@()]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          description: [
            {
              // pattern: /^[A-Za-z0-9-_@()\u4e00-\u9fa5]+$/,
              message: this.$t("vlink.product.productNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          min: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          max: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          maxLength: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  methods: {
    saveParamHandler() {
      this.$refs.paramForm.validate((v) => {
        if (v) {
          if (this.currentState === "add") {
            this.$emit("addParamList", this.param.form);
          }
          if (this.currentState === "edit") {
            this.$emit("updateParamList", this.param.form);
            this.currentState = "view";
          }
        }
      });
    },
    deleteHandler() {
      this.$emit("deleteParam", this.param.form.paramName);
    },
    initForm() {
      this.param.form = {
        paramName: null,
        description: null,
        required: false,
        dataType: "int",
        min: 0,
        max: 65535,
        step: 1,
        maxLength: 0,
        enumList: null,
      };
    },
    cancelHandler() {
      if (this.formState === "add") {
        // 新增
        this.$emit("close");
      } else {
        // 编辑
        this.currentState = "view";
      }
    },
    editHandler() {
      this.currentState = "edit";
    },
    addEnumItem() {
      if (!this.param.form.enumList) {
        this.param.form.enumList = [];
      }
      this.param.form.enumList.push("");
    },
    renderDataType(type) {
      switch (type) {
        case "int":
          return this.$t("vlink.dataType.int");
        case "decimal":
          return this.$t("vlink.dataType.decimal");
        case "string":
          return this.$t("vlink.dataType.string");
        case "dateTime":
          return this.$t("vlink.dataType.dateTime");
        case "enum":
          return this.$t("vlink.dataType.enum");
        case "jsonObject":
          return this.$t("vlink.dataType.jsonObject");
        case "stringList":
          return this.$t("vlink.dataType.stringList");
        case "img":
          return this.$t("vlink.dataType.img");
      }
    },
    renderValueRange(obj) {
      return `${obj.min} - ${obj.max}`;
    },
  },
};
</script>

<style scoped lang="less">
.single-param-form {
  .split-span {
    padding: 0 5px;
  }
  .buttons-container {
    margin-top: 15px;
  }
  .descript-input .el-input__count {
    top: 1px;
    height: 30px;
  }
  .view-detail-container {
    & > div {
      height: 36px;
      line-height: 36px;
    }
    .view-detail-label {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c6d6e;
    }
    .view-detail-value {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #303133;
      margin-bottom: 18px;
    }
  }
  .add-line {
    margin-top: 16px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0486fe;
    line-height: 20px;
    & > span {
      cursor: pointer;
    }
  }
  .enum-list-line {
    display: flex;
    margin-bottom: 4px;
    .index-span {
      display: inline-block;
      width: 25px;
    }
  }
  .el-form-item {
    margin-bottom: 18px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 0;
  }
  /deep/ .el-textarea__inner {
    min-height: 36px !important;
  }
}
</style>