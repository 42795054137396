const PythonJsonDefaultCode = `
# 将设备自定义topic数据转换为json格式数据, 设备上报数据到物联网平台时调用
# 入参: topic   字符串，设备上报消息的topic
# 入参: bytes 列表，列表元素取值为int类型    不能为空
# 出参: json 字典
def custom_topic(topic, bytes):
    
    bytes = [];
    
    return bytes;

`; 
export default PythonJsonDefaultCode;
