<template>
  <div>
    <template v-if="property.protocolName == 'modbus'">
      <property-modbus
        ref="modbus"
        :editable="editable"
        :property="property"
        @cancelEditProperty="cancelEditProperty"
      ></property-modbus>
    </template>
    <template v-else-if="property.protocolName == 'opcua'">
      <property-opcua
        ref="opcua"
        :editable="editable"
        :property="property"
        @cancelEditProperty="cancelEditProperty"
      ></property-opcua>
    </template>
    <template v-else-if="property.protocolName == 'bacnet'">
      <property-bacnet
        ref="bacnet"
        :editable="editable"
        :property="property"
        @cancelEditProperty="cancelEditProperty"
      ></property-bacnet>
    </template>
    <template v-else>
      <property-customize
        ref="custom"
        :editable="editable"
        @cancelEditProperty="cancelEditProperty"
        :property="property"
      ></property-customize>
    </template>
  </div>
</template>

<script>
import PropertyModbus from "./propertyModbus";
import PropertyOpcua from "./propertyOpcua";
import PropertyBacnet from "./propertyBacnet";
import PropertyCustomize from "./propertyCustomize";

export default {
  components: {
    PropertyModbus,
    PropertyOpcua,
    PropertyBacnet,
    PropertyCustomize,
  },
  props: {
    property: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    property(va) {
      console.log("va", va);
    },
  },
  data() {
    return {};
  },
  methods: {
    saveEmit(type) {
      this.$refs[type].cancelEditProperty();
    },
    cancelEditProperty(v) {
      this.$emit("cancelEditProperty", v);
    },
  },
};
</script>
