<template>
  <div class="script-parsing-component">
    <div class="title-line">
      {{ $t("vlink.scripting.scriptTitle") }}
    </div>
    <div class="script-select-line">
      <!--脚本语言下拉选择框-->
      <span class="name-span">
        {{ $t("vlink.scripting.scriptingLanguage") }}
      </span>
      <el-radio-group v-model="storeLocation" @change="languageChange">
        <el-radio label="JavaScript" border></el-radio>
        <el-radio label="Python" border></el-radio>
      </el-radio-group>
    </div>

    <div>
      <!--编辑器-->
      <ace-editor
        :key="currentRow.productKey"
        v-model="content"
        @init="editorInit"
        :lang="language"
        theme="chrome"
        style="border: 1px solid #ebecec; margin-top: 15px"
        height="60vh"
        width="80%"
      >
      </ace-editor>
    </div>

    <div class="buttons-container">
      <el-button type="primary" @click="releaseScript">
        {{ $t("vlink.commons.released") }}
      </el-button>
      <el-button type="warning" plain @click="debuggerDrawerShow = true">
        {{ $t("vlink.scripting.debugging") }}
      </el-button>
      <el-button
        type="primary"
        plain
        @click="saveScript"
        style="margin-top: 15px"
      >
        {{ $t("vlink.scripting.saveAsDraft") }}
      </el-button>
      <span class="compare-button" @click="open()">
        {{ $t("vlink.scripting.checkCompareScript") }}
      </span>
    </div>

    <!-- 脚本调试 -->
    <el-drawer
      v-if="debuggerDrawerShow"
      v-drawerDrag
      title=""
      :modal="false"
      :visible.sync="debuggerDrawerShow"
      direction="btt"
      :size="drawerHeight"
      :destroy-on-close="true"
      :wrapperClosable="false"
      :before-close="debuggerDrawerClose"
    >
      <div slot="title">
        <div class="drawer-title"></div>
      </div>
      <div class="drawer-container">
        <div class="detail-container">
          <div class="left-part part-div">
            <div class="title-line">
              {{ $t("vlink.scripting.analogInput") }}
            </div>
            <div class="selector-line">
              <span class="selector-title"> 模拟类型 </span>
              <el-radio-group v-model="value" @change="moniTypeChange">
                <el-radio label="report" border>{{
                  $t("vlink.scripting.imitateType") +
                  ": " +
                  $t("vlink.scripting.deviceReportData")
                }}</el-radio>
                <el-radio label="receive" border>{{
                  $t("vlink.scripting.imitateType") +
                  ": " +
                  $t("vlink.scripting.deviceReceiveData")
                }}</el-radio>
              </el-radio-group>
            </div>
            <div v-if="value === 'report'" class="selector-line">
              <span class="selector-title"> 数据类型 </span>
              <el-radio-group v-model="checked">
                <el-radio :label="false" border>文本 Text</el-radio>
                <el-radio :label="true" border>十六进制 Hex</el-radio>
              </el-radio-group>
            </div>

            <el-input
              v-loading="inputLoading"
              v-model="input"
              type="textarea"
              rows="8"
              style="margin-bottom: 16px; height: 180px"
              :placeholder="
                $t('vlink.scripting.checkHexToIndicateHexadecimalInput') +
                ',' +
                $t('vlink.scripting.inputStringDataIntoOriginalString')
              "
            ></el-input>
            <div class="drawer-buttons">
              <el-button type="primary" @click="debugScript">
                {{ $t("vlink.scripting.debugging") }}
              </el-button>
            </div>
          </div>
          <el-divider
            direction="vertical"
            :style="{ height: '400px' }"
          ></el-divider>
          <div class="right-part part-div">
            <div class="title-line">
              {{ $t("vlink.scripting.runningResult") }}
            </div>
            <div class="selector-line" v-if="value === 'receive'">
              <span class="selector-title"> 数据类型 </span>
              <el-radio-group v-model="formatValue" @change="formatSwitch">
                <el-radio label="text" border>文本 Text</el-radio>
                <el-radio label="hex" border>十六进制 Hex</el-radio>
                <el-radio label="base64" border>编码 Base64</el-radio>
              </el-radio-group>
            </div>
            <el-input
              v-model="outPut"
              type="textarea"
              rows="12"
              placeholder=""
            ></el-input>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 脚本对比弹框 -->
    <el-dialog :visible.sync="dialogShow" ref="dialog" width="80%">
      <ScriptContrast
        v-if="dialogShow"
        :productKey="currentRow.productKey"
        :scriptCode="this.content"
        :language="this.storeLocation.toLowerCase()"
      ></ScriptContrast>
    </el-dialog>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";
import ScriptContrast from "./ScriptContrast.vue";

import JavaScriptBinaryDefaultCode from "./scriptTemplate/JavaScriptBinaryDefaultCode.js";
import JavaScriptJsonDefaultCode from "./scriptTemplate/JavaScriptJsonDefaultCode.js";
import PythonBinaryDefaultCode from "./scriptTemplate/PythonBinaryDefaultCode.js";
import PythonJsonDefaultCode from "./scriptTemplate/PythonJsonDefaultCode.js";

import {
  createScript,
  findScript,
  updateScript,
  debugReceiveScript,
  debugReportScript,
  getTemplateJson,
} from "@/api/ruge/vlink/product/product";

export default {
  name: "container",
  components: { AceEditor, ScriptContrast },
  props: {
    currentRow: {
      type: Object,
    },
    detailInfo: {
      type: Object,
      default: null,
    },
  },
  watch: {
    currentRow: {
      deep: true,
      handler: function () {
        this.content = "";
      },
    },
  },
  data() {
    return {
      drawerHeight: 446,
      debuggerDrawerShow: false,
      flag: false,
      activeName: "first",
      formatValue: "hex",
      storeLocation: "JavaScript",
      beforeStorageValue: "", // 中介值
      value: "report",
      dialogShow: false,
      content: "",
      returnContent: {}, // 脚本查询接口返回值
      compareCode: true, // 查看模板函数是否更改
      input: "",
      inputResource: "",
      inputLoading: false,
      outPut: "",
      copyOutPut: "",
      hexOutPut: "",
      checked: false,
      check: false,
      dataMethod: "",
    };
  },
  computed: {
    language: function () {
      return this.storeLocation.toLowerCase();
    },
  },
  mounted() {
    this.beforeStorageValue = this.storeLocation;
  },
  methods: {
    moniTypeChange(val) {
      if (val === "receive") {
        if (this.inputResource) {
          this.input = this.inputResource;
        } else {
          const { productKey } = this.detailInfo;
          console.log("productKey", productKey);
          this.inputLoading = true;
          getTemplateJson({ productKey: productKey })
            .then((res) => {
              const resBeautyJson = JSON.stringify(res, null, "\t");
              this.inputResource = resBeautyJson;
              this.input = resBeautyJson;
              this.inputLoading = false;
            })
            .catch(() => {
              this.inputLoading = false;
            });
        }
      } else {
        this.input = "";
      }
    },
    languageChange() {
      this.$confirm(
        this.$t("vlink.scripting.switchingLanguagesWillClearUnsavedDrafts"),
        this.$t("vlink.scripting.areYouSureYouWantToSwitch"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "info",
        }
      )
        .then(() => {
          this.changeStoreForm();
        })
        .catch(() => {
          this.changeStoreCancle();
        });
    },
    debuggerDrawerClose() {
      this.debuggerDrawerShow = false;
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/mode/python");
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);

      //thingEditor.setReadOnly(true);
    },
    // 根据productKey查询脚本接口
    async findScript() {
      this.loading = true;
      findScript(this.currentRow.productKey)
        .then((res) => {
          this.returnContent = res;
          this.input = "";
          //通过查询更改monacoEditor内容
          if (res.scriptContent != null) {
            this.content = res.scriptContent;
            this.storeLocation = res.scriptLanguage;
          } else {
            // 获取模板
            this.defaultCode();
          }
        })
        .catch((err) => {
          console.log("错误信息：", err);
        });
    },
    //保存脚本为草稿箱
    saveScript() {
      this.$confirm("保存脚本为草稿", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 保存脚本
          this.compareScript();
          if (this.compareCode) {
            this.input = "";
            if (this.returnContent.scriptId) {
              this.edit();
            } else {
              this.new();
            }
          } else {
            this.$message({
              type: "error",
              message: "保存失败原因:" + this.input,
            });
            this.findScript();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存",
          });
        });
    },
    //发布脚本
    releaseScript() {
      this.$confirm("发布脚本,请确认是否发布", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.compareScript();
          console.log("xxxxx", this.compareCode);
          if (this.compareCode) {
            this.input = "";
            // 发布脚本
            console.log(
              "this.returnContent.productKey",
              this.currentRow.productKey
            );
            updateScript({
              scriptId: null,
              productKey: this.currentRow.productKey,
              scriptStatus: "published",
              scriptLanguage: this.storeLocation,
              scriptContent: this.content,
              createdBy: null,
              creationDate: "",
              lastUpdatedBy: null,
            })
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("发布成功"),
                  type: "success",
                });
                this.findScript();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.createLoading = false;
              });
          } else {
            this.$message({
              type: "error",
              message: "发布失败原因:" + this.input,
            });
            this.findScript();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消发布",
          });
        });
    },
    //  1， 新增脚本的接口，
    new() {
      createScript({
        scriptId: null,
        productKey: this.currentRow.productKey,
        scriptStatus: "draft",
        scriptLanguage: this.storeLocation,
        createdBy: null,
        scriptContent: this.content,
        creationDate: "",
        lastUpdatedBy: null,
      })
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findScript();
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    // 2， 修改脚本的接口。
    edit() {
      // updateScript
      updateScript({
        scriptId: null,
        productKey: this.currentRow.productKey,
        scriptStatus: "draft",
        scriptLanguage: this.storeLocation,
        createdBy: null,
        scriptContent: this.content,
        creationDate: "",
        lastUpdatedBy: null,
      })
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findScript();
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    open() {
      this.dialogShow = true;
      // this.$refs.dialog.open()
    },
    // 取消换脚本语言
    changeStoreCancle() {
      this.storeLocation = this.beforeStorageValue;
      // this.language = this.storeLocation.toLowerCase();
      if (this.storeLocation == this.returnContent.scriptLanguage) {
        this.content = this.returnContent.scriptContent;
      } else {
        this.defaultCode();
      }
    },
    //确定换脚本语言
    changeStoreForm() {
      this.beforeStorageValue = this.storeLocation; // 确定更改后使中介值等于目前框内的值，这样下次确定改变取消的话，可以回到现在的值
      // this.language = this.storeLocation.toLowerCase();
      //判断切换到的脚本是否有保存的草稿
      if (this.storeLocation == this.returnContent.scriptLanguage) {
        this.content = this.returnContent.scriptContent;
      } else {
        this.defaultCode();
      }
    },
    // 获取脚本模板
    defaultCode() {
      // 根据datatype 里json 和 BINARY 来判断 ，默认是显示一个接口还是三个接口
      console.log("this.detailInfo.dataType", this.detailInfo.dataType);
      console.log("storeLocation:", this.storeLocation);

      if (this.detailInfo != null) {
        if (this.detailInfo.dataType === "BINARY") {
          if (this.storeLocation === "JavaScript") {
            this.content = JavaScriptBinaryDefaultCode;
          } else {
            // 获取Python模板
            this.content = PythonBinaryDefaultCode;
          }
        } else {
          if (this.storeLocation === "JavaScript") {
            this.content = JavaScriptJsonDefaultCode;
          } else {
            // 获取Python模板
            this.content = PythonJsonDefaultCode;
          }
        }
      }
    },
    //  比较脚本 是否是 模板函数
    compareScript() {
      let arr = [];
      if (this.detailInfo != null) {
        if (this.detailInfo.dataType === "BINARY") {
          if (this.storeLocation === "JavaScript") {
            arr = [
              "function customTopic(",
              "function bytesToJson(",
              "function jsonToBytes(",
            ];
          } else {
            arr = [
              "def custom_topic(",
              "def bytes_to_json(",
              "def json_to_bytes(",
            ];
          }
        } else {
          if (this.storeLocation === "JavaScript") {
            arr = ["function customTopic("];
          } else {
            arr = ["def custom_topic("];
          }
        }
      }
      for (const i of arr) {
        if (this.content.indexOf(i) === -1) {
          this.compareCode = false;
          if (this.storeLocation === "JavaScript") {
            return (this.input =
              "脚本中需定义customTopic,bytesToJson和jsonToBytes方法");
          } else {
            return (this.input =
              "脚本中需定义custom_topic,bytes_to_json和json_to_bytes方法");
          }
        } else {
          this.compareCode = true;
        }
      }
    },
    // 调试脚本
    debugScript() {
      if (this.value === "report") {
        this.debugReportScript();
      } else {
        this.debugReceiveScript();
      }
    },
    // 脚本上报数据调试
    async debugReportScript() {
      this.loading = true;
      this.uploadType();
      debugReportScript({
        scriptLanguage: this.storeLocation,
        scriptContent: this.content,
        dataMethod: this.dataMethod,
        data: this.input,
        hex: this.checked,
        productKey: this.currentRow.productKey,
      })
        .then((res) => {
          this.activeName = "second";
          this.outPut = res;
          this.copyOutPut = res;
        })
        .catch((err) => {
          console.log("错误信息：", err);
        });
    },
    // 脚本接收数据调试
    async debugReceiveScript() {
      this.loading = true;
      this.uploadType();
      debugReceiveScript({
        scriptLanguage: this.storeLocation,
        scriptContent: this.content,
        dataMethod: this.dataMethod,
        data: this.input,
        hex: this.checked,
        productKey: this.currentRow.productKey,
      })
        .then((res) => {
          this.activeName = "second";
          this.outPut = JSON.stringify(res);
          this.copyOutPut = JSON.stringify(res);
          this.bytes2HexString();
          this.formatValue = "hex";
        })
        .catch((err) => {
          console.log("错误信息：", err);
        });
    },
    // 获取调试脚本上报方法
    uploadType() {
      if (this.storeLocation === "JavaScript") {
        if (this.value === "report") {
          this.dataMethod = "bytesToJson";
        } else {
          this.dataMethod = "jsonToBytes";
        }
      } else {
        if (this.value === "report") {
          this.dataMethod = "bytes_to_json";
        } else {
          this.dataMethod = "json_to_bytes";
        }
      }
    },
    // 根据下拉框去选择转换类型
    formatSwitch(formatValue) {
      switch (formatValue) {
        //字节数组转十六进制字符串
        case "hex":
          this.bytes2HexString();
          break;
        //字节数组转字符串
        case "text":
          this.utf8ByteToUnicodeStr(JSON.parse(this.copyOutPut));
          break;
        //16进制转base64
        case "base64":
          this.hexToBase64();
          break;
      }
    },
    //字节数组转十六进制字符串，对负值填坑
    bytes2HexString() {
      var String = JSON.parse(this.copyOutPut);
      var str = "";
      for (var i = 0; i < String.length; i++) {
        var tmp;
        var num = String[i];
        if (num < 0) {
          //此处填坑，当byte因为符合位导致数值为负时候，需要对数据进行处理
          tmp = (255 + num + 1).toString(16);
        } else {
          tmp = num.toString(16);
        }
        if (tmp.length == 1) {
          tmp = "0" + tmp;
        }
        str += tmp;
      }
      this.outPut = str;
      this.hexOutPut = str;
    },
    //byte转string
    utf8ByteToUnicodeStr(utf8Bytes) {
      var unicodeStr = "";
      for (var pos = 0; pos < utf8Bytes.length; ) {
        var flag = utf8Bytes[pos];
        var unicode = 0;
        if (flag >>> 7 === 0) {
          unicodeStr += String.fromCharCode(utf8Bytes[pos]);
          pos += 1;
        } else if ((flag & 0xfc) === 0xfc) {
          unicode = (utf8Bytes[pos] & 0x3) << 30;
          unicode |= (utf8Bytes[pos + 1] & 0x3f) << 24;
          unicode |= (utf8Bytes[pos + 2] & 0x3f) << 18;
          unicode |= (utf8Bytes[pos + 3] & 0x3f) << 12;
          unicode |= (utf8Bytes[pos + 4] & 0x3f) << 6;
          unicode |= utf8Bytes[pos + 5] & 0x3f;
          unicodeStr += String.fromCharCode(unicode);
          pos += 6;
        } else if ((flag & 0xf8) === 0xf8) {
          unicode = (utf8Bytes[pos] & 0x7) << 24;
          unicode |= (utf8Bytes[pos + 1] & 0x3f) << 18;
          unicode |= (utf8Bytes[pos + 2] & 0x3f) << 12;
          unicode |= (utf8Bytes[pos + 3] & 0x3f) << 6;
          unicode |= utf8Bytes[pos + 4] & 0x3f;
          unicodeStr += String.fromCharCode(unicode);
          pos += 5;
        } else if ((flag & 0xf0) === 0xf0) {
          unicode = (utf8Bytes[pos] & 0xf) << 18;
          unicode |= (utf8Bytes[pos + 1] & 0x3f) << 12;
          unicode |= (utf8Bytes[pos + 2] & 0x3f) << 6;
          unicode |= utf8Bytes[pos + 3] & 0x3f;
          unicodeStr += String.fromCharCode(unicode);
          pos += 4;
        } else if ((flag & 0xe0) === 0xe0) {
          unicode = (utf8Bytes[pos] & 0x1f) << 12;
          unicode |= (utf8Bytes[pos + 1] & 0x3f) << 6;
          unicode |= utf8Bytes[pos + 2] & 0x3f;
          unicodeStr += String.fromCharCode(unicode);
          pos += 3;
        } else if ((flag & 0xc0) === 0xc0) {
          //110
          unicode = (utf8Bytes[pos] & 0x3f) << 6;
          unicode |= utf8Bytes[pos + 1] & 0x3f;
          unicodeStr += String.fromCharCode(unicode);
          pos += 2;
        } else {
          unicodeStr += String.fromCharCode(utf8Bytes[pos]);
          pos += 1;
        }
      }
      this.outPut = unicodeStr;
    },
    // 16进制转base64
    hexToBase64() {
      if (!this.hexOutPut) return;
      this.btoa(
        String.fromCharCode.apply(
          null,
          this.hexOutPut
            .replace(/\r|\n/g, "")
            .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
            .replace(/ +$/, "")
            .split(" ")
        )
      );
    },
    btoa(bin) {
      var tableStr =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
      var table = tableStr.split("");
      for (var i = 0, j = 0, len = bin.length / 3, base64 = []; i < len; ++i) {
        var a = bin.charCodeAt(j++),
          b = bin.charCodeAt(j++),
          c = bin.charCodeAt(j++);
        if ((a | b | c) > 255)
          throw new Error("String contains an invalid character");
        base64[base64.length] =
          table[a >> 2] +
          table[((a << 4) & 63) | (b >> 4)] +
          (isNaN(b) ? "=" : table[((b << 2) & 63) | (c >> 6)]) +
          (isNaN(b + c) ? "=" : table[c & 63]);
      }
      this.outPut = base64.join("");
    },
  },
};
</script>
<style scoped lang="less">
.script-parsing-component {
  .title-line {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-bottom: 16px;
  }
  .script-select-line {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    .name-span {
      margin-right: 20px;
    }
  }
  .drawer-title {
    text-align: center;
    .el-icon-d-caret {
      cursor: n-resize;
      position: relative;
      left: 17px;
    }
  }
  .drawer-container {
    padding: 0 32px;
    .detail-container {
      display: flex;
      .part-div {
        flex: 1;
      }
      .title-line {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      .left-part {
        margin-right: 28px;
      }
      .right-part {
        margin-left: 28px;
      }
      .selector-line {
        margin-bottom: 16px;
        .selector-title {
          margin-right: 20px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }
    }
    /deep/ .el-divider--vertical {
      height: 370px;
    }
  }
  .buttons-container {
    .compare-button {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0486fe;
      margin-left: 20px;
      &:hover {
        color: #0f2fd5;
      }
    }
  }
  /deep/ .el-button + .el-button {
    margin-left: 20px;
  }
  /deep/ .el-drawer__header {
    padding-top: 8px;
    margin-bottom: 10px;
  }
  /deep/ .el-textarea__inner {
    height: 100%;
  }
}
</style>