var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit-params-comp" }, [
    !_vm.EditFlag
      ? _c("div", { staticClass: "add-line" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  _vm.EditFlag = true
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-plus" }),
              _vm._v("\n      " + _vm._s(_vm.titleRender()) + "\n    "),
            ]
          ),
        ])
      : _c(
          "div",
          { class: _vm.paramList.length === 0 ? "" : "edit-container" },
          [
            _c("el-divider", { staticClass: "top-devider" }),
            _c("singleParamForm", {
              attrs: { formState: "add" },
              on: {
                close: function ($event) {
                  _vm.EditFlag = false
                },
                addParamList: _vm.addParamList,
              },
            }),
            _vm.paramList.length === 0 ? _c("el-divider") : _vm._e(),
          ],
          1
        ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.paramList.length > 0,
            expression: "paramList.length > 0",
          },
        ],
        staticClass: "params-list-container",
      },
      [
        _c(
          "el-collapse",
          { on: { change: _vm.collapseHandleChange } },
          _vm._l(_vm.paramList, function (item, index) {
            return _c(
              "el-collapse-item",
              {
                key: index,
                attrs: {
                  title: item.description
                    ? item.paramName + " - " + item.description
                    : item.paramName,
                  name: item.paramName,
                },
              },
              [
                _c("singleParamForm", {
                  attrs: { formData: item, formState: "view" },
                  on: {
                    updateParamList: _vm.updateParamList,
                    deleteParam: _vm.deleteParam,
                  },
                }),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }