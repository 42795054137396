var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "bacnetForm",
          attrs: {
            "label-position": "top",
            rules: _vm.bacnetRule,
            model: _vm.bacnetForm,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.objectType"),
                        prop: "objectType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.bacnetForm.objectType,
                            callback: function ($$v) {
                              _vm.$set(_vm.bacnetForm, "objectType", $$v)
                            },
                            expression: "bacnetForm.objectType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.analogInput"),
                              value: 0,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.analogOutput"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.analogValue"),
                              value: 2,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.binaryInput"),
                              value: 3,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.binaryOutput"),
                              value: 4,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.binaryValue"),
                              value: 5,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.multiStateInput"),
                              value: 13,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.objectId"),
                        prop: "objectId",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "20",
                          "show-word-limit": true,
                          clearable: "",
                        },
                        model: {
                          value: _vm.bacnetForm.objectId,
                          callback: function ($$v) {
                            _vm.$set(_vm.bacnetForm, "objectId", $$v)
                          },
                          expression: "bacnetForm.objectId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }