var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "script-contrast-comp", attrs: { id: "contrast" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("div", { staticClass: "draft-title-line" }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("vlink.commons.draft")) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("ace-editor", {
                staticStyle: { border: "1px solid #ebecec" },
                attrs: { lang: this.language, theme: "chrome", height: "60vh" },
                on: { init: _vm.editorInit },
                model: {
                  value: _vm.scriptCode,
                  callback: function ($$v) {
                    _vm.scriptCode = $$v
                  },
                  expression: "scriptCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("vlink.scripting.draftScript"),
                        name: "first",
                      },
                    },
                    [
                      _vm.productKey
                        ? _c("ace-editor", {
                            key: _vm.productKey,
                            staticStyle: { border: "1px solid #ebecec" },
                            attrs: {
                              lang: this.draftLanguage,
                              theme: "chrome",
                              height: "60vh",
                            },
                            on: { init: _vm.editorInit },
                            model: {
                              value: _vm.draftScript,
                              callback: function ($$v) {
                                _vm.draftScript = $$v
                              },
                              expression: "draftScript",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("vlink.scripting.publishedScript"),
                        name: "second",
                      },
                    },
                    [
                      _vm.productKey
                        ? _c("ace-editor", {
                            key: _vm.productKey,
                            staticStyle: { border: "1px solid #ebecec" },
                            attrs: {
                              lang: this.publishedLanguage,
                              theme: "chrome",
                              height: "60vh",
                            },
                            on: { init: _vm.editorInit },
                            model: {
                              value: _vm.publishedScript,
                              callback: function ($$v) {
                                _vm.publishedScript = $$v
                              },
                              expression: "publishedScript",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }