<template>
  <div class="app-container product-detail-tabs" v-loading="loading">
    <el-tabs
      v-model="activeTabName"
      @tab-click="tabsChange"
      class="first-level-tab"
    >
      <el-tab-pane :label="$t('vlink.product.summary')" name="info">
        <productInfo
          v-if="activeMap.info"
          v-bind:currentRow="currentRow"
          :detailInfo="detailInfo"
        ></productInfo>
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.product.tslManagement')" name="tsl">
        <tsl v-if="activeMap.tsl" v-bind:currentRow="currentRow"></tsl>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('vlink.product.topicManagement')"
        name="topic"
        v-if="currentRow.nodeType != 'SUBDEVICE'"
      >
        <topic v-if="activeMap.topic" v-bind:currentRow="currentRow"></topic>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('vlink.product.ScriptParsing')"
        name="ScriptParsing"
      >
        <ScriptParsing
          v-if="activeMap.ScriptParsing"
          v-bind:currentRow="currentRow"
          :detailInfo="detailInfo"
          ref="scriptParsingChild"
        ></ScriptParsing>
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.product.OtaManagement')" name="OTA">
        <OTA
          v-if="activeMap.OTA"
          v-bind:currentRow="currentRow"
          :detailInfo="detailInfo"
          ref="OTAChild"
        ></OTA>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import productInfo from "./productInfo.vue";
import Tsl from "./tslComponent";
import Topic from "@/views/ruge/vlink/product/topic";
import ScriptParsing from "./scriptParsingComponent.vue";
import OTA from "@/views/ruge/vlink/product/OTACoponent";
import { findProductDetail } from "@/api/ruge/vlink/product/product";
export default {
  components: {
    productInfo,
    Tsl,
    Topic,
    ScriptParsing,
    OTA,
  },
  data() {
    return {
      loading: true,
      activeTabName: "info",
      detailInfo: null,
      count: 0,
      currentRow: {},
      activeMap: {
        info: false,
        tsl: false,
        topic: false,
        ScriptParsing: false,
        OTA: false,
      },
    };
  },
  watch: {
    activeTabName: {
      handler(tabName) {
        if (!this.activeMap[tabName]) {
          this.activeMap[tabName] = true;
        }
      },
      immediate: true,
    },
  },
  //   props: {
  //     currentRow: {
  //       type: Object,
  //       required: true,
  //     },
  //   },
  //   computed: {
  //     productId() {
  //       return this.currentRow.productId;
  //     },
  //   },
  //   watch: {
  //     productId: {
  //       immediate: true,
  //       handler: function () {
  //         this.init();
  //       },
  //     },
  //   },
  created() {
    const { productKey } = this.$route.query;
    this.currentRow = { productKey };
    this.init();
  },
  methods: {
    // 页面初始化
    init() {
      this.activeTabName = "info";
      this.getDetail();
      this.count = 0;
    },
    tabsChange(ev) {
      if (ev.paneName === "ScriptParsing") {
        if (this.count < 1) {
          this.$refs.scriptParsingChild.findScript();
          this.count = 1;
        }
      }
    },
    activeTabsHandler() {
      const { activeTabs } = this.$route.query;
      if (activeTabs) {
        this.activeTabName = activeTabs;
      }
    },
    getDetail() {
      this.loading = true;
      findProductDetail({ productKey: this.currentRow.productKey })
        .then((response) => {
          this.detailInfo = response;
          this.currentRow.productId = response.productId;
          this.currentRow.nodeType = response.nodeType;
          this.currentRow.dataType = response.dataType;
          this.$eventBus.$emit("setDetailAlias", response.productName);
        })
        .catch((error) => {
          console.log(`服务请求失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
          this.activeTabsHandler();
        });
    },
  },
};
</script>
<style scoped lang="less">
.product-detail-tabs {
  padding: 0;
}
/deep/ .el-tabs__content {
  overflow: unset;
}
</style>