var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-service-comp" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "serviceForm",
          staticClass: "scroll-view",
          attrs: {
            "label-position": "top",
            model: _vm.service.form,
            rules: _vm.service.formRules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.serviceName"),
                        prop: "serviceName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: _vm.currentService.isEdit,
                          maxlength: "32",
                          clearable: "",
                          placeholder: _vm.$t("commons.pleaseInput"),
                        },
                        model: {
                          value: _vm.service.form.serviceName,
                          callback: function ($$v) {
                            _vm.$set(_vm.service.form, "serviceName", $$v)
                          },
                          expression: "service.form.serviceName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.description"),
                        prop: "description",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "100",
                          placeholder: _vm.$t("commons.pleaseInput"),
                        },
                        model: {
                          value: _vm.service.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.service.form, "description", $$v)
                          },
                          expression: "service.form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("vlink.tsl.inputData") } },
            [
              _c("EditParams", {
                ref: "inputParamsRef",
                attrs: { dataset: _vm.service.form.inputData },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("vlink.tsl.responseParam") } },
            [
              _c("EditParams", {
                ref: "responseParamsRef",
                attrs: {
                  paramType: "response",
                  dataset: _vm.service.form.outputData,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("vlink.tsl.extendConfig"),
                prop: "extendConfig",
              },
            },
            [
              _c(
                "div",
                { staticClass: "extend-config-line" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.service.form.needConfig,
                      callback: function ($$v) {
                        _vm.$set(_vm.service.form, "needConfig", $$v)
                      },
                      expression: "service.form.needConfig",
                    },
                  }),
                  _c(
                    "span",
                    {
                      class: _vm.service.form.needConfig
                        ? "config-title-active"
                        : "config-title-default",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.service.form.needConfig ? "已开启" : "未开启"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("span", { staticClass: "config-tips" }, [
                    _c("i", { staticClass: "el-icon-info" }),
                    _vm._v("\n          关闭时，将不保存扩展配置。\n        "),
                  ]),
                ],
                1
              ),
              _vm.service.form.needConfig
                ? _c("ace-editor", {
                    staticStyle: { border: "1px solid #ebecec" },
                    attrs: { lang: "json", theme: "chrome", height: "300px" },
                    on: { init: _vm.editorInit },
                    model: {
                      value: _vm.service.form.extendConfig,
                      callback: function ($$v) {
                        _vm.$set(_vm.service.form, "extendConfig", $$v)
                      },
                      expression: "service.form.extendConfig",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { slot: "footer", align: "right" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeSaveService(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleSaveService },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.currentService.isEdit
                    ? _vm.$t("commons.save")
                    : _vm.$t("commons.add")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }