var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container product-detail-tabs",
    },
    [
      _c(
        "el-tabs",
        {
          staticClass: "first-level-tab",
          on: { "tab-click": _vm.tabsChange },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("vlink.product.summary"), name: "info" } },
            [
              _vm.activeMap.info
                ? _c("productInfo", {
                    attrs: {
                      currentRow: _vm.currentRow,
                      detailInfo: _vm.detailInfo,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("vlink.product.tslManagement"),
                name: "tsl",
              },
            },
            [
              _vm.activeMap.tsl
                ? _c("tsl", { attrs: { currentRow: _vm.currentRow } })
                : _vm._e(),
            ],
            1
          ),
          _vm.currentRow.nodeType != "SUBDEVICE"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("vlink.product.topicManagement"),
                    name: "topic",
                  },
                },
                [
                  _vm.activeMap.topic
                    ? _c("topic", { attrs: { currentRow: _vm.currentRow } })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("vlink.product.ScriptParsing"),
                name: "ScriptParsing",
              },
            },
            [
              _vm.activeMap.ScriptParsing
                ? _c("ScriptParsing", {
                    ref: "scriptParsingChild",
                    attrs: {
                      currentRow: _vm.currentRow,
                      detailInfo: _vm.detailInfo,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("vlink.product.OtaManagement"),
                name: "OTA",
              },
            },
            [
              _vm.activeMap.OTA
                ? _c("OTA", {
                    ref: "OTAChild",
                    attrs: {
                      currentRow: _vm.currentRow,
                      detailInfo: _vm.detailInfo,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }