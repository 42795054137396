var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "single-param-form" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentState !== "view",
            expression: "currentState !== 'view'",
          },
        ],
      },
      [
        _c(
          "el-form",
          {
            ref: "paramForm",
            attrs: {
              "label-position": "top",
              model: _vm.param.form,
              rules: _vm.param.formRules,
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 48 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("vlink.tsl.paramName"),
                          prop: "paramName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            readonly: _vm.currentState === "edit",
                            maxlength: "32",
                            clearable: "",
                            placeholder: _vm.$t("commons.pleaseInput"),
                          },
                          model: {
                            value: _vm.param.form.paramName,
                            callback: function ($$v) {
                              _vm.$set(_vm.param.form, "paramName", $$v)
                            },
                            expression: "param.form.paramName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("vlink.tsl.dataType"),
                          prop: "dataType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            style: { width: "100%" },
                            model: {
                              value: _vm.param.form.dataType,
                              callback: function ($$v) {
                                _vm.$set(_vm.param.form, "dataType", $$v)
                              },
                              expression: "param.form.dataType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.int"),
                                value: "int",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.decimal"),
                                value: "decimal",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.string"),
                                value: "string",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.dateTime"),
                                value: "dateTime",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.enum"),
                                value: "enum",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.jsonObject"),
                                value: "jsonObject",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.stringList"),
                                value: "stringList",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("vlink.dataType.img"),
                                value: "img",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.param.form.dataType == "int" ||
                _vm.param.form.dataType == "decimal"
                  ? _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.valueRange"),
                              required: "",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "min" } },
                                  [
                                    _c("el-input-number", {
                                      style: { width: "100%" },
                                      attrs: {
                                        "controls-position": "right",
                                        min: -2147483647,
                                        max: _vm.param.form.max - 1,
                                      },
                                      model: {
                                        value: _vm.param.form.min,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.param.form, "min", $$v)
                                        },
                                        expression: "param.form.min",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "split-span" }, [
                                  _vm._v("-"),
                                ]),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "max" } },
                                  [
                                    _c("el-input-number", {
                                      style: { width: "100%" },
                                      attrs: {
                                        "controls-position": "right",
                                        min: _vm.param.form.min + 1,
                                        max: 2147483647,
                                      },
                                      model: {
                                        value: _vm.param.form.max,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.param.form, "max", $$v)
                                        },
                                        expression: "param.form.max",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.param.form.dataType == "int" ||
                _vm.param.form.dataType == "decimal"
                  ? _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.step"),
                              prop: "step",
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "tsl-input-number",
                              attrs: {
                                "controls-position": "right",
                                min: 0,
                                max: 50,
                              },
                              model: {
                                value: _vm.param.form.step,
                                callback: function ($$v) {
                                  _vm.$set(_vm.param.form, "step", $$v)
                                },
                                expression: "param.form.step",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.param.form.dataType == "int" ||
                _vm.param.form.dataType == "decimal"
                  ? _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.unit"),
                              prop: "unit",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "16",
                                clearable: "",
                                placeholder: _vm.$t("commons.pleaseInput"),
                              },
                              model: {
                                value: _vm.param.form.unit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.param.form, "unit", $$v)
                                },
                                expression: "param.form.unit",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.param.form.dataType !== "int" &&
                _vm.param.form.dataType !== "decimal"
                  ? _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.maxLength"),
                              prop: "maxLength",
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "tsl-input-number",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "controls-position": "right",
                                min: 0,
                                max: 2147483647,
                              },
                              model: {
                                value: _vm.param.form.maxLength,
                                callback: function ($$v) {
                                  _vm.$set(_vm.param.form, "maxLength", $$v)
                                },
                                expression: "param.form.maxLength",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("vlink.tsl.paramDescription"),
                          prop: "description",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "descript-input",
                          attrs: {
                            maxlength: "100",
                            placeholder: _vm.$t("commons.pleaseInput"),
                          },
                          model: {
                            value: _vm.param.form.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.param.form, "description", $$v)
                            },
                            expression: "param.form.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.param.form.dataType == "enum"
              ? _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("vlink.tsl.enumList") } },
                          [
                            _vm._l(
                              _vm.param.form.enumList,
                              function (enumItem, index) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    staticStyle: { "margin-top": "10px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "enum-list-line" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "index-span" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(index + " - ") +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "16",
                                            clearable: "",
                                            placeholder: _vm.$t(
                                              "commons.pleaseInput"
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.param.form.enumList[index],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.param.form.enumList,
                                                index,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "param.form.enumList[index]",
                                          },
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeEnumItem(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-delete delete-btn",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            _c("div", { staticClass: "add-line" }, [
                              _c("span", { on: { click: _vm.addEnumItem } }, [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("vlink.tsl.addEnumItem")) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "buttons-container" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.saveParamHandler },
              },
              [_vm._v("保存参数")]
            ),
            _c(
              "el-button",
              { attrs: { plain: "" }, on: { click: _vm.cancelHandler } },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentState === "view",
            expression: "currentState === 'view'",
          },
        ],
      },
      [
        _c(
          "el-row",
          { attrs: { gutter: 48 } },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "view-detail-container" }, [
                _c("div", { staticClass: "view-detail-label" }, [
                  _vm._v(_vm._s(_vm.$t("vlink.tsl.paramName"))),
                ]),
                _c("div", { staticClass: "view-detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.param.form.paramName) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "view-detail-container" }, [
                _c("div", { staticClass: "view-detail-label" }, [
                  _vm._v(_vm._s(_vm.$t("vlink.tsl.dataType"))),
                ]),
                _c("div", { staticClass: "view-detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.renderDataType(_vm.param.form.dataType)) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
            _vm.param.form.dataType == "int" ||
            _vm.param.form.dataType == "decimal"
              ? _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "view-detail-container" }, [
                    _c("div", { staticClass: "view-detail-label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vlink.tsl.valueRange")) +
                          "\n          "
                      ),
                    ]),
                    _c("div", { staticClass: "view-detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.renderValueRange(_vm.param.form)) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.param.form.dataType == "int" ||
            _vm.param.form.dataType == "decimal"
              ? _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "view-detail-container" }, [
                    _c("div", { staticClass: "view-detail-label" }, [
                      _vm._v(_vm._s(_vm.$t("vlink.tsl.step"))),
                    ]),
                    _c("div", { staticClass: "view-detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.param.form.step || "-") +
                          "\n          "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.param.form.dataType == "int" ||
            _vm.param.form.dataType == "decimal"
              ? _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "view-detail-container" }, [
                    _c("div", { staticClass: "view-detail-label" }, [
                      _vm._v(_vm._s(_vm.$t("vlink.tsl.unit"))),
                    ]),
                    _c("div", { staticClass: "view-detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.param.form.unit || "-") +
                          "\n          "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.param.form.dataType !== "int" &&
            _vm.param.form.dataType !== "decimal"
              ? _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "view-detail-container" }, [
                    _c("div", { staticClass: "view-detail-label" }, [
                      _vm._v(_vm._s(_vm.$t("vlink.tsl.maxLength"))),
                    ]),
                    _c("div", { staticClass: "view-detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.param.form.maxLength || "-") +
                          "\n          "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "view-detail-container" }, [
                _c("div", { staticClass: "view-detail-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("vlink.tsl.paramDescription")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "view-detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.param.form.description || "-") +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _vm.param.form.dataType == "enum"
          ? _c("el-row", [
              _c(
                "div",
                { staticClass: "view-detail-container" },
                [
                  _c("div", { staticClass: "view-detail-label" }, [
                    _vm._v(_vm._s(_vm.$t("vlink.tsl.enumList"))),
                  ]),
                  _vm._l(_vm.param.form.enumList, function (item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(index + "-" + item) +
                          "\n        "
                      ),
                    ])
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "buttons-container" },
          [
            _c(
              "el-button",
              { attrs: { plain: "" }, on: { click: _vm.editHandler } },
              [_vm._v("编辑参数")]
            ),
            _c(
              "el-button",
              { attrs: { plain: "" }, on: { click: _vm.deleteHandler } },
              [_vm._v("删除参数")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }