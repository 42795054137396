<template>
  <div class="tsl-component" v-loading="loading">
    <div class="buttons-container">
      <el-button type="primary" @click="addModule">添加模块</el-button>
      <el-button v-if="!noDataFlag" @click="viewTsl">查看物模型</el-button>
    </div>
    <div class="no-data-container" v-if="noDataFlag">
      <span>
        物模型用于描述设备具备的属性和能力，设备接入平台前，请先配置设备所属产品的物模型。
      </span>
    </div>
    <div class="tabs-container" v-else>
      <el-tabs v-model="activeTabName">
        <el-tab-pane
          v-for="(item, index) in tsl.modules"
          :key="index"
          :label="item.moduleName"
          :name="item.moduleName"
        >
          <singleTsl
            :currentRow="currentRow"
            :dataset="item"
            :tsl="tsl"
            @updateDesc="updateDesc"
            @updateObject="updateObject"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="footer-container" v-if="!noDataFlag">
      <el-button type="danger" plain @click="deleteModule">删除模块</el-button>
    </div>

    <!-- 添加/编辑模块 -->
    <el-dialog
      :visible.sync="module.addDialogVisible"
      :before-close="closeAddModule"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ module.title }}
      </template>
      <el-form
        label-position="top"
        :model="module.form"
        ref="moduleForm"
        :rules="module.formRules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              v-if="!this.module.isEdit"
              :label="$t('vlink.tsl.moduleName')"
              prop="moduleName"
            >
              <el-input
                v-model="module.form.moduleName"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.tsl.description')"
              prop="description"
            >
              <el-input
                v-model="module.form.description"
                maxlength="100"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="closeAddModule">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleAddModule">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 物模型详情 -->
    <el-dialog
      style="line-height: 16px"
      width="728px"
      :append-to-body="true"
      :before-close="closeViewTsl"
      :visible.sync="viewTslVisible"
    >
      <template slot="title">
        <span>{{ $t("vlink.tsl.tslInfo") }}</span>
      </template>
      <tsl-info :tsl="tsl" @close="closeViewTsl"></tsl-info>
    </el-dialog>
  </div>
</template>

<script>
import { findTsl, updateTsl } from "@/api/ruge/vlink/product/product";
import singleTsl from "./singleTsl";
import TslInfo from "@/views/ruge/vlink/product/tsl/tslInfo";
import { deepClone } from "@/utils/utils.js";
export default {
  name: "tslComponent",
  components: {
    singleTsl,
    TslInfo,
  },
  data() {
    return {
      loading: false,
      activeTabName: null,
      tsl: {},
      viewTslVisible: false,
      module: {
        addDialogVisible: false,
        isEdit: false,
        title: null,
        form: {
          moduleName: null,
          description: null,
        },
        formRules: {
          moduleName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              min: 4,
              message: this.$t("vlink.device.deviceNameRule1"),
              trigger: ["blur"],
            },
            {
              pattern: /^[A-Za-z0-9-]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          description: [
            {
              pattern: /^[A-Za-z0-9-_@()\u4e00-\u9fa5]+$/,
              message: this.$t("vlink.product.productNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    noDataFlag() {
      if (!this.tsl.modules || this.tsl.modules.length === 0) {
        return true;
      }
      return false;
    },
    productId() {
      return this.currentRow.productId;
    },
  },
  watch: {
    productId: {
      immediate: true,
      handler: function () {
        this.findTsl();
      },
    },
  },
  methods: {
    updateDesc(datas) {
      this.tsl.modules.forEach((item) => {
        if (item.moduleName === datas.moduleName) {
          item.description = datas.description;
        }
      });
      updateTsl(this.tsl)
        .then(() => {
          // 成功提示
          this.$message({
            message: "描述更新成功！",
            type: "success",
          });
          this.findTsl(datas.moduleName);
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.descriptionEdit = false;
        });
    },
    updateObject(moduleName) {
      this.findTsl(moduleName);
    },
    deleteModule() {
      const moduleName = this.activeTabName;
      this.$confirm(this.$t("vlink.tsl.tips2"), this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          let tslObj = Object.assign(
            { productKey: this.currentRow.productKey },
            this.tsl
          );
          tslObj.modules = tslObj.modules.filter(
            (item) => item.moduleName !== moduleName
          );
          // 保存tsl
          return updateTsl(tslObj)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.tsl = tslObj;
              if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
                this.activeTabName = this.tsl.modules[0].moduleName;
              }
            })
            .catch((error) => {
              console.log(`保存失败，原因 => ${error}`);
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    viewTsl() {
      this.viewTslVisible = true;
    },
    closeViewTsl() {
      this.viewTslVisible = false;
    },
    // 保存添加/编辑模块
    handleAddModule() {
      this.$refs.moduleForm.validate((v) => {
        if (v) {
          if (!this.module.isEdit) {
            // moduleName唯一性校验
            if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
              let obj = this.tsl.modules.find((item) => {
                return item.moduleName == this.module.form.moduleName;
              });
              if (obj) {
                this.$message({
                  message: this.$t("vlink.tsl.tips1"),
                  type: "warning",
                });
                return;
              }
            }
          }

          this.loading = true;
          let tslObj = Object.assign(
            { productKey: this.currentRow.productKey },
            this.tsl
          );
          if (!tslObj.modules) {
            tslObj.modules = [];
          }

          if (this.module.isEdit) {
            tslObj.modules.find((item) => {
              if (item.moduleName == this.module.form.moduleName) {
                item.description = this.module.form.description;
                return;
              }
            });
          } else {
            // 将模块添加到tsl中
            tslObj.modules.push(Object.assign({}, this.module.form));
          }

          // 保存tsl
          return updateTsl(tslObj)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              this.tsl = tslObj;
              this.activeTabName = this.module.form.moduleName;
              this.closeAddModule();
            })
            .catch((error) => {
              console.log(`保存失败，原因 => ${error}`);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // 取消添加/编辑模块
    closeAddModule() {
      this.module.form = {
        moduleName: null,
        description: null,
      };
      this.module.addDialogVisible = false;
    },
    // 添加模块
    addModule() {
      this.module.isEdit = false;
      this.module.title = this.$t("vlink.tsl.addObjectModule");
      if (
        !this.tsl.modules ||
        (this.tsl.modules && this.tsl.modules.length === 0)
      ) {
        this.module.form.moduleName = "default";
        this.module.form.description = "默认模块";
      }
      this.module.addDialogVisible = true;
    },
    findTsl(setActiveName) {
      this.loading = true;
      findTsl({ productKey: this.currentRow.productKey })
        .then((data) => {
          this.tsl = data;
          // 默认选中第一个模块tab
          if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
            this.activeTabName = setActiveName
              ? setActiveName
              : this.tsl.modules[0].moduleName;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`操作失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.tsl-component {
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .no-data-container {
    font-weight: bold;
    font-size: 16px;
    margin: 20px 0;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .footer-container {
    margin: 40px 0 8px 0;
  }
}
</style>