const JavaScriptJsonDefaultCode = `
//以下为Rlink javaScript脚本模版，您可以基于以下模版进行脚本编写

/**
 * 设备自定义topic
 *
 * 设备上报，下发都可以使用这个
 *
 * 入参：topic  string 设备消息的topic
 *
 * 入参：bytes byte[]数组 不能为空
 *
 * 出参： bytes 不能为空
 */

function customTopic(topic, bytes) {

  var bytes = [];

  return bytes;

}

`; 
export default JavaScriptJsonDefaultCode;
