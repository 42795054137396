<template>
  <div class="product-info-comp" v-loading="loading">
    <!-- 基础信息 -->
    <div class="card-container">
      <div class="header-cont">
        <span class="header-title">
          {{ $t("vlink.product.basicInformation") }}
        </span>
        <span class="header-tips">
          <i class="el-icon-info" />
          {{ $t("vlink.product.basicInfoTips") }}
        </span>
      </div>
      <div class="detail-cont">
        <!-- 产品名称 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.productName") }}
          </div>

          <div class="detail-value">
            <span v-if="productNameEdit" class="desc-edit-input">
              <el-input
                ref="productNameInput"
                maxlength="150"
                v-model="detailInfo.productName"
                @keyup.enter.native="updateProductName"
                size="mini"
              ></el-input>
            </span>
            <span v-else class="desc-edit-input desc-span">
              {{ detailInfo && detailInfo.productName }}
            </span>
            <i
              v-if="!productNameEdit"
              class="el-icon-edit desc-edit-input"
              @click="showProductNameEdit"
            ></i>
            <span v-else style="margin-left: 10px">
              <el-button
                @click="updateProductName"
                type="success"
                size="mini"
                icon="el-icon-check"
                circle
              ></el-button>
              <el-button
                @click="
                  productNameEdit = false;
                  detailInfo.productName = productNameBak;
                "
                size="mini"
                icon="el-icon-close"
                circle
              ></el-button>
            </span>
          </div>
        </div>
        <!-- 节点类型 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.nodeType") }}
          </div>
          <div class="detail-value">
            {{ nodeTypeRender(detailInfo && detailInfo.nodeType) }}
          </div>
        </div>
        <!-- 数据格式 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.dataType") }}
          </div>
          <div class="detail-value">
            {{ dataTypeRender(detailInfo && detailInfo.dataType) }}
          </div>
        </div>
        <!-- 认证方式 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.authType") }}
          </div>
          <div class="detail-value">
            {{ authTypeRender(detailInfo && detailInfo.authType) }}
          </div>
        </div>
        <!-- 协议类型 -->
        <div class="last-line">
          <div class="detail-label">
            {{ $t("vlink.product.protocol") }}
          </div>
          <div class="detail-value">
            {{ detailInfo && $t("vlink.commons." + detailInfo.protocol) }}
          </div>
        </div>
        <!-- 是否共享 -->
        <div class="last-line">
          <div class="detail-label">是否共享</div>
          <div class="detail-value">
            <el-switch
              v-if="detailInfo"
              @change="updateShareState"
              v-model="detailInfo.isShared"
              active-color="#67C23A"
              active-value="Y"
              inactive-value="N"
            >
            </el-switch>
          </div>
        </div>
      </div>
    </div>
    <!-- 证书和时间 -->
    <div class="card-container">
      <div class="header-cont">
        <span class="header-title">
          {{ $t("vlink.product.certificateAndTime") }}
        </span>
        <span class="header-tips">
          <i class="el-icon-info" />
          {{ $t("vlink.product.certificateAndTimeTips") }}
        </span>
      </div>
      <div class="detail-cont">
        <!-- 产品标识码 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.productKey") + " ProductKey" }}
          </div>
          <div class="detail-value">
            {{ detailInfo && detailInfo.productKey }}
          </div>
        </div>
        <!-- 创建时间 -->
        <div>
          <div class="detail-label">
            {{ $t("commons.creationTime") }}
          </div>
          <div class="detail-value">
            {{
              detailInfo &&
              detailInfo.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}
          </div>
        </div>
        <!-- 产品秘钥 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.productSecret") + " ProductSecret" }}
            <span class="copy-container" @click="copyTheSecret">
              <i class="el-icon-document-copy"></i>
              复制证书
            </span>
          </div>
          <div class="detail-value">
            <el-tooltip
              class="item view-tooltips"
              effect="dark"
              :content="$t('vlink.product.seeProductSecret')"
              placement="bottom"
            >
              <span @click="showeSecret = !showeSecret">
                <i
                  v-if="showeSecret"
                  class="iconfont icon-yanjing-1 el-icon-view"
                ></i>
                <i v-else class="iconfont icon-biyan el-icon-view"></i>
              </span>
            </el-tooltip>
            <span
              :style="{
                position: 'relative',
                top: showeSecret ? '0' : '2px',
              }"
            >
              {{ showeSecret ? certInfo.productSecret : "******" }}
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="download-cont" @click="sequenceDownload">
        <i class="el-icon-download"></i>
        下载证书
      </div> -->
    </div>
    <!-- 产品配置 -->
    <div class="card-container">
      <div class="header-cont">
        <span class="header-title">
          {{ $t("vlink.product.productConfig") }}
        </span>
        <span class="header-tips">
          <i class="el-icon-info" />
          {{ $t("vlink.product.productConfigTips") }}
        </span>
      </div>
      <div class="detail-cont">
        <!-- 产品秘钥 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.dynamicRegistration") }}
          </div>
          <div class="detail-value">
            <el-switch
              v-if="detailInfo"
              @change="updateDynamicRegistration"
              v-model="detailInfo.dynamicRegistration"
              :active-text="
                detailInfo.dynamicRegistration === 'ENABLE'
                  ? '已开启'
                  : '已禁用'
              "
              active-color="#67C23A"
              active-value="ENABLE"
              inactive-value="DISABLE"
            >
            </el-switch>
          </div>
        </div>
        <!-- 产品描述 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.description") }}
          </div>
          <div class="detail-value">
            <span v-if="descriptionEdit" class="desc-edit-input">
              <el-input
                ref="descInput"
                maxlength="150"
                v-model="detailInfo.description"
                @keyup.enter.native="updateDesc"
                size="mini"
              ></el-input>
            </span>
            <span v-else class="desc-edit-input desc-span">
              {{
                detailInfo && detailInfo.description
                  ? detailInfo.description
                  : "-"
              }}
            </span>
            <i
              v-if="!descriptionEdit"
              class="el-icon-edit desc-edit-input"
              @click="showDescriptionEdit"
            ></i>
            <span v-else style="margin-left: 10px">
              <el-button
                @click="updateDesc"
                type="success"
                size="mini"
                icon="el-icon-check"
                circle
              ></el-button>
              <el-button
                @click="
                  descriptionEdit = false;
                  detailInfo.description = descriptionBak;
                "
                size="mini"
                icon="el-icon-close"
                circle
              ></el-button>
            </span>
          </div>
        </div>
        <!-- 产品标签 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.label") }}
          </div>
          <div>
            <productTagBind
              v-if="detailInfo && detailInfo.tagList"
              :tagValueList="detailInfo.tagList"
              tagType="product"
              @tagDelete="handleTagDelete"
              @tagAdd="handleTagAdd"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 扩展配置 -->
    <div class="card-container">
      <div class="header-cont config-header">
        <span class="header-cont">
          <span class="header-title"> 扩展信息 </span>
          <span class="header-tips">
            <i class="el-icon-info" />
            用户自由配置的扩展信息
          </span>
        </span>
        <span class="edit-span" @click="jumpToEdit">
          <i class="el-icon-edit"></i>
          编辑
        </span>
      </div>
      <div class="detail-cont" v-if="extendInfoList.length">
        <el-row class="config-row">
          <el-col
            v-for="item in extendInfoList"
            :key="item.label"
            :span="item.layout === 'all' ? 24 : 12"
            style="margin-top: 20px"
          >
            <div class="detail-label">
              {{ item.label }}
            </div>
            <div class="detail-value">
              <span
                :id="item.id"
                class="desc-span"
                :title="item.value"
                v-html="item.value"
              >
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="no-config" v-else>
        <img src="@/assets/images/no_product_config.png" alt="" />
        <div>
          当前产品暂未配置扩展信息，
          <span class="edit-label" @click="jumpToEdit"> 立即编辑 </span>
        </div>
      </div>
    </div>
    <div class="button-container">
      <el-button type="danger" plain @click="deleteProduct">{{
        $t("vlink.product.deleteProduct")
      }}</el-button>
    </div>

    <!-- 显示证书对话框 -->
    <el-dialog :visible.sync="certDialogVisible" width="500px">
      <template slot="title">
        {{ $t("vlink.commons.viewCert") }}
      </template>
      <el-form label-width="120px" label-position="left">
        <el-row>
          <el-form-item label="ProductKey" prop="productKey">
            <span>{{ certInfo.productKey }}</span>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="ProductSecret" prop="productSecret">
            <span>{{ certInfo.productSecret }}</span>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="closeCert">{{
          $t("commons.close")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteProductById,
  findProductDetail,
  viewCert,
  updateDynamicRegistration,
  deleteTagByTagNameAndProductId,
  updateDescriptionByProductId,
  addTagByTagNameAndProductId,
  updateproductShareState,
} from "@/api/ruge/vlink/product/product";
import productTagBind from "./productTag";
import { copy2Clip } from "@/utils/global";
import { dynamicLabelRender } from "@/utils/dynamicUtils.js";

export default {
  name: "productInfoComponent",
  components: {
    productTagBind,
  },
  data() {
    return {
      descriptionBak: null,
      certDialogVisible: false,
      certInfo: {},
      showeSecret: false,
      loading: true,
      descriptionEdit: false,
      productNameEdit: false,
      productNameBak: null,
      extendInfoList: [],
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
    detailInfo: {
      type: Object,
      default: null,
    },
  },
  watch: {
    detailInfo: {
      handler(val) {
        this.loading = false;
        if (JSON.stringify(this.certInfo) === "{}") {
          this.viewCert();
        }
        if (this.descriptionBak === null && val) {
          this.descriptionBak = val.description || "";
        }
        if (this.productNameBak === null && val) {
          this.productNameBak = val.productName || "";
        }
        if (val && val.extendInfoTemplate) {
          this.extendInfoRender(val.extendInfoTemplate);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    extendInfoRender(datas) {
      try {
        const parsedList = JSON.parse(datas);
        this.extendInfoList = parsedList.json.map((item) => {
          return {
            label: item.propsList.filter((single) => single.key === "label")[0]
              .value,
            value: dynamicLabelRender(item),
            layout: item.propsList.filter(
              (single) => single.key === "layout"
            )[0].value,
            id: item.id,
          };
        });
      } catch (error) {
        console.log("扩展信息解析失败：", error);
      }
    },
    jumpToEdit() {
      const { productKey } = this.detailInfo;
      this.$router.push({
        path: "/iot/dynamicForm?layout=hide",
        query: {
          productKey,
        },
      });
    },
    updateShareState(value) {
      this.loading = true;
      updateproductShareState({
        productId: this.detailInfo.productId,
        isShared: value,
      })
        .then((res) => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          this.getDetail();
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteProduct() {
      if (!this.detailInfo.productId) return;
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteProductById({ productId: this.detailInfo.productId }).then(
            () => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.$router.push("/iot/product/list");
            }
          );
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    handleTagAdd(tag, tagValueList) {
      // 已有此标签
      if (tagValueList.indexOf(tag) != -1) {
        this.$message({
          message: this.$t("已有此标签"),
          type: "error",
        });
        return;
      }
      addTagByTagNameAndProductId(this.currentRow.productId, tag)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("添加成功"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(`添加失败，原因 => ${error}`);
        })
        .finally(() => {
          tagValueList.push(tag);
        });
    },
    handleTagDelete(tag, tagValueList) {
      this.$confirm("此操作将删除产品标签,您确定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var productId = this.detailInfo.productId;
          var tagName = tag;
          // 删除产品标签
          deleteTagByTagNameAndProductId(productId, tagName)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("删除成功"),
                type: "success",
              });
              this.detailInfo.tagList = tagValueList.filter(
                (item) => item !== tag
              );
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            })
            .finally(() => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    showDescriptionEdit() {
      this.descriptionEdit = true;
      this.$nextTick(() => {
        this.$refs.descInput.focus();
        this.$refs.descInput.select();
      });
    },
    showProductNameEdit() {
      this.productNameEdit = true;
      this.$nextTick(() => {
        this.$refs.productNameInput.focus();
        this.$refs.productNameInput.select();
      });
    },
    updateDesc() {
      const { productId, description } = this.detailInfo;
      if (!description) {
        this.descriptionEdit = false;
        return;
      }
      updateDescriptionByProductId({ productId, description })
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("更新成功"),
            type: "success",
          });
          this.descriptionEdit = false;
          this.descriptionBak = description;
        })
        .catch((error) => {
          console.log(`删除失败，原因 => ${error}`);
          this.descriptionEdit = false;
        });
    },
    updateProductName() {
      const { productId, productName, description } = this.detailInfo;
      if (!productName) {
        this.productNameEdit = false;
        return;
      }
      updateDescriptionByProductId({ productId, productName, description })
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("更新成功"),
            type: "success",
          });
          this.productNameEdit = false;
          this.productNameBak = productName;
        })
        .catch((error) => {
          console.log(`删除失败，原因 => ${error}`);
          this.productNameEdit = false;
        });
    },
    updateDynamicRegistration() {
      this.loading = true;
      updateDynamicRegistration(this.detailInfo)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          this.getDetail();
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyTheSecret() {
      const tempObj = {
        ProductKey: this.certInfo.productKey,
        ProductSecret: this.certInfo.productSecret,
      };
      copy2Clip(JSON.stringify(tempObj));
    },
    sequenceDownload() {
      const json = `ProductKey: ${this.certInfo.productKey}\nProductSecret: ${this.certInfo.productSecret}`;
      const asemName = "txt";
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(json)
      );
      element.setAttribute("download", asemName);
      element.style.display = "none";
      element.click();
    },
    authTypeRender(type) {
      switch (type) {
        case "SECRETKEY":
          return this.$t("vlink.commons.secretkey");
        case "X509":
          return this.$t("vlink.commons.x509");
        default:
          return "-";
      }
    },
    dataTypeRender(type) {
      switch (type) {
        case "JSON":
          return this.$t("vlink.commons.json");
        case "BINARY":
          return this.$t("vlink.commons.binary");
        default:
          return "-";
      }
    },
    nodeTypeRender(type) {
      switch (type) {
        case "DIRECTDEVICE":
          return this.$t("vlink.commons.directDevice");
        case "GATEWAY":
          return this.$t("vlink.commons.gateway");
        case "SUBDEVICE":
          return this.$t("vlink.commons.subDevice");
        default:
          return "-";
      }
    },
    viewCert() {
      if (!this.detailInfo) return;
      this.loading = true;
      viewCert({ productKey: this.detailInfo.productKey })
        .then((response) => {
          this.certInfo = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`操作失败，原因 => ${error}`);
        });
    },
    closeCert() {
      this.certDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
.product-info-comp {
  .card-container {
    background-color: #f8f8f8;
    padding: 32px 32px 10px;
    margin-bottom: 24px;
    .config-header {
      display: flex;
      justify-content: space-between;
      .edit-span {
        font-weight: 600;
        font-size: 14px;
        color: #2a61ff;
        display: flex;
        align-items: center;
        cursor: pointer;
        .el-icon-edit {
          margin-right: 3px;
        }
      }
    }
    .no-config {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      color: #a7b4c7;
      margin-bottom: 20px;
      .edit-label {
        color: #2a61ff;
        cursor: pointer;
        margin-top: 5px;
      }
    }
    .header-cont {
      display: flex;
      align-items: center;
      .header-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      .header-tips {
        margin-left: 16px;
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #000;
        opacity: 0.7;
        i {
          opacity: inherit;
        }
      }
    }
    .detail-cont {
      margin-bottom: 15px;
      flex-wrap: wrap;
      display: flex;
      & > div {
        width: 50%;
        margin-top: 20px;
      }
      .detail-label {
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 12px;
        font-weight: 300;
        color: #4a4a4a;
        height: 18px;
        line-height: 18px;
        margin-bottom: 4px;
        .copy-container {
          margin: 0 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0486fe;
          cursor: pointer;
          &:hover {
            color: #0f2fd5;
          }
        }
      }
      .detail-value {
        display: flex;
        align-items: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #000000;
        height: 20px;
        line-height: 20px;
        padding-right: 20px;
        .view-tooltips {
          margin-right: 5px;
        }
        .el-icon-view {
          color: #0486fe;
          font-size: 26px;
          cursor: pointer;
        }
        .desc-edit-input + .desc-edit-input {
          margin-left: 10px;
        }
        .desc-span {
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .el-icon-edit {
          height: 18;
          width: 18px;
          cursor: pointer;
        }
      }
      .last-line {
        margin-bottom: 22px;
      }
      .config-row {
        width: 100%;
        margin-top: 0;
      }
    }
    .download-cont {
      margin: 10px 0 22px 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0486fe;
      cursor: pointer;
    }
  }
  .special-card {
    background: #fff;
    box-shadow: 0px 0px 4px 0px #00000016;
  }
  .button-container {
    margin: 40px 0;
  }
  /deep/ .el-switch__label.is-active {
    color: #67c23a;
  }
}
</style>