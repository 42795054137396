const PythonBinaryDefaultCode = `
# 以下为Rlink python产品模版，您可以基于以下模版进行脚本编写
#
# 将设备自定义topic数据转换为json格式数据, 设备上报数据到物联网平台时调用
#
# 入参: topic   字符串，设备上报消息的topic
#
# 入参: bytes  列表元素取值为int类型    不能为空
#
# 出参: bytes

def custom_topic(topic, bytes):
    
    bytes = [];
    
    return bytes;


# 将设备的自定义格式数据转换为Rlink协议的数据，设备上报数据到物联网平台时调用
#
# 入参: bytes 列表，列表元素取值为int类型    不能为空
#
# 出参: json                          不能为空

def bytes_to_json(bytes):
    
    json = {}
    
    return json


# 将Rlink协议的数据转换为设备能识别的格式数据，物联网平台给设备下发数据时调用
#
# 入参: json                                            不能为空
#
# 出参: bytes    列表元素取值为int类型且大小为[0, 255]之间  不能为空

def json_to_bytes(json):
    
    bytes = [];
  
    return bytes;
`; 
export default PythonBinaryDefaultCode;
