<template>
  <div class="edit-params-comp">
    <div class="add-line" v-if="!EditFlag">
      <span @click="EditFlag = true">
        <i class="el-icon-plus"></i>
        {{ titleRender() }}
      </span>
    </div>
    <div :class="paramList.length === 0 ? '' : 'edit-container'" v-else>
      <el-divider class="top-devider" />
      <singleParamForm
        formState="add"
        @close="EditFlag = false"
        @addParamList="addParamList"
      />
      <el-divider v-if="paramList.length === 0" />
    </div>
    <div class="params-list-container" v-show="paramList.length > 0">
      <el-collapse @change="collapseHandleChange">
        <el-collapse-item
          v-for="(item, index) in paramList"
          :key="index"
          :title="
            item.description
              ? item.paramName + ' - ' + item.description
              : item.paramName
          "
          :name="item.paramName"
        >
          <singleParamForm
            :formData="item"
            formState="view"
            @updateParamList="updateParamList"
            @deleteParam="deleteParam"
          />
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import singleParamForm from "./singleParamForm";
import { deepClone } from "@/utils/utils.js";
export default {
  components: {
    singleParamForm,
  },
  props: {
    dataset: Array,
    paramType: String,
  },
  watch: {
    dataset: {
      immediate: true,
      handler(val) {
        if (!val || val.length === 0) return;
        this.paramList = JSON.parse(JSON.stringify(val));
      },
    },
  },
  data() {
    return {
      EditFlag: false,
      // 参数列表
      paramList: [],
    };
  },
  methods: {
    titleRender() {
      switch (this.paramType) {
        case "response":
          return "添加响应参数";
        case "inputData":
          return "添加上报参数";
        default:
          return "添加下发参数";
      }
    },
    getParamList() {
      return deepClone(this.paramList);
    },
    collapseHandleChange(val) {
      console.log(val);
    },
    deleteParam(paramName) {
      this.paramList = this.paramList.filter(
        (item) => item.paramName !== paramName
      );
      console.log("this.paramList", this.paramList);
      this.$message.success("参数删除成功");
    },
    addParamList(param) {
      // 名称重复校验
      const repeatNum = this.paramList.filter(
        (item) => item.paramName === param.paramName
      ).length;
      if (repeatNum > 0) {
        this.$message.warning("参数名称不能重复");
        return;
      }
      this.paramList.push(param);
      this.EditFlag = false;
    },
    updateParamList(paramForm) {
      this.paramList.forEach((item, index) => {
        if (item.paramName === paramForm.paramName) {
          this.paramList.splice(index, 1, paramForm);
        }
      });
      this.$message.success("参数更新成功");
    },
  },
};
</script>

<style scoped lang="less">
.edit-params-comp {
  margin-top: 5px;
  .add-line {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0486fe;
    line-height: 20px;
    & > span {
      cursor: pointer;
    }
  }
  .edit-container {
    margin-bottom: 35px;
  }
  .params-list-container {
    margin-top: 15px;
  }
  .top-devider {
    margin: 0 0 24px;
  }
  ::v-deep .el-collapse-item__header {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
    &:hover {
      color: #0486fe !important;
    }
  }
  ::v-deep .el-collapse-item__header.is-active {
    color: #0486fe !important;
  }
  ::v-deep .el-collapse-item__header {
    height: 54px;
    line-height: 54px;
  }
}
</style>