<template>
  <div>
    <el-form
      label-position="top"
      ref="opcuaForm"
      :rules="opcuaRule"
      :model="opcuaForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.tsl.originalDataType')"
            prop="originalDataType"
          >
            <el-select
              v-model="opcuaForm.originalDataType"
              clearable
              :style="{ width: '100%' }"
            >
              <el-option
                :label="$t('vlink.originalDataType.int16')"
                value="int16"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.uint16')"
                value="uint16"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.int32')"
                value="int32"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.uint32')"
                value="uint32"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.int64')"
                value="int64"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.uint64')"
                value="uint64"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.float')"
                value="float"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.double')"
                value="double"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.enum')"
                value="enum"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.bool')"
                value="bool"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.date')"
                value="date"
              ></el-option>
              <el-option
                :label="$t('vlink.originalDataType.text')"
                value="text"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.displayName')" prop="displayName">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.displayName"
              maxlength="50"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.scaling')" prop="scaling">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.scaling"
              maxlength="20"
              :show-word-limit="true"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.precision')" prop="precision">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.precision"
              maxlength="20"
              :show-word-limit="true"
              clearable
            ></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>

      <!-- 将上报方式移到子设备配置中 -->
      <!-- <el-row>
                <el-col>
                    <el-form-item :label="$t('vlink.tsl.trigger')" prop="trigger">
                        <el-radio-group :disabled="!editable" v-model="opcuaForm.trigger">
                            <el-radio :label="1">{{$t("vlink.tsl.timingAppear")}}</el-radio>
                            <el-radio :label="2">{{$t("vlink.tsl.changeAppear")}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row> -->

      <!-- <el-row v-if="editable">
                <el-col :span="24" align="left">
                    <el-button type="primary" @click="saveProperty">{{$t('commons.save') }}</el-button>
                    <el-button @click="cancelEditProperty(null)">{{$t('commons.cancel') }}</el-button>
                </el-col>
            </el-row> -->
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    property: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    property: {
      immediate: true,
      handler: function () {
        this.initProperty();
      },
    },
  },
  data() {
    return {
      opcuaForm: {
        originalDataType: null,
        displayName: null,
        scaling: null,
        precision: null,
        trigger: "2",
        reportCycle: 5000,
      },
      opcuaRule: {
        originalDataType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        displayName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        trigger: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        reportCycle: [
          {
            required: false,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (value < 1000) {
                callback(new Error("上报周期最小1000毫秒"));
                return;
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    initProperty() {
      if (this.property) {
        if (this.property.extendConfig) {
          let customize = JSON.parse(this.property.extendConfig);
          this.opcuaForm.originalDataType = customize.originalDataType;
          this.opcuaForm.displayName = customize.displayName;

          this.opcuaForm.scaling = customize.scaling;
          this.opcuaForm.precision = customize.precision;
          this.opcuaForm.trigger = customize.trigger ? customize.trigger : "2";
          this.opcuaForm.reportCycle = customize.reportCycle;
          // if(customize.trigger){
          //     this.opcuaForm.trigger = customize.trigger;
          // }else{
          //     this.opcuaForm.trigger = 1;
          // }
        }
      }
    },
    saveProperty() {
      this.$refs.opcuaForm.validate((v) => {
        if (v) {
          let param = this.buildParam();
          // 回写数据
          this.$emit("cancelEditProperty", param);
        }
      });
    },
    buildParam() {
      let extendConfig = JSON.stringify({
        originalDataType: this.opcuaForm.originalDataType,
        displayName: this.opcuaForm.displayName,
        scaling: this.opcuaForm.scaling,
        trigger: this.opcuaForm.trigger,
        reportCycle: this.opcuaForm.reportCycle,
      });
      return extendConfig;
    },
    cancelEditProperty() {
      this.saveProperty();
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 18px;
}
</style>