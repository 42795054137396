<template>
  <div class="ota-component">
    <div class="buttons-container">
      <div>
        <el-button icon="el-icon-plus" type="primary" @click="createOta">
          {{ $t("commons.add") }}
        </el-button>

        <el-tooltip
          class="item"
          effect="dark"
          :disabled="ota.multipleSelection.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="right"
        >
          <el-button
            type="danger"
            :class="ota.multipleSelection.length === 0 && 'not-allowed-button'"
            icon="el-icon-delete"
            @click="batchDelete"
            plain
            >{{ $t("commons.delete") }}
          </el-button>
        </el-tooltip>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </div>
      <div
        class="header-filter-button"
        @click="filterLineShow = !filterLineShow"
        :class="filterLineShow ? 'filter-button-open' : 'filter-button-close'"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="filterLineShow"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 添加OTA固件包对话框 -->
    <el-dialog
      :visible.sync="ota.addDialogVisible"
      :before-close="cancelOtaSave"
      width="35%"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ ota.title }}
      </template>
      <el-form label-position="top" :model="ota.form" ref="otaForm">
        <el-form-item
          :label="$t('vlink.product.firmwareVersion')"
          prop="firmwareVersion"
        >
          <el-input
            v-model="ota.form.version"
            maxlength="32"
            :show-word-limit="true"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('vlink.product.programmingLanguage')"
          prop="programmingLanguage"
        >
          <el-input
            v-model="ota.form.programmingLanguage"
            maxlength="32"
            :show-word-limit="true"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('vlink.product.OTADescription')"
          prop="description"
        >
          <el-input
            v-model="ota.form.description"
            maxlength="32"
            :show-word-limit="true"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-upload
          class="upload-demo"
          ref="upload"
          drag
          :with-credentials="true"
          :action="uploadUrl"
          :on-success="uploadSuccess"
          :on-exceed="onExceed"
          :limit="1"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <el-button type="primary" plain @click="download"
          >{{ ota.form.fileName }}<i class="el-icon-download el-icon--right"></i
        ></el-button>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelOtaSave">{{ $t("commons.cancel") }}</el-button>
        <el-button type="primary" @click="handleOtaSave">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  createProductOta,
  findOtaPage,
  updateProductOta,
  deleteOtaById,
  updateEnableStatus,
  batchDeleteProductOta,
  changeIndex4Ota,
} from "@/api/ruge/vlink/product/product";
import { deepClone } from "@/utils/utils.js";
import { envInfo } from "@/constants/envInfo";
import { download } from "@/utils/download";
export default {
  components: {
    finalTable,
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      filterLineShow: true,
      filterObj: {
        current: 1,
        total: 0,
        rowCount: 10,
        programmingLanguage: "",
        version: "",
        description: "",
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "50",
        },
        header: [
          { prop: "programmingLanguage", label: "编程语言", width: "" },
          { prop: "version", label: "固件版本", width: "" },
          { prop: "description", label: "描述", width: "" },
          { prop: "status", label: "启用状态", width: "200" },
          { prop: "operation", label: "操作", width: "165" },
        ],
        tableData: [],
        searchLineConfig: {
          programmingLanguage: {
            type: "input",
            label: "编程语言",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入编程语言",
            prefixIcon: "el-icon-search",
          },
          version: {
            type: "input",
            label: "固件版本",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入固件版本",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          status: {
            type: "selector",
            label: "启用状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择启用状态",
            optionList: [
              {
                label: "启用",
                value: "ENABLE",
              },
              {
                label: "禁用",
                value: "DISABLE",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        detailConfig: {
          status: {
            type: "switch",
            actionType: "switchEvent",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "toTop",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "iconfont icon-zhiding",
                tooltips: "置顶",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          programmingLanguage: {
            inline: true,
            value: "",
          },
          version: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          status: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      ota: {
        sortable: undefined,
        otaConfigDialogVisible: false,
        title: null,
        total: 0,
        pageSize: 10,
        currentPage: 1,
        list: null,
        listLoading: false,
        listQuery: {
          otaId: null,
          productKey: this.currentRow.productKey,
          version: null,
          programmingLanguage: null,
          fileId: null,
          fileName: null,
          status: null,
          description: null,
          otaIndex: null,
        },
        multipleSelection: [],
        addDialogVisible: false,
        createLoading: false,
        form: {
          otaId: null,
          productKey: this.currentRow.productKey,
          version: null,
          programmingLanguage: null,
          fileId: null,
          fileName: null,
          status: null,
          description: null,
          otaIndex: null,
        },
        formRules: {},
        // 下载
      },
      downloadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=",
    };
  },
  watch: {
    // currentRow: {
    //   handler(val) {
    //     this.getOtaPage();
    //   },
    //   immediate: true,
    // },
    filterLineShow: {
      handler(val) {
        this.$refs.finalTableRef.filterLineToggle(val);
      },
    },
    loading: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  computed: {
    uploadUrl() {
      let rootPath;
      let uploadRoot;
      if (this.excelType) {
        rootPath = this.rootPath;
        uploadRoot = "/param/excel/upload?ulType=";
      } else {
        uploadRoot = "/param/archive/upload?ulType=";
        rootPath = this.rootPath ? this.rootPath : envInfo.bgApp.archivePath;
      }
      let url =
        rootPath + uploadRoot + (this.ulType ? this.ulType : "DefaultUpload");
      url = this.excelType ? url + "&excelType=" + this.excelType : url;
      return url;
    },
    otaId() {
      return this.currentRow.otaId;
    },
  },
  created() {
    console.log("created");
    this.getOtaPage();
  },
  methods: {
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.getOtaPage();
    },
    download() {
      console.log("this.downloadPath", this.downloadPath);
      const url = this.downloadPath + this.ota.form.fileId;
      const fileName = this.ota.form.fileName;
      download(url, fileName);
    },
    uploadSuccess(response, file, fileList) {
      //this.uploadModel = false
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });

      // 设置fileId和fileName
      this.ota.form.fileId = response[0].fileId;
      this.ota.form.fileName = response[0].displayName;

      this.$refs.upload.clearFiles();

      this.findOtaPage();
    },
    onExceed(file, fileList) {
      this.$message.error("只能上传一个文件，请先删除已有文件!");
    },
    handleOtaSave() {
      // pk
      this.ota.form.productKey = this.currentRow.productKey;

      var totalCount = this.dataset.pageVO.total;

      this.$refs.otaForm.validate((v) => {
        if (v) {
          // 编辑
          if (this.ota.form.otaId) {
            updateProductOta(this.ota.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelOtaSave();
                this.findOtaPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            // 新增
            // 索引加1
            this.ota.form.otaIndex = totalCount + 1 - 1;
            // 新增默认启用
            this.ota.form.status = "ENABLE";
            createProductOta(this.ota.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelOtaSave();
                this.findOtaPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    editOta(row) {
      this.ota.title = this.$t("vlink.product.OTADetail");
      this.ota.form.otaId = row.otaId;
      this.ota.form.productKey = row.productKey;
      this.ota.form.version = row.version;
      this.ota.form.programmingLanguage = row.programmingLanguage;
      this.ota.form.fileId = row.fileId;
      this.ota.form.fileName = row.fileName;
      this.ota.form.status = row.status;
      this.ota.form.description = row.description;
      this.ota.form.otaIndex = row.otaIndex;
      this.ota.addDialogVisible = true;
    },
    //置顶
    topOta(row) {
      row.toIndex = 0;
      changeIndex4Ota(row)
        .then(() => {
          // 成功提示
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
          // 刷新列表
          this.getOtaPage();
        })
        .catch((error) => {
          // 更新失败还原顺序
          this.ota.listLoading = false;
          console.log(`更改顺序失败，原因 => ${error}`);
        });
    },
    deleteOta({ otaId }) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteOtaById({ otaId: otaId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findOtaPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    updateEnableStatus(row) {
      this.loading = true;
      updateEnableStatus(row)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findOtaPage();
        })
        .catch((error) => {
          this.findOtaPage();
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.filterObj = { ...this.filterObj, ...datas.params };
        this.findOtaPage();
      } else if (datas.type === "paginationChange") {
        this.filterObj.current = datas.params.current.page;
        this.filterObj.rowCount = datas.params.current.limit;
        this.getProductPage();
      } else if (datas.type === "updateSelectionList") {
        this.ota.multipleSelection = datas.list.map((item) => item.otaId);
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editOta(datas.row);
            break;
          case "toTop":
            this.topOta(datas.row);
            break;
          case "delete":
            this.deleteOta(datas.row);
            break;
        }
      }
    },
    batchDelete() {
      if (this.ota.multipleSelection.length < 1) return;
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          batchDeleteProductOta({
            productOtaIdList: this.ota.multipleSelection,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findOtaPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    createOta() {
      this.ota.title = this.$t("vlink.product.addOta");
      this.ota.form.otaId = null;
      this.ota.form.productKey = null;
      this.ota.form.version = null;
      this.ota.form.programmingLanguage = null;
      this.ota.form.fileId = null;
      this.ota.form.fileName = null;
      this.ota.form.status = null;
      this.ota.form.description = null;
      this.ota.form.otaIndex = null;
      this.ota.addDialogVisible = true;
    },
    cancelOtaSave() {
      this.ota.addDialogVisible = false;
    },
    findOtaPage() {
      this.ota.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.getOtaPage();
    },
    getOtaPage() {
      this.loading = true;
      const params = { ...this.filterObj };
      findOtaPage(params)
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scopde lang="less">
.ota-component {
  .buttons-container {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>