var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ruge-drawer-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top", "label-width": "80px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("vlink.tsl.tslJson") } },
            [
              _c("ace-editor", {
                key: _vm.tsl.productKey,
                staticStyle: { border: "1px solid #ebecec" },
                attrs: { lang: "json", theme: "chrome", height: "562px" },
                on: { init: _vm.editorInit },
                model: {
                  value: _vm.tslJson,
                  callback: function ($$v) {
                    _vm.tslJson = $$v
                  },
                  expression: "tslJson",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.closeInfoDialog },
                },
                [_vm._v(_vm._s(_vm.$t("vlink.commons.close")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }