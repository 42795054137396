<template>
  <div class="edit-service-comp">
    <el-form
      class="scroll-view"
      v-loading="loading"
      label-position="top"
      :model="service.form"
      ref="serviceForm"
      :rules="service.formRules"
    >
      <el-row :gutter="48">
        <!-- 服务名称 -->
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.serviceName')" prop="serviceName">
            <el-input
              v-model="service.form.serviceName"
              :readonly="currentService.isEdit"
              maxlength="32"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 描述 -->
        <el-col :span="12">
          <el-form-item :label="$t('vlink.tsl.description')" prop="description">
            <el-input
              v-model="service.form.description"
              maxlength="100"
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 上报参数 -->
      <el-form-item :label="$t('vlink.tsl.inputData')">
        <EditParams ref="inputParamsRef" :dataset="service.form.inputData" />
      </el-form-item>

      <!-- 响应参数 -->
      <el-form-item :label="$t('vlink.tsl.responseParam')">
        <EditParams
          ref="responseParamsRef"
          paramType="response"
          :dataset="service.form.outputData"
        />
      </el-form-item>

      <!-- 扩展描述 -->
      <el-form-item :label="$t('vlink.tsl.extendConfig')" prop="extendConfig">
        <div class="extend-config-line">
          <el-switch
            v-model="service.form.needConfig"
            active-color="#13ce66"
            inactive-color="#ccc"
          >
          </el-switch>
          <span
            :class="
              service.form.needConfig
                ? 'config-title-active'
                : 'config-title-default'
            "
          >
            {{ service.form.needConfig ? "已开启" : "未开启" }}
          </span>
          <span class="config-tips">
            <i class="el-icon-info"></i>
            关闭时，将不保存扩展配置。
          </span>
        </div>
        <ace-editor
          v-if="service.form.needConfig"
          v-model="service.form.extendConfig"
          @init="editorInit"
          lang="json"
          theme="chrome"
          style="border: 1px solid #ebecec"
          height="300px"
        ></ace-editor>
      </el-form-item>
    </el-form>
    <div slot="footer" align="right" style="margin-bottom: 10px">
      <el-button @click="closeSaveService(false)">{{
        $t("commons.cancel")
      }}</el-button>
      <el-button :loading="loading" type="primary" @click="handleSaveService">{{
        currentService.isEdit ? $t("commons.save") : $t("commons.add")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";
import EditParams from "./editParams";
import { updateTsl } from "@/api/ruge/vlink/product/product";
export default {
  name: "editServiceComponent",
  components: {
    AceEditor,
    EditParams,
  },
  props: {
    tsl: {
      type: Object,
      required: true,
    },
    currentService: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentService: {
      immediate: true,
      handler: function () {
        // JSON.parse(JSON.stringify(obj)) 深拷贝，防止提交失败也会修改父组件数据现象
        this.tslObj = JSON.parse(
          JSON.stringify(
            Object.assign(
              { productKey: this.currentService.productKey },
              this.tsl
            )
          )
        );
        this.initServiceForm();
      },
    },
  },
  data() {
    return {
      loading: false,
      tslObj: {},
      service: {
        form: {},
        formRules: {
          serviceName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              pattern: /^[A-Za-z0-9-]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          description: [
            {
              // pattern: /^[A-Za-z0-9-_@()\u4e00-\u9fa5]+$/,
              message: this.$t("vlink.product.productNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  methods: {
    closeSaveService(moduleName) {
      this.$emit("closeSaveService", moduleName);
    },
    editorInit(thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(false);
    },
    initServiceForm() {
      if (this.currentService.isEdit) {
        // 如果有extendConfig，switch打开
        this.currentService.form.needConfig = this.currentService.form
          .extendConfig
          ? true
          : false;
        this.currentService.form.extendConfig = this.currentService.form
          .extendConfig
          ? JSON.parse(this.currentService.form.extendConfig)
          : null;
        this.service.form = Object.assign({}, this.currentService.form);
      } else {
        this.service.form = {
          serviceName: null,
          description: null,
          required: false,
          inputData: [],
          outputData: [],
          extendConfig: null,
          needConfig: false,
        };
      }
    },
    handleSaveService() {
      this.$refs.serviceForm.validate((v) => {
        if (v) {
          if (
            this.tslObj &&
            this.tslObj.modules &&
            this.tslObj.modules.length > 0
          ) {
            // 获取当前模块
            let currModule = this.tslObj.modules.find((item) => {
              return item.moduleName == this.currentService.moduleName;
            });
            // 找不到对应的模块
            if (!currModule) {
              this.$message({
                message: this.$t("vlink.tsl.tips3"),
                type: "warning",
              });
              return;
            }
            const currentinputList = this.$refs.inputParamsRef.getParamList();
            this.service.form.inputData = currentinputList;
            const currentResponsetList =
              this.$refs.responseParamsRef.getParamList();
            this.service.form.outputData = currentResponsetList;
            this.service.form.extendConfig = this.service.form.needConfig
              ? JSON.stringify(this.service.form.extendConfig)
              : null;
            // 编辑
            if (this.currentService.isEdit) {
              currModule.services = currModule.services.map((item) => {
                return item.serviceName == this.service.form.serviceName
                  ? Object.assign({}, this.service.form)
                  : item;
              });
            } else {
              // 添加时，serviceName唯一性校验
              if (currModule.services && currModule.services.length > 0) {
                let obj = currModule.services.find((item) => {
                  return item.serviceName == this.service.form.serviceName;
                });
                if (obj) {
                  this.$message({
                    message: this.$t("vlink.tsl.tips4"),
                    type: "warning",
                  });
                  return;
                }
              }
              if (!currModule.services) {
                currModule.services = [];
              }
              // 将服务添加到模块中
              currModule.services.push(Object.assign({}, this.service.form));
            }

            this.loading = true;
            this.tslObj.modules = this.tslObj.modules.map((item) => {
              return item.moduleName == currModule.moduleName
                ? currModule
                : item;
            });
            // 保存tsl
            updateTsl(this.tslObj)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.closeSaveService(currModule.moduleName);
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.edit-service-comp {
}
</style>