var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "product-info-comp",
    },
    [
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "header-cont" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.basicInformation")) +
                "\n      "
            ),
          ]),
          _c("span", { staticClass: "header-tips" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.basicInfoTips")) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-cont" }, [
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.productName")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm.productNameEdit
                ? _c(
                    "span",
                    { staticClass: "desc-edit-input" },
                    [
                      _c("el-input", {
                        ref: "productNameInput",
                        attrs: { maxlength: "150", size: "mini" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.updateProductName.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.detailInfo.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailInfo, "productName", $$v)
                          },
                          expression: "detailInfo.productName",
                        },
                      }),
                    ],
                    1
                  )
                : _c("span", { staticClass: "desc-edit-input desc-span" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.detailInfo && _vm.detailInfo.productName) +
                        "\n          "
                    ),
                  ]),
              !_vm.productNameEdit
                ? _c("i", {
                    staticClass: "el-icon-edit desc-edit-input",
                    on: { click: _vm.showProductNameEdit },
                  })
                : _c(
                    "span",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "success",
                          size: "mini",
                          icon: "el-icon-check",
                          circle: "",
                        },
                        on: { click: _vm.updateProductName },
                      }),
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          icon: "el-icon-close",
                          circle: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.productNameEdit = false
                            _vm.detailInfo.productName = _vm.productNameBak
                          },
                        },
                      }),
                    ],
                    1
                  ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.nodeType")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.nodeTypeRender(
                      _vm.detailInfo && _vm.detailInfo.nodeType
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.dataType")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.dataTypeRender(
                      _vm.detailInfo && _vm.detailInfo.dataType
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.authType")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.authTypeRender(
                      _vm.detailInfo && _vm.detailInfo.authType
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", { staticClass: "last-line" }, [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.protocol")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.detailInfo &&
                      _vm.$t("vlink.commons." + _vm.detailInfo.protocol)
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", { staticClass: "last-line" }, [
            _c("div", { staticClass: "detail-label" }, [_vm._v("是否共享")]),
            _c(
              "div",
              { staticClass: "detail-value" },
              [
                _vm.detailInfo
                  ? _c("el-switch", {
                      attrs: {
                        "active-color": "#67C23A",
                        "active-value": "Y",
                        "inactive-value": "N",
                      },
                      on: { change: _vm.updateShareState },
                      model: {
                        value: _vm.detailInfo.isShared,
                        callback: function ($$v) {
                          _vm.$set(_vm.detailInfo, "isShared", $$v)
                        },
                        expression: "detailInfo.isShared",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "header-cont" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.certificateAndTime")) +
                "\n      "
            ),
          ]),
          _c("span", { staticClass: "header-tips" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.certificateAndTimeTips")) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-cont" }, [
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.productKey") + " ProductKey") +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.detailInfo && _vm.detailInfo.productKey) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("commons.creationTime")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.detailInfo && _vm.detailInfo.creationDate,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("vlink.product.productSecret") + " ProductSecret"
                  ) +
                  "\n          "
              ),
              _c(
                "span",
                {
                  staticClass: "copy-container",
                  on: { click: _vm.copyTheSecret },
                },
                [
                  _c("i", { staticClass: "el-icon-document-copy" }),
                  _vm._v("\n            复制证书\n          "),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "detail-value" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item view-tooltips",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("vlink.product.seeProductSecret"),
                      placement: "bottom",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            _vm.showeSecret = !_vm.showeSecret
                          },
                        },
                      },
                      [
                        _vm.showeSecret
                          ? _c("i", {
                              staticClass:
                                "iconfont icon-yanjing-1 el-icon-view",
                            })
                          : _c("i", {
                              staticClass: "iconfont icon-biyan el-icon-view",
                            }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    style: {
                      position: "relative",
                      top: _vm.showeSecret ? "0" : "2px",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.showeSecret
                            ? _vm.certInfo.productSecret
                            : "******"
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "header-cont" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.productConfig")) +
                "\n      "
            ),
          ]),
          _c("span", { staticClass: "header-tips" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.productConfigTips")) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-cont" }, [
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.dynamicRegistration")) +
                  "\n        "
              ),
            ]),
            _c(
              "div",
              { staticClass: "detail-value" },
              [
                _vm.detailInfo
                  ? _c("el-switch", {
                      attrs: {
                        "active-text":
                          _vm.detailInfo.dynamicRegistration === "ENABLE"
                            ? "已开启"
                            : "已禁用",
                        "active-color": "#67C23A",
                        "active-value": "ENABLE",
                        "inactive-value": "DISABLE",
                      },
                      on: { change: _vm.updateDynamicRegistration },
                      model: {
                        value: _vm.detailInfo.dynamicRegistration,
                        callback: function ($$v) {
                          _vm.$set(_vm.detailInfo, "dynamicRegistration", $$v)
                        },
                        expression: "detailInfo.dynamicRegistration",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.description")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm.descriptionEdit
                ? _c(
                    "span",
                    { staticClass: "desc-edit-input" },
                    [
                      _c("el-input", {
                        ref: "descInput",
                        attrs: { maxlength: "150", size: "mini" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.updateDesc.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.detailInfo.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailInfo, "description", $$v)
                          },
                          expression: "detailInfo.description",
                        },
                      }),
                    ],
                    1
                  )
                : _c("span", { staticClass: "desc-edit-input desc-span" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.detailInfo && _vm.detailInfo.description
                            ? _vm.detailInfo.description
                            : "-"
                        ) +
                        "\n          "
                    ),
                  ]),
              !_vm.descriptionEdit
                ? _c("i", {
                    staticClass: "el-icon-edit desc-edit-input",
                    on: { click: _vm.showDescriptionEdit },
                  })
                : _c(
                    "span",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "success",
                          size: "mini",
                          icon: "el-icon-check",
                          circle: "",
                        },
                        on: { click: _vm.updateDesc },
                      }),
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          icon: "el-icon-close",
                          circle: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.descriptionEdit = false
                            _vm.detailInfo.description = _vm.descriptionBak
                          },
                        },
                      }),
                    ],
                    1
                  ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.label")) +
                  "\n        "
              ),
            ]),
            _c(
              "div",
              [
                _vm.detailInfo && _vm.detailInfo.tagList
                  ? _c("productTagBind", {
                      attrs: {
                        tagValueList: _vm.detailInfo.tagList,
                        tagType: "product",
                      },
                      on: {
                        tagDelete: _vm.handleTagDelete,
                        tagAdd: _vm.handleTagAdd,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "header-cont config-header" }, [
          _vm._m(0),
          _c(
            "span",
            { staticClass: "edit-span", on: { click: _vm.jumpToEdit } },
            [
              _c("i", { staticClass: "el-icon-edit" }),
              _vm._v("\n        编辑\n      "),
            ]
          ),
        ]),
        _vm.extendInfoList.length
          ? _c(
              "div",
              { staticClass: "detail-cont" },
              [
                _c(
                  "el-row",
                  { staticClass: "config-row" },
                  _vm._l(_vm.extendInfoList, function (item) {
                    return _c(
                      "el-col",
                      {
                        key: item.label,
                        staticStyle: { "margin-top": "20px" },
                        attrs: { span: item.layout === "all" ? 24 : 12 },
                      },
                      [
                        _c("div", { staticClass: "detail-label" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "detail-value" }, [
                          _c("span", {
                            staticClass: "desc-span",
                            attrs: { id: item.id, title: item.value },
                            domProps: { innerHTML: _vm._s(item.value) },
                          }),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _c("div", { staticClass: "no-config" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/no_product_config.png"),
                  alt: "",
                },
              }),
              _c("div", [
                _vm._v("\n        当前产品暂未配置扩展信息，\n        "),
                _c(
                  "span",
                  { staticClass: "edit-label", on: { click: _vm.jumpToEdit } },
                  [_vm._v(" 立即编辑 ")]
                ),
              ]),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", plain: "" },
              on: { click: _vm.deleteProduct },
            },
            [_vm._v(_vm._s(_vm.$t("vlink.product.deleteProduct")))]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.certDialogVisible, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.certDialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("vlink.commons.viewCert")) + "\n    "
            ),
          ]),
          _c(
            "el-form",
            { attrs: { "label-width": "120px", "label-position": "left" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "ProductKey", prop: "productKey" } },
                    [_c("span", [_vm._v(_vm._s(_vm.certInfo.productKey))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "ProductSecret", prop: "productSecret" },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.certInfo.productSecret))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeCert } },
                [_vm._v(_vm._s(_vm.$t("commons.close")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "header-cont" }, [
      _c("span", { staticClass: "header-title" }, [_vm._v(" 扩展信息 ")]),
      _c("span", { staticClass: "header-tips" }, [
        _c("i", { staticClass: "el-icon-info" }),
        _vm._v("\n          用户自由配置的扩展信息\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }