<template>
  <div id="contrast" class="script-contrast-comp">
    <el-row>
      <el-col :span="12">
        <div class="draft-title-line">
          <span>
            {{ $t("vlink.commons.draft") }}
          </span>
        </div>
        <ace-editor
          v-model="scriptCode"
          @init="editorInit"
          :lang="this.language"
          theme="chrome"
          style="border: 1px solid #ebecec"
          height="60vh"
        ></ace-editor>
      </el-col>
      <el-col :span="12">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('vlink.scripting.draftScript')" name="first">
            <ace-editor
              v-if="productKey"
              :key="productKey"
              v-model="draftScript"
              @init="editorInit"
              :lang="this.draftLanguage"
              theme="chrome"
              style="border: 1px solid #ebecec"
              height="60vh"
            ></ace-editor>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('vlink.scripting.publishedScript')"
            name="second"
          >
            <ace-editor
              v-if="productKey"
              :key="productKey"
              v-model="publishedScript"
              @init="editorInit"
              :lang="this.publishedLanguage"
              theme="chrome"
              style="border: 1px solid #ebecec"
              height="60vh"
            ></ace-editor>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";
import { findProductKeyByScript } from "@/api/ruge/vlink/product/product";
export default {
  components: { AceEditor },
  data() {
    return {
      draftScript: "",
      publishedScript: "",
      draftLanguage: null,
      publishedLanguage: null,
      activeName: "first",
      draft: "first",
    };
  },
  props: {
    productKey: {
      type: String,
      default: null,
    },
    scriptCode: {
      type: String,
      default: null,
    },
    language: {
      type: String,
      default: null,
    },
  },
  created() {
    this.findProductKeyByScript();
  },
  methods: {
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/mode/python");
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    async findProductKeyByScript() {
      this.loading = true;
      findProductKeyByScript(this.productKey)
        .then((res) => {
          //通过查询得到，保存脚本和发布脚本，更改脚本内容
          if (res[0].scriptContent != null) {
            if (res[0].scriptStatus === "draft") {
              this.draftLanguage = res[0].scriptLanguage.toLowerCase(); // 修改编辑器脚本语言
              this.draftScript = res[0].scriptContent;
            } else {
              this.publishedLanguage = res[0].scriptLanguage.toLowerCase(); // 修改编辑器脚本语言
              this.publishedScript = res[0].scriptContent;
            }
          }
          //code1 运行脚本
          if (res[1].scriptContent != null) {
            if (res[1].scriptStatus === "published") {
              this.publishedLanguage = res[1].scriptLanguage.toLowerCase(); // 修改编辑器脚本语言
              this.publishedScript = res[1].scriptContent;
            } else {
              this.draftLanguage = res[0].scriptLanguage.toLowerCase(); // 修改编辑器脚本语言
              this.draftScript = res[1].scriptContent;
            }
          }
          console.log("this", this.language);
        })
        .catch((err) => {
          console.log("错误信息：", err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.script-contrast-comp {
  .draft-title-line {
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    span {
      display: inline-block;
      border-bottom: 2px solid #409eff;
      color: #409eff;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
