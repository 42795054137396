var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ace-editor", {
        key: "customizeJson",
        staticStyle: { border: "1px solid #ebecec" },
        attrs: { lang: "json", theme: "chrome", height: "320" },
        on: { init: _vm.editorInit },
        model: {
          value: _vm.customizeForm.customizeJson,
          callback: function ($$v) {
            _vm.$set(_vm.customizeForm, "customizeJson", $$v)
          },
          expression: "customizeForm.customizeJson",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }