const JavaScriptBinaryDefaultCode = `

//以下为Rlink javaScript脚本模版，您可以基于以下模版进行脚本编写


/**
 * 设备自定义topic
 *
 * 设备上报，下发都可以使用这个
 *
 * 入参：topic  string 设备消息的topic
 *
 * 入参：bytes byte[]数组 不能为空
 *
 * 出参： bytes 不能为空
 */

function customTopic(topic, bytes) {

  var bytes = [];

  return bytes;
}



/**
 *
 * 将设备的格式数据转换为Rlink JSON数据，设备上报数据到物联网平台时调用
 *
 * 入参：bytes byte[]数组 不能为空
 *
 * 出参: json Rlink JSON 不能为空
 *
 * 输入调试：输入可以传16进制，只需勾选上方hex。返回结果后可以使用下方JSON格式化按钮进行JSON格式化
 * 
 * 如果返回数据是’ discard ’ ，将会丢弃此数据
 */

function bytesToJson(bytes) {

  var json = {};

  return json;

}



/**
 * 将Rlink JSON数据转换为设备能识别的格式数据，物联网平台给设备下发数据时调用
 *
 * 入参：json  Rlink JSON 不能为空
 *
 * 出参：bytes byte[]数组   不能为空
 *
 * 输入调试：运行结果返回的是16进制数据，根据上方下拉框选择要转换的数据格式，可以对数据进行JSON格式化
 *
 */

function jsonToBytes(json) {

  var bytes = [];

  return bytes;

}
`; 
export default JavaScriptBinaryDefaultCode;
