var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "propertyForm",
          staticClass: "scroll-view",
          attrs: {
            "label-position": "top",
            model: _vm.property.form,
            rules: _vm.property.formRules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.propertyName"),
                        prop: "propertyName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: _vm.currentProperty.isEdit,
                          maxlength: "32",
                          "show-word-limit": true,
                          clearable: "",
                          placeholder: _vm.$t("commons.pleaseInput"),
                        },
                        model: {
                          value: _vm.property.form.propertyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.property.form, "propertyName", $$v)
                          },
                          expression: "property.form.propertyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.dataType"),
                        prop: "dataType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.property.form.dataType,
                            callback: function ($$v) {
                              _vm.$set(_vm.property.form, "dataType", $$v)
                            },
                            expression: "property.form.dataType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.int"),
                              value: "int",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.decimal"),
                              value: "decimal",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.string"),
                              value: "string",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.dateTime"),
                              value: "dateTime",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.enum"),
                              value: "enum",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.jsonObject"),
                              value: "jsonObject",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.stringList"),
                              value: "stringList",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.dataType.img"),
                              value: "img",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.accessMode"),
                        prop: "accessMode",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.property.form.accessMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.property.form, "accessMode", $$v)
                            },
                            expression: "property.form.accessMode",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.commons.r"),
                              value: "r",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.commons.w"),
                              value: "w",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.commons.rw"),
                              value: "rw",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.property.form.dataType == "int" ||
              _vm.property.form.dataType == "decimal"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.tsl.valueRange"),
                            prop: "min",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "min" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "controls-position": "right",
                                      min: -2147483647,
                                      max: _vm.property.form.max - 1,
                                    },
                                    model: {
                                      value: _vm.property.form.min,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.property.form, "min", $$v)
                                      },
                                      expression: "property.form.min",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "split-span" }, [
                                _vm._v("-"),
                              ]),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "max" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "controls-position": "right",
                                      min: _vm.property.form.min + 1,
                                      max: 2147483647,
                                    },
                                    model: {
                                      value: _vm.property.form.max,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.property.form, "max", $$v)
                                      },
                                      expression: "property.form.max",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.property.form.dataType == "int" ||
              _vm.property.form.dataType == "decimal"
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.step"),
                              prop: "step",
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "tsl-input-number",
                              attrs: {
                                "controls-position": "right",
                                min: 1,
                                max: 50,
                              },
                              model: {
                                value: _vm.property.form.step,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property.form, "step", $$v)
                                },
                                expression: "property.form.step",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("vlink.tsl.unit"), prop: "unit" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "16",
                          "show-word-limit": true,
                          clearable: "",
                          placeholder: _vm.$t("commons.pleaseInput"),
                        },
                        model: {
                          value: _vm.property.form.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.property.form, "unit", $$v)
                          },
                          expression: "property.form.unit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.property.form.dataType !== "int" &&
              _vm.property.form.dataType !== "decimal"
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.tsl.maxLength"),
                              prop: "maxLength",
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "tsl-input-number",
                              attrs: {
                                "controls-position": "right",
                                min: 1,
                                max: 2147483647,
                              },
                              model: {
                                value: _vm.property.form.maxLength,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property.form, "maxLength", $$v)
                                },
                                expression: "property.form.maxLength",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.trigger"),
                        prop: "trigger",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.property.form.trigger,
                            callback: function ($$v) {
                              _vm.$set(_vm.property.form, "trigger", $$v)
                            },
                            expression: "property.form.trigger",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.trigger1"),
                              value: "1",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.tsl.trigger2"),
                              value: "2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.property.form.trigger === "1"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.tsl.reportCycle"),
                            prop: "reportCycle",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.property.form.reportCycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.property.form, "reportCycle", $$v)
                              },
                              expression: "property.form.reportCycle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.scaling"),
                        prop: "scaling",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          "show-word-limit": true,
                          clearable: "",
                        },
                        model: {
                          value: _vm.property.form.scaling,
                          callback: function ($$v) {
                            _vm.$set(_vm.property.form, "scaling", $$v)
                          },
                          expression: "property.form.scaling",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.precision"),
                        prop: "precision",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          "show-word-limit": true,
                          clearable: "",
                        },
                        model: {
                          value: _vm.property.form.precision,
                          callback: function ($$v) {
                            _vm.$set(_vm.property.form, "precision", $$v)
                          },
                          expression: "property.form.precision",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.description"),
                        prop: "description",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "descript-input",
                        attrs: {
                          maxlength: "100",
                          rows: 1,
                          "show-word-limit": true,
                          type: "textarea",
                          placeholder: _vm.$t("commons.pleaseInput"),
                        },
                        model: {
                          value: _vm.property.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.property.form, "description", $$v)
                          },
                          expression: "property.form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.tsl.virtual"),
                        prop: "description",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.property.form.virtual,
                            callback: function ($$v) {
                              _vm.$set(_vm.property.form, "virtual", $$v)
                            },
                            expression: "property.form.virtual",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.commons.true"),
                              value: true,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.commons.false"),
                              value: false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.property.form.virtual
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.tsl.virtualExpression"),
                            prop: "description",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "descript-input",
                            attrs: {
                              maxlength: "100",
                              rows: 1,
                              "show-word-limit": true,
                              type: "textarea",
                              placeholder: _vm.$t(
                                "commons.pleaseInputExpression"
                              ),
                            },
                            model: {
                              value: _vm.property.form.virtualExpression,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.property.form,
                                  "virtualExpression",
                                  $$v
                                )
                              },
                              expression: "property.form.virtualExpression",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm.property.form.dataType == "enum"
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("vlink.tsl.enumList") } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          icon: "el-icon-plus",
                          size: "mini",
                        },
                        on: { click: _vm.addEnumItem },
                      },
                      [_vm._v(_vm._s(_vm.$t("vlink.tsl.addEnumItem")))]
                    ),
                    _vm._l(
                      _vm.property.form.enumList,
                      function (enumItem, index) {
                        return _c(
                          "el-form-item",
                          { key: index, staticStyle: { "margin-top": "10px" } },
                          [
                            _c(
                              "el-col",
                              [
                                _vm._v("\n            " + _vm._s(index)),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v("-")]
                                ),
                                _c("el-input", {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    width: "80%",
                                  },
                                  attrs: {
                                    maxlength: "16",
                                    "show-word-limit": true,
                                    clearable: "",
                                    placeholder: _vm.$t("commons.pleaseInput"),
                                  },
                                  model: {
                                    value: _vm.property.form.enumList[index],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.property.form.enumList,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression: "property.form.enumList[index]",
                                  },
                                }),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeEnumItem(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-delete delete-btn",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("vlink.tsl.extendConfig"),
                prop: "extendConfig",
              },
            },
            [
              _c(
                "div",
                { staticClass: "extend-config-line" },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                    },
                    model: {
                      value: _vm.property.form.needConfig,
                      callback: function ($$v) {
                        _vm.$set(_vm.property.form, "needConfig", $$v)
                      },
                      expression: "property.form.needConfig",
                    },
                  }),
                  _c(
                    "span",
                    {
                      class: _vm.property.form.needConfig
                        ? "config-title-active"
                        : "config-title-default",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.property.form.needConfig ? "已开启" : "未开启"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("span", { staticClass: "config-tips" }, [
                    _c("i", { staticClass: "el-icon-info" }),
                    _vm._v("\n          关闭时，将不保存扩展配置。\n        "),
                  ]),
                ],
                1
              ),
              _c("property-info", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.property.form.needConfig,
                    expression: "property.form.needConfig",
                  },
                ],
                ref: "propInfoRef",
                attrs: { editable: true, property: _vm.property.form },
                on: { cancelEditProperty: _vm.closeExtendConfigDialog },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { slot: "footer", align: "right" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeSaveProperty(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.saveHandler },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.currentProperty.isEdit
                    ? _vm.$t("commons.save")
                    : _vm.$t("commons.add")
                )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }